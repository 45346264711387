<template>
  <!--画面Top-->
  <v-app style="overflow-y: hidden; overflow-x: hidden;">
    <v-app-bar color="white" elevation="1">
      <v-img class="shrink mr-2" :src="require('@/assets/okinawagasulogo-image.png')" width="190"/>
    </v-app-bar>

    <v-main style="background-color:#FAFBFC">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card class="mt-3 mb-3" width="1000px" height="500">

              <!--top-->
              <div class="Top">
                <v-row justify="center" class="center ma-5">
                  <v-col cols="12" class="pt-0">
                    <v-sheet class="d-flex align-center justify-center mt-4" color="#009DE6" rounded="pill" height="60"
                      max-width="500">
                      <h2>
                        <font color="#FFFFFF">お申込みのキャンセル完了画面</font>
                      </h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>

              <div class="MessageReceived">
                <v-row class="ms-2 me-2">
                  <v-col align="ceter">
                    <p>お申込みのキャンセルが完了しました。<br>この画面を閉じて下さい。</p>
                  </v-col>
                </v-row>
              </div>

              <!--完了btn-->
              <v-row class="mt-5" justify="center">
                <v-col cols="12">
                  <v-btn class="white--text" variant="outlined" color="#009DE6" width="20%" @click="submit">
                    閉じる
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>
  
<script>
import FooterView from "@/components/common/GlobalFooter/index.vue"
export default {
  data: () => ({
    e1: 5,
  }),
  methods: {
    submit() {
      // TOPページにリダイレクト
      this.$router.go({path: '/', force: true})
    },
  },
  components: {
    FooterView,
  },
  computed: {
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  beforeRouteLeave (to, from, next) {
      // ブラウザの戻るボタンが押されたときにTOPページにリダイレクト
      if (from.name === "CancelComplete" && (to.name === "ConfAppStartUse" || to.name === "SuspendContentConfirmationPage")) {
        this.$router.go({path: '/', force: true})
      } else {
        next();
      }
    }
}
</script>
<style scoped>
@media screen and (max-width:600px) {
  .v-toolbar__title {
    font-size: .8em;
  }

  .Top h2 {
    font-size: 1em;
  }

  h3 {
    font-size: .9em;
  }
}
</style>
<style scoped>
::v-deep .v-stepper__label {
  display: grid !important;
}
</style>