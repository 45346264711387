<template> 
  <AppStartAgreement /> 
</template>
  
<script>
  import AppStartAgreement from '../components/AplStart/AppStartAgreement.vue'

  export default {
    name: 'AppStartAgreementFormView',

    components: {
      AppStartAgreement,
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>