import {
  SET_INPUT,
  SET_RES_KAIHEI_DATA,
} from "@/store/mutation-types";
  
  export default {
    [SET_INPUT]: (state, value) => {
      state.confInput = value
    },
    [SET_RES_KAIHEI_DATA]: (state, resKaiheiData) => {
      state.resKaiheiData = resKaiheiData
    },
  };