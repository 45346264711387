<template>
  <v-app>
    <!-- ヘッダー -->
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>
    <v-main  style="background-color:#FAFBFC">
      <v-container>
        <v-row> 
          <v-col align="center">
            <v-card 
              max-width="1000px" 
              class="mt-3 mb-3"
            >
            <!--ステップ表示-->
            <div class="step" >
              <v-row class="mb-10">
                <v-col cols="12">
                  <v-stepper alt-labels flat>
                    <v-stepper-header v-model="e1">
                      <v-col cols="2">
                        <v-stepper-step class="pr-0 pl-0"
                          :complete="e1 > 1"
                          step="1"
                        >
                          <font size="4" color="#1478D2">STEP1</font>
                        </v-stepper-step>
                      </v-col>
                      <v-col cols="2">
                        <v-stepper-step class="pr-0 pl-0" 
                          :complete="e1 > 2"
                          step="2"
                        >
                          <font size="4" color="#1478D2">STEP2</font>
                        </v-stepper-step>
                      </v-col>
                      <v-col cols="2">
                        <v-stepper-step class="pr-0 pl-0" 
                          :complete="e1 == 3"
                          step="3"
                        >
                        <font size="4" color="#1478D2">STEP3</font>
                        </v-stepper-step>
                      </v-col>
                      <v-col cols="2">
                        <v-stepper-step class="pr-0 pl-0" step="4">
                          STEP4
                        </v-stepper-step>
                      </v-col>
                      <v-col cols="2">
                        <v-stepper-step class="pr-0 pl-0" step="5">
                          STEP5
                        </v-stepper-step>
                      </v-col>
                    </v-stepper-header>
                  </v-stepper>
                </v-col>
              </v-row>
            </div>
            <!-- タイトル -->
            <div class="tytle">
                <v-row justify="center" class="center ma-5">
                  <v-col class="pt-0">
                    <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="60"
                      max-width="550"
                    >
                      <h2 class="ms-1 me-1"><font color="#FFFFFF">「お引越しによるご利用停止」のお申し込み</font></h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>
            <!-- (1)お申し込みに関する同意事項 -->
            <div class="AgreementMatter">
              <v-row class="ms-1">
                <v-col align="left">
                  <v-alert
                    class="d-flex align-center"
                    border="left"
                    colored-border
                    color="#009DE6"
                    height="20"
                    tile
                  >
                    <h3><font color="#009DE6">➈&ensp;同意事項</font></h3>
                  </v-alert>
                </v-col>
              </v-row >

              <!-- (1)共通事項 -->
              <div class="CommonMatter">
                <v-row class="ms-1">
                  <v-col align="left" class="pb-0">
                    （１）共通事項
                  </v-col>
                </v-row >
                <v-row class="ms-3 me-2">
                  <v-col align="left">
                    <p>・現在、暴力団員、暴力団準構成員、総会屋等の反社会的勢力には該当せず、将来にわたっても該当しません。</p>
                    <p>・申し込に際し、次の「不利益説明事項」を読み、内容に同意したうえで申し込みをします。</p>
                  </v-col>
                </v-row>
              </div>

              <!-- 不利益説明事項 -->
              <div class="DisadvantageDescriptionMatter">
                <v-row class="ms-1">
                  <v-col align="left" class="pb-0">
                    〈不利益説明事項〉
                  </v-col>
                </v-row >
                <v-row class="ms-3 me-2">
                  <v-col align="left">
                    <p>現在の小売契約解約時に下記に記載される事項が発生する可能性があります。</p>
                    <p>・ 契約期間中の解約に伴う違約金の発生（複数年契約などの場合）</p>
                    <p>・ 各社発行ポイントの失効</p>
                    <p>・ 継続利用割引に適用される継続利用期間の終了</p>
                    <p>・ 過去の電力使用量及びガス使用量に関する各小売事業者への照会不可</p>
                  </v-col>
                </v-row>
              </div>
              <div v-if ="(suspendAgreement.ContinuousUseQuestion || suspendAgreement.NewApplicationQuestion == 1)||(suspendAgreement.ElectricalSuspendQuestion == 0)">
                <v-row class="ms-1">
                  <v-col align="left" class="pb-0">
                    （２）「沖縄ガスのでんき」のお申し込みに関する同意事項
                  </v-col>
                </v-row>
                <v-row class="ms-3 me-2">
                  <v-col align="left">
                    <p>・ <a :href="this.linkIndicate.serviceGuide" target="_blank" rel="noopener noreferrer">サービス内容説明書</a>に同意したうえで申し込みをします。</p>
                    <p>・ <a :href="this.linkIndicate.supplyClause" target="_blank" rel="noopener noreferrer">供給約款</a>に同意したうえで申し込みをします。</p>
                    <p>・ 申し込に際し、<a :href="this.linkIndicate.personalInformationHandling" target="_blank" rel="noopener noreferrer">個人情報の取扱いについて</a>を読み、内容に同意したうえで申し込みをします。</p>
                    <p>・ <a :href="this.linkIndicate.consignmentClauseProvision" target="_blank" rel="noopener noreferrer">託送約款規定における需要者に関する事項</a>を遵守します。</p>
                    <p>・ 他社がガス供給している建物から「沖縄ガスのでんき」のご利用のお申し込みをされた場合、お申込みキャンセルのご意向を確認できない場合は、手続きを進めさせていただくことを予めご了承ください。</p>
                  </v-col>
                </v-row>
              </div>
            </div>

              <v-form 
                ref="AgreementMatterCheck"
                v-model="valiCheck"
                
              >

                <!-- 同意確認 -->
                <div class="AgreementCheck">
                  <!-- <v-row class="ms-1">
                    <v-col class="mt-10" align="left">
                      <p>上記の内容を確認し、同意する場合は「同意する」をチェックを入れてください。</p>
                    </v-col>
                  </v-row> -->
                  <v-radio-group
                    class="mt-5" 
                    v-model="AgreementConfirmation"
                    :rules="[rules.AgreementValiCheck,rules.notAgreeValiCheck]"
                    required
                    row
                  >
                    <v-row class="ps-5 pb-5" justify="center">
                      <!-- <v-col cols="12" md="3" class="pb-0"> -->
                        <v-radio 
                          value="同意する"
                        >
                          <template v-slot:label>
                            <span style="color:rgba(0,0,0,.87)">上記の内容を確認し、同意しました。</span>
                          </template>
                        </v-radio>
                      <!-- </v-col> -->
                      <!-- <v-col cols="12" md="3">
                        <v-radio 
                          value="同意しない"
                        >
                          <template v-slot:label>
                            <span style="color:rgba(0,0,0,.87)">同意しない</span>
                          </template>
                        </v-radio>
                      </v-col> -->
                    </v-row>
                  </v-radio-group>
                </div>
                <!-- 次へ/戻るボタン -->
                <div class="submit">
                  <v-row class="mt-5" justify="center"> 
                    <v-col cols="4" md="5" align="right">
                      <v-btn
                        color="gray"
                        @click="backBtn"
                        width="40%"
                      >戻る
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="5" align="left">
                      <v-btn
                        color="#009DE6" 
                        @click="submit"
                        width="40%"
                        :disabled="!valiCheck||AgreementConfirmation == '同意しない'" 
                      >
                      <font color="white">次へ</font>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>

<script>
import {
  mapState,
  mapActions,
} from "vuex";
import {
  GET_LINK_SETTING_CLOSE
} from "@/store/action-types";
import FooterView from "@/components/common/GlobalFooter/index.vue"
export default {
  data(){
    return{
      valiCheck:"",
      AgreementConfirmation:"",
      e1:3,
      rules:{
        //バリデーションチェック
        AgreementValiCheck:value => (value==="同意する" || value==="同意しない"),             //同意チェック
        notAgreeValiCheck: value => value === "同意する" || ''
      } ,
      linkIndicate:{
        serviceGuide:"",
        supplyClause:"",
        personalInformationHandling:"",
        consignmentClauseProvision:"",
      }
    }
  },

  methods: { 
    ...mapActions({
    getLinkSettingClose: GET_LINK_SETTING_CLOSE,
  }),
    submit() {
      return this.$refs.AgreementMatterCheck.validate() == false ? "":this.$router.push('/SuspendContentConfirmation')
    },
    backBtn(){
      return this.$router.push('/SuspendApplication')
    },
    async displayLinkSetting(){
      await this.getLinkSettingClose()
      const linkSetting = this.setResponseCloseLink.okigas_link_setting;
      
      // リンクを設定する（リンクが設定されていない場合はundefinedを設定して遷移しないリンクにする）
      this.linkIndicate.serviceGuide = linkSetting.service_guide_url ? linkSetting.service_guide_url : undefined;
      this.linkIndicate.supplyClause = linkSetting.supply_clause_url ? linkSetting.supply_clause_url : undefined;
      this.linkIndicate.personalInformationHandling = linkSetting.personal_information_handling_url ? linkSetting.personal_information_handling_url : undefined;
      this.linkIndicate.consignmentClauseProvision = linkSetting.consignment_clause_provision_url ? linkSetting.consignment_clause_provision_url : undefined;
    }
  },
  components:{
    FooterView,
  },
  computed:{
    ...mapState({
      suspendAgreement: state => state.SuspendAgreementMatterForm.suspendAgreement,
      setResponseCloseLink: state => state.SuspendAgreementMatterForm.setResponseCloseLink,
    })
  },
  mounted(){
    //リンク取得
    this.displayLinkSetting()
  },
    
}
</script>
<style scoped>
  @media screen and (max-width:600px){
    .tytle{
      font-size: .7em;
    }
    .InquiryCounterGas{
      font-size: .8em;
    }
  }
</style>
<style scoped>
::v-deep .v-stepper__label{
  display: grid !important;
}

</style>