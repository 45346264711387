import {
    SET_INPUT,
    SET_LINK_DISPLAY,

  } from "@/store/mutation-types";
    
    export default {
      [SET_INPUT]: (state, value) => {
        state.agreementInput = value
      },
      [SET_LINK_DISPLAY]:(state,value) =>{
        state.setResponseLink = value
      }
    };