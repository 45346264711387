import Vue from 'vue'
import {
    POST_INPUT_DATA,
    PUT_CLOSE_UPDATA,
} from "@/store/action-types";
import {
  SET_RES_HEISEN_DATA,
} from "@/store/mutation-types";

export default {
  // 閉栓お申込み追加
  [POST_INPUT_DATA]: async ({state, commit}, req) => {
    console.log(state)
    return await Vue.prototype.$okigasAxios
      .post(
        "/api/okigas_application/close_faucet",
        req
      )
      .then((response) => {
        if (response.data) {
          commit(SET_RES_HEISEN_DATA, response.data);
        }
        return response;
      })
      .catch((err) => {return err});
  },

  //閉栓お申込み更新
  [PUT_CLOSE_UPDATA]: async ({state,commit}, req) => {
    console.log(req)
    console.log(state)
    const new_obj = req.new.okigas_application
    const old_obj = JSON.parse(JSON.stringify(req.old.okigas_application))

    for (const key in old_obj) {
      if (new_obj[key] === undefined) {
        new_obj[key] = old_obj[key];
      }
    }
    return await Vue.prototype.$okigasAxios
      .put(
        "/api/okigas_application/close_faucet/" + new_obj.application_id,
        {
          okigas_application : new_obj,
          user_name: req.new.user_name,
        }
      )
      .then((response) => {
        if (response.data) {
          commit(SET_RES_HEISEN_DATA, response.data);
        }
      })
      .catch((err) => console.log(err));
  },
      
};