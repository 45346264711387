<template>
  <v-dialog v-model="visible" persistent width="500">
    <v-card>
      <v-system-bar :color="dialogColor" dark height="10"> </v-system-bar>
      <v-list three-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-if="type === 'info'" x-large :color="dialogColor">mdi-alert-circle-outline</v-icon>
            <v-icon v-if="type === 'warning'" x-large :color="dialogColor">mdi-alert-outline</v-icon>
            <v-icon v-if="type === 'confirm'" x-large :color="dialogColor">mdi-help-circle-outline</v-icon>
            <v-icon v-if="type === 'error'" x-large :color="dialogColor">mdi-alert-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="title" style="white-space: normal;"></v-list-item-title>
            <div class="mt-2 text-body-2 break-line text--disabled">
              {{ text }}
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions v-if="!hideBtn">
        <v-spacer></v-spacer>
        <v-btn v-if="!hideBtnCancel" color="grey darken-2" outlined @click="hide">
          {{ btnCancelTitle }}
        </v-btn>
        <v-btn v-if="!hideBtnConfirm" :color="dialogColor" @click="confirm">
          {{ btnConfirmTitle }}
        </v-btn>
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import Dialog from "@/plugins/dialog";
const DIALOG_COLOR = {
  info: "primary",
  warning: "warning",
  confirm: "primary",
  error: "error"
};

export default Vue.extend({
  data() {
    return {
      visible: false,
      title: "",
      text: "",
      type: "info",
      btnConfirmTitle: "OK",
      btnCancelTitle: "キャンセル",
      hideBtn: false,
      hideBtnConfirm: false,
      hideBtnCancel: false, 
    };
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Dialog.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  computed: {
    dialogColor() {
      return DIALOG_COLOR[this.type] || DIALOG_COLOR.info;
    },
  },
  methods: {
    hide() {
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === "function") {
        // run passed function and then close the modal
        this.onConfirm();
        this.inputUrl = "";
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      this.text = params.text;
      this.type = params.type || "info";
      this.title = params.title;
      this.hideBtn = params.hideBtn;
      this.hideBtnConfirm = params.hideBtnConfirm;
      this.hideBtnCancel = params.hideBtnCancel;
      (this.btnConfirmTitle = params.btnConfirmTitle || "OK"),
        (this.btnCancelTitle = params.btnCancelTitle || "キャンセル"),
        (this.onConfirm = params.onConfirm);
    },
  },
});
</script>

<style scoped>
.dialog-text {
  white-space: pre !important;
  display: block;
  line-height: 1.5 !important;
}
.break-line {
  white-space: pre-line !important;
}
</style>
