<template>
  <ConfAppStartUse />
</template>

<script>
  import ConfAppStartUse from '../components/AplStart/ConfAppStartUse.vue'
  export default {
    name: 'ConfAppStartUseFormView',
    components:{
      ConfAppStartUse,
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>