<template>
  <v-app>
    <!-- ヘッダー -->
    <v-sheet>
      <v-app-bar 
        color="white"
        elevation="1"
      >
        <v-img
          class="shrink mr-2"
          :src="require('../../assets/okinawagasulogo-image.png')"
          width="190"
        />
      </v-app-bar>
    </v-sheet>
    <v-main  style="background-color:#FAFBFC">
      <v-container>
        <v-row> 
          <v-col align="center">
            <v-card 
              max-width="1000px"
              class="mt-3 mb-3"
            >
              <!--ステップ表示-->
              <div class="step" >
                <v-row class="mb-10">
                  <v-col cols="12">
                    <v-stepper alt-labels flat>
                      <v-stepper-header v-model="e1">
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0"
                            :complete="e1 > 1"
                            step="1"
                          >
                          <font size="4" color="#1478D2">STEP1</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" 
                            :complete="e1 > 2"
                            step="2"
                          >
                          <font size="4" color="#1478D2">STEP2</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" 
                            :complete="e1 > 3"
                            step="3"
                          >
                          <font size="4" color="#1478D2">STEP3</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0"
                          :complete="e1 > 4"
                            step="4"
                          >
                          <font size="4" color="#1478D2">STEP4</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0"
                          :complete="e1 == 5"
                            step="5"
                          >
                          <font size="4" color="#1478D2">STEP5</font>
                          </v-stepper-step>
                        </v-col>
                      </v-stepper-header>
                    </v-stepper>
                  </v-col>
                </v-row>
              </div>
              <!-- タイトル -->
              <div class="tytle">
                <v-row justify="center" class="center ma-5">
                  <v-col class="pt-0">
                    <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="88.5"
                      max-width="550"
                    >
                      <h2 class="ms-1 me-1"><font color="#FFFFFF">「お引越しによるご利用停止」の<br>お申し込み完了画面</font></h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>  
              <v-row justify="center">
                <v-col cols="8">
                  <v-card class="mb-3 border" tile outlined>
                    <v-card-text align="left">
                      <p>お申し込みを受け付けました。</p>
                      <p>受付番号：{{ resHeisenData.reception_no }}</p>
                      <p>受付日時：{{ resHeisenData.updated_at }}</p>
                      <p>お問い合わせの際は上記の受付番号をお伝えください。</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- 1. 受付完了メールについて -->
              <div class="ReceptionCompleteEmail">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">⓵&ensp;受付完了メールについて</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1">
                  <v-col align="left">
                    <p>受付内容に不備がございましたら、その旨をご通知するメールまたは電話にて連絡をさせていただきます。</p>
                    <p>１時間以上経ってもお申し込み完了通知メールが届かない場合、お手数ですが、下記のお問い合わせ窓口までご連絡ください。</p>
                  </v-col>
                </v-row>
              </div>
              <!-- 閉じるボタン -->
              <div class="submit">
                <v-row class="mt-5" justify="center"> 
                  <v-col cols="4" md="5">
                    <v-btn
                      color="#009DE6"
                      class="white--text"
                      @click="closeBtn"
                      width="40%"
                    >閉じる
                    </v-btn>
                  </v-col>
                 </v-row>
                </div>
              <!-- 問い合わせ -->
              <div class="InquiryCounterGas">
                <v-row class="ms-2 me-2">
                  <v-col align="left" class="mt-5">
                    【ガスのご利用開始に関するお問い合わせ窓口】
                  </v-col>
                </v-row>
                <v-row class="ms-5 me-2">
                  <v-col align="left" class="pt-0">
                    沖縄ガス株式会社&emsp;財務部料金課
                  </v-col>
                </v-row>
                <v-row class="ms-9 me-2">
                  <v-col align="left" class="pt-0">
                      電話番号：平日&emsp;098-863-7438、&emsp;土曜・日曜・祝祭日&emsp;098-863-7730<br>
                      受付時間：平日&emsp;8：30&emsp;〜&emsp;17：30
                  </v-col>
                </v-row>
              </div> 
              <div class="InquiryCounterElectricity">
                <v-row class="ms-2 me-2">
                  <v-col align="left" class="mt-5">
                    【「沖縄ガスのでんき」に関するお問い合わせ窓口】
                  </v-col>
                </v-row>
                <v-row class="ms-5 me-2">
                  <v-col align="left" class="pt-0">
                    沖縄ガスニューパワーコールセンター<br>
                  </v-col>
                </v-row>
                <v-row class="ms-9 me-2">
                  <v-col align="left" class="pt-0">
                    電話番号：0120-991-549<br>
                    受付時間：平日 9：00 ～ 18：00、（休業日：土・日・祝日）<br>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>

<script>
import {
  mapState,
} from "vuex";
import FooterView from "@/components/common/GlobalFooter/index.vue"
export default {
  data(){
    return{
      e1:5,
    }
  },
  methods: { 
    closeBtn() {
        // TOPページにリダイレクト
        this.$router.go({path: '/', force: true})
      },
  },
  components:{
    FooterView,
  },
  computed: {
    ...mapState({
      resHeisenData: state => state.SuspendContentConfirmationForm.resHeisenData,
    }),
  },
}
</script>
<style scoped>
  @media screen and (max-width:600px){
    .tytle{
      font-size: .7em;
    }
    .InquiryCounterElectricity{
      font-size: .8em;
    }
    .InquiryCounterGas{
      font-size: .8em;
    }
  }
</style>
<style scoped>
::v-deep .v-stepper__label{
  display: grid !important;
}

</style>