import Vue from 'vue'
import Vuex from 'vuex'
// 各画面のモジュールをインポート
import topApplicationForm from './modules/topApplicationForm';
import ConfAppStartUseForm from './modules/ConfAppStartUseForm';
import AppStartAgreementForm from './modules/AppStartAgreementForm';
import StartUseForm from './modules/StartUseForm';
import SuspendApplicationViewForm from './modules/SuspendApplicationViewForm';
import SuspendContentConfirmationForm from './modules/SuspendContentConfirmationForm';
import SuspendAgreementMatterForm from './modules/SuspendAgreementMatterForm';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
    topApplicationForm,
    ConfAppStartUseForm,
    AppStartAgreementForm,
    StartUseForm,
    SuspendApplicationViewForm,
    SuspendContentConfirmationForm,
    SuspendAgreementMatterForm,
  }
})


