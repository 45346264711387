import state from "./topApplicationForm.state";
import mutations from "./topApplicationForm.mutations";
import actions from "./topApplicationForm.actions";

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};
