<template>
  <v-app>
    <v-main>
      <router-view/>
      <app-dialog/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
  }),
};
</script>

<style>
.v-application {
  font-family: 'Hiragino Kaku Gothic ProN';
}
</style>