import axios from 'axios'

const OkigasAxiosPlugin = {}

OkigasAxiosPlugin.install = function (Vue) {
  Vue.prototype.$okigasAxios = axios.create({
    baseURL: process.env.VUE_APP_API_DOMEIN,
    headers: {
        'x-api-key': process.env.VUE_APP_X_API_KEY
    },
  })
}

export default OkigasAxiosPlugin;