<template>
  <v-app>
    <!-- ヘッダー -->
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>
    <v-main  style="background-color:#FAFBFC">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card
              max-width="1000px"
              class="mt-3 mb-3"
            >
              <!-- ステップ表示 -->
              <div class="step">
                <v-row class="mb-10">
                  <v-col cols="12">
                    <v-stepper alt-labels flat>
                      <v-stepper-header v-model="e1">
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" :complete="e1 > 1" step="1">
                            <font size="4" color="#1478D2">STEP1</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" :complete="e1 == 2" step="2">
                            <font size="4" color="#1478D2">STEP2</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="3">
                            STEP3
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="4">
                            STEP4
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="5">
                            STEP5
                          </v-stepper-step>
                        </v-col>
                      </v-stepper-header>
                    </v-stepper>
                  </v-col>
                </v-row>
              </div>

              <!-- タイトル -->
              <div class="tytle">
                <v-row justify="center" class="center ma-5">
                  <v-col class="pt-0">
                    <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="60"
                      max-width="550"
                    >
                      <h2 class="ms-1 me-1"><font color="#FFFFFF">「お引越しによるご利用停止」のお申し込み</font></h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>
              <v-form ref="validationCheck">
                <!-- 説明 -->
                <div class="Description">
                  <v-row class="ms-3 me-1" v-if="isReceptionNo"> 
                    <v-col cols="12" md="2" align="left" class="pt-0 pb-0">
                      <P>受付番号</P>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <P>{{ isReceptionNo }}</P>
                    </v-col>
                  </v-row>

                  <div v-if ="displayCancelBtn">
                    <v-row class="ms-1 me-1 mb-3">
                      <v-col cols="12" md="5" align="left" >
                        <v-btn
                          color="error"
                          :disabled="(disabledSuspendElectricity || disabledStartElectricityContinue || disabledStartElectricityNew)"
                          @click="cancel"
                        >
                          <font color="white">お申し込みをキャンセルする</font>
                        </v-btn>
                      </v-col>
                      <v-col v-if="!(disabledSuspendElectricity || disabledStartElectricityContinue || disabledStartElectricityNew)" class="pt-0" cols="12" align="left">
                        <font color="red">
                          キャンセルのお申し込みを行いますと、「沖縄ガスのでんき」に関するお申し込みも<br>
                          キャンセルとなりますので、予めご了承ください。
                        </font>
                      </v-col>
                      <v-col v-else class="pt-0" cols="12" align="left">
                        <font color="#CD0001">
                          WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。<br>
                          お問い合わせ窓口までご連絡をください。
                        </font>
                      </v-col>
                    </v-row>
                    <v-overlay :value="loading" class="centered" color="rgba(204,204,204,0.6)">
                      <v-progress-circular
                        indeterminate
                        size="40"
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>

                  <v-row class="ms-3" no-gutters>
                    <v-col align="left" no-gutters>
                      <p>下記の項目を入力の上、送信してください。</p>
                    </v-col>
                  </v-row>
                </div>
                <!-- ⓵.お申し込み者情報 -->
                <div class="ApplicantInformation">
                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➀&ensp;お申し込み者情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp; お申し込み者
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="沖縄"
                        v-model="inputData.ApplicantSurname"
                        :rules="[rules.AppSurnameCheck,rules.AppSurname_length,rules.AppSurname_type]"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="次郎"
                        v-model="inputData.ApplicantName"
                        :rules="[rules.AppNameCheck,rules.AppName_length,rules.AppName_type]"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5" >
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;お申し込み者電話番号<br>
                      <span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                      (ハイフン無し)&ensp;
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ApplicantPhoneNumber"
                        placeholder="0988637730"
                        :rules="[rules.AppPhoneNumCheck,rules.AppPhoneNum_length,rules.AppPhoneNum_type]"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>    
                <!-- ➁.ご利用を停止する場所のご契約情報 -->
                <div class="UseLocationInformation">
                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➁&ensp;ご利用を停止する場所のご契約情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pt-1" cols="12" md="4" align="left">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;ご利用を停止する場所のガス種
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="8" align="left">
                      <v-radio-group 
                        row
                        v-model="inputData.RadioGasType"
                        :rules="[rules.radioGasType]"
                        class="mt-0"
                      >
                          <v-radio
                            value="city_gas"
                            @change="ResetRefundMethod"
                            class="me-6 mb-2"
                          >
                          <template v-slot:label>
                            <span style="color:rgba(0,0,0,.87)">都市ガス</span>
                          </template>
                          </v-radio>
                          <v-radio
                            value="propane_gas"
                            class="me-6 mb-2"
                          >
                            <template v-slot:label>
                              <span style="color:rgba(0,0,0,.87)">プロパンガス</span>
                            </template>
                          </v-radio>
                          <v-radio
                            value="unknown"
                            @change="ResetRefundMethod"
                            class="mb-2"
                          >
                            <template v-slot:label>
                              <span style="color:rgba(0,0,0,.87)">不明</span>
                            </template>
                          </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="2" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span> &ensp;お名前
                    </v-col>
                    <v-col cols="12" md="2" align="left" class="pb-0">
                      <v-dialog
                        v-model="dialog"
                        width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="text-decoration-underline"
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            text
                          >
                            入力に困ったら
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-row class="mt-1">
                              <v-col cols="12">
                                例）姓:9文字、名:10文字以内で入力してください。
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="4" align="left" class="pb-0">
                                <v-row class="ms-1 me-1">
                                  <p><span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&nbsp;お名前</p>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;沖縄ガス</font>
                                </v-sheet>
                              </v-col>
                              <v-col cols="8" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;株式会社</font>
                                </v-sheet>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="4" align="left" class="pb-0">
                                <v-row class="ms-1 me-1">
                                  <p><span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&nbsp;フリガナ</p>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row class="mb-1">
                              <v-col cols="12" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;オキナワガス</font>
                                </v-sheet>
                              </v-col>
                              <v-col cols="8" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;カブシキガイシャ</font>
                                </v-sheet>
                              </v-col>
                            </v-row>
                            <font color="red">文字数が制限を超える場合は、先頭より入力できる範囲でご入力ください。</font><br>
                            <font color="red">※法人の種類（株式会社、有限会社など）は、文字数制限を超える場合、省いてください。</font>
                          </v-card-text>
                          <v-card-actions>
                            <v-row justify="center">
                              <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                              >
                                閉じる
                              </v-btn>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field 
                        type="text"
                        v-model="inputData.ContractorSurname"
                        placeholder="沖縄"
                        :rules="[rules.ContractorSurnameCheck,rules.ContractorSurname_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorName"
                        placeholder="太郎"
                        :rules="[rules.ContractorNameCheck,rules.ContractorName_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;お名前（フリガナ）
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorSurnameFuri"
                        placeholder="オキナワ"
                        :rules="[rules.ContractorSurnameFuriCheck,rules.ContractorSurnameFuri_length,rules.ContractorSurnameFuri_type]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorNameFuri"
                        placeholder="タロウ"
                        :rules="[rules.ContractorNameFuriCheck,rules.ContractorNameFuri_length,rules.ContractorNameFuri_type]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;郵便番号<br>
                      <span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                      (ハイフン無し)&ensp;
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorPostalCode"
                        placeholder="9008605"
                        :rules="[rules.PostalCodeCheck, rules.PostalCode_length_type]"
                        @change="searchAddress"
                        outlined
                        clearable
                        dense
                        :loading="YubinBangoLoading"
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0"> 
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;都道府県
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorPrefectures"
                        placeholder="沖縄県"
                        :rules="[rules.PrefecturesCheck,rules.prefectures_length,rules.prefectures_type]"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;市町村
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorMunicipality"
                        placeholder="那覇市"
                        :rules="[rules.MunicipalityCheck,rules.Municipality_length,rules.Municipality_type]"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;町字
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorTownLetter"
                        placeholder="西"
                        :rules="[rules.TownLetterCheck,rules.TownLetter_length,rules.TownLetter_type]"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;番地
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorBlock"
                        placeholder="3-13-2"
                        :rules="[rules.ContractorBlockCheck,rules.ContractorBlock_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>&ensp;建物名
                    </v-col>
                    <v-col cols="12" md="8" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorBuildingName"
                        :rules="[rules.Building_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>&ensp;部屋番号
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorRoomNumber"
                        :rules="[rules.Room_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;電話番号<br>
                      <span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                      (ハイフン無し)&ensp;
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.ContractorPhoneNumber"
                        placeholder="0988637730"
                        :rules="[rules.PhoneNumCheck, rules.PhoneNum_length,rules.PhoneNum_type]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;メールアドレス
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        type="email"
                        v-model="inputData.ContractorEmail"
                        placeholder="okinawatarou@okinawagas.co.jp"
                        :rules="[rules.ContractorEmailCheck,rules.ContractorEmailcheck, rules.EmailLengthCheck]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;メールアドレス（確認用）
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        type="email"
                        v-model="inputData.ContractorEmailConfirmation"
                        placeholder="okinawatarou@okinawagas.co.jp"
                        :rules="[rules.ContractorEmailConfCheck,addressHogeCheck, rules.EmailLengthCheck]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                </div>

                <!-- ➂.ガスの最終ご利用日について -->
                <div class="DateDesiredSuspend">

                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➂&ensp;ガスの最終ご利用日について</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px">
                        <font style="color:white">
                          &thinsp;必須&thinsp;
                        </font>
                      </span>
                      &ensp;ガスの最終ご利用⽇<br>
                      <span style="font-size: 11px;">※日付横の()内の数字はお申し込み可能な件数です。</span>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <v-menu
                          ref="GasLastUse"
                          v-model="inputData.GasLastUse"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="350"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="inputData.GasLastUseDate"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.startdateRules]"
                            outlined
                            clearable
                            dense
                            required
                            placeholder="ここをクリック"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="LastUseDate"
                          v-model="dispGasLastUseDate"
                          @input="dispGasLastUseDateMethod"
                          no-title
                          locale="jp-ja"
                          :day-format="dayFormat"
                          :allowed-dates="isAllowedDates"
                          :min="this.dateMin"
                          :max="this.dateMax"
                          scrollable
                          required
                          width="350"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col cols="12" align="left">
                      ガスの供給は、最終ご利用⽇の翌営業日に停止させていただきます。
                    </v-col>
                  </v-row>
                  <!-- 必要かどうか確認中 -->
                  <!-- <v-row class="ms-1 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      希望時間&ensp;<font color="red">⁕</font>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <v-select
                        v-model="inputData.time"
                        :rules="[rules.required]"
                        :items="inputData.timezone"
                        outlined
                        clearable
                        dense
                        required
                      ></v-select>
                    </v-col>
                  </v-row> -->
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col cols="12" align="left">
                      建物内部に立ち入ることが出来ないなどガスのご利用停止作業に支障がある場合、弊社よりメールまたは電話にて連絡をさせていただきます。
                    </v-col>
                  </v-row>
                </div>

                <!-- ➃.ご利用場所のガス契約情報 -->
                <div class="UseLocationGasContractInformation">
                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➃&ensp;ご利用場所のガス契約情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      （１）ガス料金番号
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field 
                        type="nunber"
                        placeholder="9999999999"
                        v-model="inputData.GasChargeNumber"
                        :rules="[rules.ChargeNumberCheck]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-6 me-1  mb-5">
                    <v-col cols="12" md="4" align="left" class="pb-0">
                      検針票の画像
                    </v-col>
                    <v-col cols="12" md="8" align="left">
                      <input 
                        style="display: none"
                        type="file"
                        ref="UploadMeterImage"
                        @change="uploadFile"
                        accept="image/png,image/jpg,image/jpeg"
                        caputure="camera"
                      />
                      <v-layout text-xs-center wrap>
                        <v-flex xs12>
                          <!-- ファイルの選択 -->
                          <v-btn color="error" @click="Meterbtnclick">検針票をアップする</v-btn>
                        </v-flex>
                      </v-layout>

                        <v-img 
                          class="mt-5"
                          :src="inputData.MeterImageUrl"
                          contain
                          height="300"
                          width="500"
                          style="border:3px #BDBDBD solid;"
                        ></v-img>
                    </v-col>
                  </v-row>
                </div>

                <!-- ➄.引っ越し先の情報 -->
                <div class="InformationNextHomeMoving">
                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➄&ensp;引っ越し先の情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;郵便番号<br>
                      <span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                      (ハイフン無し)&ensp;
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.NextHomePostalCode"
                        placeholder="9030103"
                        :rules="[rules.PostalCodeCheck, rules.PostalCode_length_type]"
                        @change="searchNextHomeAddress"
                        outlined
                        clearable
                        dense
                        :loading="YubinBangoLoading"
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;都道府県
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="沖縄県"
                        :rules="[rules.PrefecturesCheck,rules.prefectures_length,rules.prefectures_type]"
                        v-model="inputData.NextHomePrefectures"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;市町村
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="西原町"
                        :rules="[rules.MunicipalityCheck,rules.Municipality_length,rules.Municipality_type]"
                        v-model="inputData.NextHomeMunicipality"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;町字
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="小那覇"
                        :rules="[rules.TownLetterCheck,rules.TownLetter_length,rules.TownLetter_type]"
                        v-model="inputData.NextHomeTownLetter"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>&ensp;番地
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        placeholder="493-1"
                        :rules="[rules.ContractorBlockCheck,rules.ContractorBlock_length]"
                        v-model="inputData.NextHomeBlock"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>&ensp;建物名
                    </v-col>
                    <v-col cols="12" md="8" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="inputData.NextHomeBuildingName"
                        :rules="[rules.Building_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col class="pb-0" cols="12" md="4" align="left" >
                      <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>&ensp;部屋番号
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        
                        v-model="inputData.NextHomeRoomNumber"
                        :rules="[rules.Room_length]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <!--  ➅.LPガスの保証金預かりの返金について -->
                <div v-if="inputData.RadioGasType !== 'city_gas'">
                  <div class="DepositRefund">
                    <v-row class="ms-1">
                      <v-col align="left">
                        <v-alert
                          class="d-flex align-center"
                          border="left"
                          colored-border
                          color="#009DE6"
                          height="20"
                          tile
                        >
                          <h3><font color="#009DE6">➅&ensp;LPガスの保証金預かりの返金について</font></h3>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row class="ms-2 me-1">
                      <v-col align="left" class="pb-0">
                        保証金の返金方法
                      </v-col>
                    </v-row>
                    <v-row class="ms-2 me-1 mb-5">
                      <v-col align="left">
                        賃貸住宅の場合等、ご入居の際に保証金10,000円をお預かりしたお客さまは最終のガス料金のお支払いが確定後に保証金を返金させていただきます。
                      </v-col>
                    </v-row>
                    <v-row class="ms-4 me-1 mt-5">
                      <v-col cols="12" md="4" align="left" class="pb-0">
                        保証金預かりNO
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-text-field
                        type="text" 
                        placeholder="999999"
                        v-model="inputData.CustodyDepositNumber"
                        :rules="[rules.SecurityDepositCheck]"
                        outlined
                        clearable
                        dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ms-4 me-1 mb-5" justify="center">
                      <v-spacer></v-spacer>
                      <v-col cols="12" md="8" align="left">
                        <input 
                          style="display: none"
                          type="file"
                          ref="UploadDepositImage"
                          @change="uploadFile2"
                          accept="image/png,image/jpg,image/jpeg"
                        />

                        <v-layout text-xs-center wrap>
                          <v-flex xs12>
                            <!-- ファイルの選択 -->
                            <v-btn color="error" @click="Depositbtnclick">保証金預かり証をアップする</v-btn>
                          </v-flex>
                        </v-layout>

                          <v-img 
                            class="mt-5"
                            :src="inputData.DepositImageUrl"
                            contain
                            height="300"
                            width="500"
                            style="border:3px #BDBDBD solid;"
                          ></v-img>
                      </v-col>
                    </v-row>
                    <div v-if="inputData.RadioGasType === 'propane_gas' || inputData.RadioGasType === 'unknown'">
                      <v-row class="ms-4 me-1 mt-8">
                        <v-col cols="12" md="4" align="left" class="pb-0">
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>保証金の受け取り口座指定について
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                          <v-radio-group mandatory row v-model="inputData.isBankDesignation" class="mt-0" hide-details="auto" man>
                            <v-radio :value="1" class="me-6 mb-2" style="color:rgba(0,0,0,.87)"
                              label="保証金の受け取り口座を指定しない。" 
                            />
                            <v-radio :value="2" class="me-6 mb-2" style="color:rgba(0,0,0,.87)"
                              label="保証金の受け取り口座を指定する。" 
                            />
                            <div v-if="(inputData.RadioGasType === 'propane_gas' || inputData.RadioGasType === 'unknown') && inputData.isBankDesignation === 1" class="mt-3 mb-6">
                              ※窓口もしくは、電話にて保証金の受取方法についてお問い合わせください。
                            </div>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="(inputData.RadioGasType === 'propane_gas' || inputData.RadioGasType === 'unknown') && inputData.isBankDesignation === 2">
                      <v-row class="ms-4 me-1 mt-8">
                        <v-col cols="12" md="4" align="left" class="pb-0">
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          口座名義
                        </v-col>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                          <v-text-field
                            type="text"
                            placeholder="オキナワ タロウ"
                            v-model="inputData.AccountHolder"
                            :rules="[rules.AccountHolder, rules.AccountHolderCheck,rules.AccountHolder_length]"
                            outlined
                            clearable
                            dense                                      
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="ms-4 me-1 mt-8">
                        <v-col cols="12" md="4" align="left" class="pb-0 pl-0">
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        </v-col>
                      </v-row>
                      <v-radio-group
                        v-model="inputData.BankSelectionRadio"
                        :rules="[rules.BankSelectionRadioCheck]"
                      >
                        <v-row class="ms-2 me-1 mt-5">
                          <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                            <v-radio 
                              :value="0"
                              @click="resetBankInfo('ゆうちょ以外')"
                              label="銀行・金庫・農協の名称"
                            ></v-radio>
                          </v-col>
                          <v-col cols="12" md="6" class="ps-10 pt-0 pb-0">
                            <v-text-field
                              type="text"
                              placeholder="〇〇銀行"
                              v-model="inputData.BankName"
                              :rules="[BankNameCheck,rules.BankName_length]"
                              outlined
                              clearable
                              dense
                              :disabled="inputData.BankSelectionRadio !== 0 "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div v-if="inputData.BankSelectionRadio === 0 ">
                          <v-radio-group 
                            v-model="inputData.AccountTypeRadio"
                            :rules="[rules.AccountType]"
                          >
                            <v-row class="ms-10 me-1">
                              <v-col cols="12" md="4" class="pb-0">
                                <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span><span style="color:rgba(0,0,0,.87)">口座の種類</span>
                              </v-col>
                              <v-col cols="12" md="2" class="pb-0">
                                <v-radio 
                                  :value="0"
                                  label="普通"
                                  >
                                </v-radio>
                              </v-col>
                              <v-col cols="12" md="2" class="pb-0">
                                <v-radio 
                                  :value="1"
                                  label="当座"
                                  >
                                </v-radio>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                          <v-row class="ms-10 me-1 mt-5">
                            <v-col cols="12" md="4" align="left" class="pb-0">   
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>支店名
                            </v-col>
                            <v-col cols="12" md="2" class="pt-0 pb-0">
                              <v-text-field
                                type="text"
                                v-model="inputData.BranchName"
                                :rules="[rules.BranchNameCheck,rules.BranchName_length]"
                                placeholder="〇〇支店"
                                outlined
                                clearable
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="ms-10 me-1">
                            <v-col cols="12" md="4" align="left" class="pb-0">
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span> 支店番号
                            </v-col>
                            <v-col cols="12" md="2" class="pt-0 pb-0">
                              <v-text-field
                                type="text"
                                placeholder="308"
                                v-model="inputData.BranchOfficeNumber"
                                :rules="[rules.BranchNumberCheck,rules.BranchNumber_length_type]"
                                outlined
                                clearable
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="ms-10 me-1 mb-3">
                            <v-col cols="12" md="4" align="left" class="pb-0">
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>口座番号
                            </v-col>
                              <v-col cols="12" md="3" class="pt-0">
                                <v-text-field
                                  type="text"
                                  placeholder="9999999"
                                  v-model="inputData.BankAccountNumber"
                                  :rules="[rules.AccountNumberCheck,rules.AccountNumber_length_type]"
                                  outlined
                                  clearable
                                  dense
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        <v-row class="ms-2 me-1">
                          <v-col cols="12" md="4" align="left" class="pb-0">
                            <v-radio 
                              :value="1"
                              @click="resetBankInfo('ゆうちょ')"
                              label="ゆうちょ銀行"
                            ></v-radio>
                          </v-col>
                          <v-col cols="12" md="6" align="left" class="d-flex justify-center ps-10 pb-0 pt-0" >
                            <v-text-field
                              type="text"
                              placeholder="記号"
                              v-model="inputData.PostBankSymbol"
                              outlined
                              dense
                              class="postStyle1"
                              :disabled="inputData.BankSelectionRadio !== 1 "
                              :rules="[postBankSymbolCheck]"
                            ></v-text-field>
                            <span class="mx-2 pt-2" style="color:rgba(0,0,0,.87)"><b>-</b></span>
                            <v-text-field
                              placeholder="番号"
                              v-model="inputData.PostBankNum"
                              outlined
                              dense
                              class="postStyle3"
                              :disabled="inputData.BankSelectionRadio !== 1 "
                              :rules="[postBankNumCheck]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="ms-2 me-1 mb-5">
                          <v-col align="left">
                            ※再発行通帳の場合、記号と番号の間の数字(「*****-*-********」の「-*-」部分)は不要です。
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </div>
                  </div>
                </div>

                <!--  ➆.沖縄ガスのでんきのご利用の停止およびお引越し先のご利用について -->
                <div class="MovingNextUse" :class="((disabledSuspendElectricity && disabledStartElectricityContinue) || (disabledStartElectricityNew)) ? 'disabledArea' : ''">
                  <v-row class="ms-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        :class="((disabledSuspendElectricity && disabledStartElectricityContinue) || (disabledStartElectricityNew)) ? 'disabledArea' : ''"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3>
                          <font color="#009DE6">
                            <span v-if="inputData.RadioGasType === 'city_gas'">➅</span>
                            <span v-else>➆</span>
                            &ensp;沖縄ガスのでんきのご利用の停止およびお引越し先のご利用について
                          </font>
                        </h3>
                      </v-alert>
                    </v-col>
                    <v-col v-if="(disabledSuspendElectricity || disabledStartElectricityContinue || disabledStartElectricityNew)" cols="12" align="left" class="pt-0">
                      <font color="#CD0001">
                        WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。<br>
                        お問い合わせ窓口までご連絡をください。
                      </font>
                    </v-col>
                  </v-row>
                  <v-radio-group
                    v-model="inputData.UseOkinawaGasElectricQuestion"
                    :disabled="(disabledSuspendElectricity && disabledStartElectricityContinue) || (disabledStartElectricityNew)"
                    :readonly="(disabledSuspendElectricity || disabledStartElectricityContinue)"
                    :rules="[rules.UseOkinawaGasElectricQuestionCheck]"
                    @change="ResetAboutUse"
                  >
                    <v-row class="ms-2 me-1">
                      <v-col align="left" class="pb-0">
                        <font color="#CD0001">いずれかを選択してください。</font>
                      </v-col>
                    </v-row>
                    <v-row class="ms-2 me-1">
                      <v-col align="left">
                        <v-radio 
                          :value="0"
                          label="ガスをご利用している場所で、「沖縄ガスのでんき」を利用している場合"
                        ></v-radio>
                      </v-col>
                    </v-row>
                    <!-- ガスをご利用している場所で、「沖縄ガスのでんき」を利用している場合 -->
                    <div v-if="inputData.UseOkinawaGasElectricQuestion === 0">
                      <div :class="(disabledSuspendElectricity) ? 'disabledArea' : ''">
                        <v-row class="mt-1 ms-4 me-1">
                          <v-col>
                            <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                            （１）ガスのご利用を停止する場所で「沖縄ガスのでんき」のご利用を停止しますか？
                          </v-col>
                        </v-row>
                        <v-radio-group
                          v-model="inputData.ElectricalSuspendQuestion"
                          :disabled="disabledSuspendElectricity"
                          :rules="[rules.ElectricalSuspendQuestionCheck]"
                        >
                          <v-row class="ms-8 me-1">
                            <v-col cols="12" md="2">
                              <v-radio :value="1" label="はい"></v-radio>
                            </v-col>
                            <v-col cols="12" md="3" class="pb-0">
                              <span style="color:rgba(0,0,0,.87)">でんきの最終ご利用日</span>
                            </v-col>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-menu
                                v-model="inputData.UsingElectricalSuspend"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="inputData.UsingElectricalSuspendDate"
                                    :disabled="(inputData.ElectricalSuspendQuestion === 0) || disabledSuspendElectricity"
                                    :rules="[check]"
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    clearable
                                    placeholder="ここをクリック"
                                    required
                                    dense
                                    readonly
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dispElSuspendDate"
                                  @input="dispElSuspendDateMethod"
                                  no-title
                                  locale="jp-ja"
                                  :day-format="nomalDayFormat"
                                  :min="this.dateMin"
                                  :max="this.dateMax"
                                  scrollable
                                  required
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row class="ms-8 me-1  mb-5">
                            <v-col cols="11" md="10" class="pt-0">
                              <v-radio
                                :value="0"
                                label="いいえ（でんきは、継続して利用する。）"
                              ></v-radio>
                            </v-col>
                            <v-spacer></v-spacer>
                          </v-row>
                        </v-radio-group>
                      </div>
                      <div :class="(disabledStartElectricityContinue) ? 'disabledArea' : ''">
                        <v-row class="ms-4 me-1">
                          <v-col>
                            <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                            （２）引っ越し先で、「沖縄ガスのでんき」を継続利用を申し込みますか？
                          </v-col>
                        </v-row>
                        <v-radio-group 
                          v-model="inputData.ContinuousUseQuestion"
                          :disabled="disabledStartElectricityContinue"
                          :rules="[rules.ContinuousUseQuestionCheck]"
                        >
                          <v-row class="ms-8 me-1">
                            <v-col cols="12" md="2">
                              <v-radio
                                :value="1"
                                label="申し込む"
                              ></v-radio>
                            </v-col>
                          </v-row>
                          <div v-if="inputData.ContinuousUseQuestion === 1 ">
                            <v-row class="ms-12 me-1">
                              <v-col cols="12" md="3" class="pb-0">
                                <span style="color:rgba(0,0,0,.87)">でんきのご利用開始⽇</span>
                              </v-col>
                              <v-col cols="12" md="4" class="pt-0 pb-0">
                                <v-menu
                                  ref="ContinuousUseStart"
                                  v-model="inputData.ContinuousUseStart"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="inputData.ContinuousUseStartDate"
                                      :disabled="disabledStartElectricityContinue"
                                      :rules="[rules.startdateRules]"
                                      append-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      clearable
                                      dense
                                      placeholder="ここをクリック"
                                      required
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dispContinuUseStartDate"
                                    @input="dispContinuUseStartDateMethod"
                                    no-title
                                    locale="jp-ja"
                                    :day-format="nomalDayFormat"
                                    :min="this.dateMin"
                                    :max="this.dateMax"
                                    scrollable
                                    required
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row class="ms-12 me-1">
                              <v-col>
                                <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                                <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                                <p>・沖縄本島全域</p>
                                <p>・沖縄本島と電力系統が繋がっている離島</p>
                                <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                              </v-col>
                            </v-row>
                          </div>
                          <v-row class="ms-8 me-1 mb-5">
                            <v-col>
                              <v-radio
                                :value="0"
                                label="申し込まない"
                              ></v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div>
                    </div>
                    <!-- ガスをご利用している場所で、「沖縄ガスのでんき」を利用していない場合 -->
                    <v-row class="ms-2 me-1">
                      <v-col align="left">
                        <v-radio
                          :value="1"
                          label="ガスをご利用している場所で、「沖縄ガスのでんき」を利用していない場合"
                        ></v-radio>
                      </v-col>
                    </v-row>
                    <div v-if="inputData.UseOkinawaGasElectricQuestion === 1 ">
                      <v-row class="mt-1 ms-6 me-1">
                        <v-col>
                          お引越し先で、「沖縄ガスのでんき」を新規で申し込みますか
                        </v-col>
                      </v-row>
                      <v-radio-group
                        v-model="inputData.NewApplicationQuestion"
                        :disabled="disabledStartElectricityNew"
                        :rules="[rules.NewApplicationQuestionCheck]"
                      >
                        <v-row class="ms-6 me-1">
                          <v-col>
                            <v-radio
                              :value="1"
                              label="申し込む"
                            ></v-radio>
                          </v-col>
                        </v-row>
                        <div v-if="inputData.NewApplicationQuestion === 1">
                          <v-row class="ms-8 me-1">
                            <v-col cols="12" md="4" class="pb-0" >
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                              （１）でんきのご利用開始⽇
                            </v-col>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-menu
                                v-model="inputData.NewUseStart"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="inputData.NewUseStartDate"
                                    :disabled="disabledStartElectricityNew"
                                    :rules="[rules.startdateRules]"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    clearable
                                    placeholder="ここをクリック"
                                    required
                                    dense
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="dispNewUseStartDate"
                                  @input="dispNewUseStartDateMethod"
                                  no-title
                                  locale="jp-ja"
                                  :day-format="nomalDayFormat"
                                  :min="this.dateMin"
                                  :max="this.dateMax"
                                  scrollable
                                  required
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row class="ms-8 me-1">
                            <v-col class="pb-0" cols="12">
                              <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                              <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                              <p>・沖縄本島全域</p>
                              <p>・沖縄本島と電力系統が繋がっている離島</p>
                              <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                            </v-col>
                          </v-row>
                          <v-row class="ms-8 me-1">
                            <v-col class="pb-0" cols="12">
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                              （２）「でんき料金のお知らせ」の郵送の要・不要
                            </v-col>
                            <v-col class="ps-12 pt-0" style="color:black">
                            （「要」の場合、でんき料金に１１０円が加算されます。）
                            </v-col>
                          </v-row>
                          <v-radio-group 
                            v-model="inputData.AnnouncementAvailability"
                            :disabled="disabledStartElectricityNew"
                            :rules="[rules.AnnouncementAvailabilityCheck]"
                          >
                            <v-row class="ms-12 me-1 mb-2">
                              <v-col cols="12" md="2">
                                <v-radio
                                  :value="1"
                                  label="要"
                                ></v-radio>
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-radio
                                  :value="0"
                                  label="不要"
                                ></v-radio>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                          <v-row class="ms-8 me-1">
                            <v-col class="pb-0">
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                              <span style="color:rgba(0,0,0,.87)">（３）でんき料金のお支払い方法</span>
                            </v-col>
                          </v-row>
                          <v-row class="ms-8 me-1">
                            <v-col class="ps-12 pt-0" cols="12">
                              <span style="color:rgba(0,0,0,.87)">
                                お支払いは、口座振替とクレジット支払のみとなります。<br>
                                お支払いに関する手続き用紙は、ご利用場所に別途郵送いたします。<br>
                                ・口座振替<br>
                                ・クレジットカード
                              </span>
                            </v-col>
                          </v-row>
                          <v-row class="ms-16 me-1 mb-4">
                            <span style="color:rgba(0,0,0,.87)">（ご利用できるカード：</span>
                            <v-img 
                              :src="require('../../assets/card_5brand.png')"
                              class="shrink mr-2"
                              max-width="230"
                              max-height="25"
                              contain
                            ></v-img>
                            <span style="color:rgba(0,0,0,.87)">）</span>
                          </v-row>
                        </div>
                        <v-row class="ms-6 me-1 mb-5">
                          <v-col>
                            <v-radio 
                              :value="0"
                              label="申し込まない"
                            ></v-radio>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </div>
                  </v-radio-group>
                </div>
                <!--  ➇.その他（代理店記入） -->
                <div class="Other">
                  <div v-if="inputData.RadioGasType === 'city_gas'">
                    <v-row class="ms-1">
                      <v-col align="left">
                        <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➆&ensp;その他（代理店記入）</font></h3>
                      </v-alert>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="inputData.RadioGasType !== 'city_gas'">
                    <v-row class="ms-1">
                      <v-col align="left">
                        <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➇&ensp;その他（代理店記入）</font></h3>
                      </v-alert>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                      代理店番号
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" md="3" >
                      <v-text-field type="text"
                        placeholder="代理店番号"
                        v-model="inputData.ProxyStoreNumber"
                        outlined
                        clearable
                        :rules="[rules.dairitenBangoLength]"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 要望により非表示 -->
                  <v-row v-show="qrCodePara" class="ms-1 me-1">
                    <v-col class="pt-0 pb-0" cols="12" md="4" align="left" >
                      QRコードのパラメーター
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" md="3" >
                      <v-text-field 
                        type="text"
                        placeholder="QRコード"
                        v-model="inputData.QRCodeParameter"
                        :rules="[QRCodeParameterCheck]"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row> 
                </div>
                <!-- 次へ/戻るボタン -->
                <div class="submit">
                  <v-row class="mt-5" justify="center"> 
                    <v-col cols="4" md="5" align="right">
                      <v-btn
                        color="gray"
                        @click="backBtn"
                        width="40%"
                      >戻る
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="5" align="left">
                      <v-btn
                        color="#009DE6" 
                        @click="submit"
                        width="40%"
                      >
                      <font color="white">次へ</font>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card> 
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>

<script>
  import FooterView from "@/components/common/GlobalFooter/index.vue";
  import {
    mapMutations,
    mapState,
    mapActions
  } from "vuex";
  import {
    SET_INPUT_DATA,
  } from "@/store/mutation-types";
  import {
    PUT_CANCEL_CLOSEAPPLICATION,
    GET_CLOSE_RESERVATION_COUNT,
    GET_CLOSE_ACCEPTABLE_RANGE,
  } from "@/store/action-types";
  import {
    compressImage,
  } from '@/mixins/imageHelper.js';
  import moment from "moment";
  import axios from "axios";
  export default {
    components:{
      FooterView,
    },
    data: () => ({   
      YubinBangoLoading: false,
      inputData: {
        //1. お申し込み者情報
        ApplicantSurname: "",
        ApplicantName: "",
        ApplicantPhoneNumber: "",
        //2. ご利用場所ご契約情報
        RadioGasType: "",
        ContractorSurname: "",
        ContractorName: "",
        ContractorSurnameFuri: "",
        ContractorNameFuri: "",
        ContractorPostalCode: "",
        ContractorPrefectures: "",
        ContractorMunicipality: "",
        ContractorTownLetter: "",
        ContractorBlock: "",
        ContractorBuildingName: "",
        ContractorRoomNumber: "",
        ContractorPhoneNumber: "",
        ContractorEmail: "",
        ContractorEmailConfirmation: "",
        //3. ガスの最終ご利用日について
        GasLastUseDate: "",
        time: "",
        timezone: ['➀時間指定なし', '➁9：00〜12：00', '③13：00〜15：00', '④15：00〜17：00'],
        //4. ご利用場所のガス契約情報
        GasChargeNumber: "",
        MeterImageUrl: "",
        //5.引っ越し先の情報
        NextHomePostalCode: "",
        NextHomePrefectures: "",
        NextHomeMunicipality: "",
        NextHomeTownLetter: "",
        NextHomeBlock: "",
        NextHomeBuildingName: "",
        NextHomeRoomNumber: "",
        //6. 保証金預かりの返金について
        CustodyDepositNumber: "",
        DepositImageUrl: "",
        AccountHolder: "",
        isBankDesignation: 1,
        BankName: "",
        BranchName: "",
        BranchOfficeNumber: "",
        BankAccountNumber: "",
        BankSelectionRadio: "",
        AccountTypeRadio: "",
        PostBankSymbol:"",
        PostBankNum: "",
        //7. でんきのご利用の停止およびお引越し先のご利用について
        GasPaymentSelection: "",
        UseOkinawaGasElectricQuestion: "",
        ElectricalSuspendQuestion: "",
        UsingElectricalSuspendDate: "",
        ContinuousUseQuestion: "",
        ContinuousUseStartDate: "",
        NewApplicationQuestion: "",
        NewUseStartDate: "",
        AnnouncementAvailability: "",
        //8. その他（代理店記入）
        ProxyStoreNumber: "",
        QRCodeParameter: "",
        // //カレンダー
        ContinuousUseStart: false, 
        UsingElectricalSuspend: false,
        GasLastUse: false,
        NewUseStart: false,
      },
      today: moment().format('YYYY-MM-DD'),
      qrCodePara: false,
      e1: 2,
      dialog: "",
      reservableDate: {},
      allowedDates:[],
      dispGasLastUseDate: "",
      dispElSuspendDate: "",
      dispContinuUseStartDate: "",
      dispNewUseStartDate: "",
      displayCancelBtn: false,
      dateMin: "",
      dateMax: "",
      loading: false,
      imageMaxSize: 200000, // 画像データは200KB
      rules: {
        //バリデーションチェック
        AppSurnameCheck: value => !!value || 'お申し込み者(姓)を入力してください',
        AppSurname_length:value => value == null || 1 <= value.length && value.length <= 9 ||'9文字以下で入力してください。',
        AppSurname_type: value => /^[^0-9０-９]*$/.test(value) ||'お申し込み者（姓）は文字のみ有効です。',
        AppNameCheck: value => !!value || 'お申し込み者(名)を入力してください',
        AppName_length: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        AppName_type: value => /^[^0-9０-９]*$/.test(value) ||'お申し込み者（名）は文字のみ有効です。',       
        AppPhoneNumCheck: value => !!value || 'お申し込み者電話番号を入力してください',
        AppPhoneNum_length: value => value == null ||  10 <= value.length && value.length <= 11 || '(市外局番込み)10桁以上11桁以下で入力してください。',
        AppPhoneNum_type: value => /^[0-9]*$/.test(value) ||'お申し込み電話番号は数字のみ有効です。',
        ContractorSurnameCheck: value => !!value || 'お名前(姓)を入力してください',
        ContractorSurname_length: value => value == null || 1 <= value.length && value.length <= 9 || '9文字以下で入力してください。',
        ContractorNameCheck: value => !!value || 'お名前(名)を入力してください',
        ContractorName_length: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        ContractorSurnameFuriCheck: value => !!value || 'フリガナ（姓）を入力してください。',
        ContractorSurnameFuri_length: value => value == null || 1 <= value.length && value.length <= 9 || '9文字以下で入力してください。',
        ContractorSurnameFuri_type: value => /^[ァ-ヶー0-9０-９]+$/.test(value) || "フリガナ（姓）はカタカナもしくは数字のみ有効です。",   
        ContractorNameFuriCheck: value => !!value ||'フリガナ（名）を入力してください。',
        ContractorNameFuri_length: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        ContractorNameFuri_type: value => /^[ァ-ヶー0-9０-９]+$/.test(value) || "フリガナ（名）はカタカナもしくは数字のみ有効です。",
        PostalCodeCheck: value => !!value || '郵便番号を入力してください',
        PostalCode_length_type: value => /^\d{7}$/.test(value) ||'ハイフン無し7桁で入力してください。',   
        PrefecturesCheck: value => !!value || '都道府県を入力してください',
        prefectures_length: value => value == null || 1 <= value.length && value.length <= 4 || '4文字以下で入力してください。',
        prefectures_type: value => /^[^0-9０-９]*$/.test(value) ||'都道府県は文字のみ有効です。',
        MunicipalityCheck: value => !!value || '市町村を入力してください',
        Municipality_length: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        Municipality_type: value => /^[^0-9０-９]*$/.test(value) ||'市町村は文字のみ有効です。',
        TownLetterCheck: value => !!value || '町字を入力してください',
        apptownsdigitLimit: value => value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        TownLetter_length: value => value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        TownLetter_type: value => /^[^0-9０-９]*$/.test(value) ||'町字は文字のみ有効です。',       
        ContractorBlockCheck: value => !!value || '番地を入力してください',
        ContractorBlock_length: value => value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        Building_length: value => value == "" || value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        Room_length: value => value == "" || value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        PhoneNumCheck: value => !!value || '電話番号を入力してください',
        PhoneNum_length: value => value == null ||  10 <= value.length && value.length <= 11 || '(市外局番込み)10桁以上11桁以下で入力してください。',
        PhoneNum_type: value => /^[0-9]*$/.test(value) ||'電話番号は数字のみ有効です。',
        EmailLengthCheck: value => value.length <= 254 || '254文字以下で入力してください。',
        ContractorEmailCheck: value => !!value || 'メールアドレスを入力してください',
        ContractorEmailConfCheck: value => !!value || 'メールアドレス（確認用）を入力してください',
        ChargeNumberCheck: value => value == "" ||value == null || /^\d{10}$/.test(value) ||'ガス料金番号は 10 桁のみ有効です。',
        SecurityDepositCheck: value => value == "" ||value == null || /^\d{6}$/.test(value) ||'保証金預かり番号は 6 桁のみ有効です。',
        BankSelectionRadioCheck: value => (value === 0 || value === 1 ) ||'',  
        AccountHolder: value => !!value || '口座名義を入力してください',
        AccountHolderCheck: value => value == ""||value == null || /^[ァ-ヶー　\s]+$/.test(value) || '口座名義はカタカナのみ有効です。',       
        AccountHolder_length:value => value == ""||value == null || 1 <= value.length && value.length <= 32 || '口座名義は 32 文字以下のみ有効です。',
        BranchNameCheck: value => !!value ||'支店名を入力してください。',
        BranchName_length: value => value == ""||value == null || 1 <= value.length && value.length <= 32 || '支店名は 32 文字以下のみ有効です。',
        BankName_length: value =>  value == ""||value == null || 1 <= value.length && value.length <= 32 || '銀行・金庫・農協の名称は 32 文字以下のみ有効です。',
        BranchNumberCheck: value => !!value || '支店番号を入力してください。', 
        BranchNumber_length_type: value => /^\d{3}$/.test(value) ||'支店番号は数字3桁のみ有効です。',
        AccountType: value => (value === 0 || value === 1 ) || '',
        AccountNumberCheck: value => !!value || '口座番号を入力してください。',
        AccountNumber_length_type: value =>  /^\d{7}$/.test(value) ||'口座番号は数字7桁のみ有効です。',
        // PostBankSymbolCheck: value => !!value || '記号を入力してください。',
        // PostBankNumCheck: value => !!value || '番号を入力してください。',
        startdateRules: value => !!value || '希望日を選択してください。', 
        radioGasType:value => (value==="city_gas" || value==="propane_gas" || value==="unknown") || '', 
        //katakana: value => /^[ァ-ヶー]+$/.test(value) || "全角カタカナで入力してください", 
        //Phone_length:value => value.length == 11 || '11桁で入力してください',  
        // PostalCode_length:value => value.length == 7 || '7桁で入力してください', 
        // ReceptionNumber_length:value => value.length == 13 || '13桁で入力してください',
        ContractorEmailcheck: value => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(value) || 'メールアドレスが正しくありません。',
        UseOkinawaGasElectricQuestionCheck: value => (value === 0 || value === 1 ) ||'',                                                      //現地で電気を停止する？
        ElectricalSuspendQuestionCheck: value => (value === 1 || value === 0 ) ||'',
        ContinuousUseQuestionCheck: value => (value === 1 || value === 0 ) ||'',
        GasPaymentSelectionCheck: value => (value === 0 || value === 1 ) ||'',
        NewApplicationQuestionCheck: value => (value === 1 || value === 0 ) ||'',
        AnnouncementAvailabilityCheck: value => (value === 1 || value === 0 ) ||'',
        AgencyNumberCheck: value => !!value || '代理店番号を入力してください。',
        //QRCodeParameterCheck: value => !!value || 'QRコードパラメーターを入力してください。',
        dairitenBangoLength: value => (value === "" || value === null || value === undefined) ? true : value.length <= 20 ? true : '',
      },
      resGetAcceptableRange: null,
      disabledSuspendElectricity: false,
      disabledStartElectricityContinue: false,
      disabledStartElectricityNew: false,
    }),
    watch: {
      // ガスのご利用を停止する場所で「沖縄ガスのでんき」のご利用を停止しますかが変更された場合
      "inputData.ElectricalSuspendQuestion": {
        handler(value) {
          if (value === 0) {
            // でんきの最終ご利用日は、初期値に戻す
            this.inputData.UsingElectricalSuspendDate = ""
          }
        },
        deep: true
      },
      // 引っ越し先で、「沖縄ガスのでんき」を継続利用を申し込みますかが変更された場合
      "inputData.ContinuousUseQuestion": {
        handler(value) {
          if (value === 0) {
            // でんきのご利用開始⽇は、初期値に戻す
            this.inputData.ContinuousUseStartDate = ""
          }
        },
        deep: true
      },
      // お引越し先で、「沖縄ガスのでんき」を新規で申し込みますかが変更された場合
      "inputData.NewApplicationQuestion": {
        handler(value) {
          if (value === 0) {
            // でんきのご利用開始⽇は、初期値に戻す
            this.inputData.NewUseStartDate = ""
            // 「でんき料金のお知らせ」の郵送の要・不要は、初期値に戻す
            this.inputData.AnnouncementAvailability = ""
          }
        },
        deep: true
      },
    },
    computed: {
      ...mapState({
        formData: state => state.SuspendContentConfirmationForm.FormData,
        getAllResponseData: state => state.topApplicationForm.getAllResponseData, 
        urlParams: state => state.topApplicationForm.urlParams,
      }),
      isReceptionNo() {
        return this.getAllResponseData?.okigas_application?.reception_no ? this.getAllResponseData?.okigas_application?.reception_no : false
      },
      addressHogeCheck() {
        //メアドとメアド確認用チェック
        return this.inputData.ContractorEmail === this.inputData.ContractorEmailConfirmation || 'メールアドレスが一致しません。';  
      },
      check() {
        //項目７の（1）選択肢によって「でんきのご利用停止日」のバリエーションチェックを変更
        if(this.inputData.ElectricalSuspendQuestion === 1 ){
          return !!this.inputData.UsingElectricalSuspendDate || '希望日を選択してください。';
        }
        else{
          return true;
        }
      }, 
      BankNameCheck() {
        //項目６の銀行・金庫・農協が選択された際にバリデーションチェックを行う。
        if(this.inputData.BankSelectionRadio === 0 ){
          return !!this.inputData.BankName || '銀行・金庫・農協の名称を入力してください。';
        }else{
          return true;
        }
      },
      postBankSymbolCheck() {
        if(this.inputData.BankSelectionRadio === 1){
          return /^\d{5}$/.test(this.inputData.PostBankSymbol) || '記号は数字5桁で入力してください。';
        }else{
          return true;
        }
      },
      postBankNumCheck() {
        if (this.inputData.BankSelectionRadio === 1 && !(/^\d{8}$/.test(this.inputData.PostBankNum))) {
          return (this.inputData.PostBankNum.length > 8) ? '番号は数字8桁で入力してください。' : '先頭を0埋めして8桁になるように入力してください';
        } else {
          return true;
        }
      },
      QRCodeParameterCheck() {
        return this.qrCodePara == false ? true : !!this.inputData.QRCodeParameter || "QRコードパラメーターを入力してください。";
      },
    },
    methods: {
      ...mapMutations({
        setInputData: SET_INPUT_DATA,
      }),
      ...mapActions({
        putCancelCloseApplication: PUT_CANCEL_CLOSEAPPLICATION,
        getReserveCount: GET_CLOSE_RESERVATION_COUNT,
        getAcceptableRange: GET_CLOSE_ACCEPTABLE_RANGE,
      }),
      async uploadFile() {
        const MeterImageFile = this.$refs.UploadMeterImage.files[0];
        // 画像を圧縮
        const compressedImage = await compressImage(MeterImageFile, this.imageMaxSize);

        // 画像情報をセット
        this.inputData.MeterImageUrl = URL.createObjectURL(MeterImageFile)
        this.inputData.MeterImageName = MeterImageFile.name
        this.inputData.MeterImageBase64 = compressedImage
      },
      async uploadFile2() {
        const DepositFil = this.$refs.UploadDepositImage.files[0];
        // 画像を圧縮
        const compressedImage = await compressImage(DepositFil, this.imageMaxSize);

        // 画像情報をセット
        this.inputData.DepositImageUrl = URL.createObjectURL(DepositFil)
        this.inputData.DepositImageName = DepositFil.name
        this.inputData.DepositImageBase64 = compressedImage
      },
      dayFormat(date) {
        const key = moment(date).format('YYYY-MM-DD')

        if(this.reservableDate[key] !== undefined){
          const retValue = this.reservableDate[key]
          return new Date(date).getDate() +"("+ retValue +")"
        }else{
          return new Date(date).getDate() +"("+ '0' +")"
        }
      },
      nomalDayFormat(date) {
        return new Date(date).getDate();
      },
      dispGasLastUseDateMethod() {
        this.inputData.GasLastUseDate = this.dispGasLastUseDate
        this.inputData.GasLastUse = false
      },
      dispElSuspendDateMethod() {
        this.inputData.UsingElectricalSuspendDate = this.dispElSuspendDate
        this.inputData.UsingElectricalSuspend = false
      },
      dispContinuUseStartDateMethod() {
        this.inputData.ContinuousUseStartDate = this.dispContinuUseStartDate
        this.inputData.ContinuousUseStart = false
      },
      dispNewUseStartDateMethod() {
        this.inputData.NewUseStartDate = this.dispNewUseStartDate
        this.inputData.NewUseStart = false
      },
      ResetRefundMethod() {
        // 保証金預かりNOの値と画像は、初期値に戻す
        this.inputData.CustodyDepositNumber = ""
        // 保証金預かり証の画像情報をリセット
        this.inputData.DepositImageUrl = ""
        this.inputData.DepositImageName = ""
        this.inputData.DepositImageBase64 = ""
        // 保証金の受け取り口座指定については、初期値に戻す
        this.inputData.isBankDesignation = 1
        //保証金返金方法と口座情報をリセット
        this.inputData.AccountHolder = '';
        this.inputData.BankSelectionRadio = '';
        this.inputData.BankName = '';
        this.inputData.AccountTypeRadio = '';
        this.inputData.BranchName = '';
        this.inputData.BranchOfficeNumber = '';
        this.inputData.BankAccountNumber = '';
      },
      ResetAboutUse() {
        //「沖縄ガスのでんき」を利用している場合とそうでない場合の切替時に中身をリセット（項目７)
        this.inputData.ElectricalSuspendQuestion = "";
        this.inputData.UsingElectricalSuspendDate= "";
        this.inputData.ContinuousUseQuestion = "";
        this.inputData.ContinuousUseStartDate = "";
        this.inputData.GasPaymentSelection = "";
        this.inputData.NewApplicationQuestion = "";
        this.inputData.NewUseStartDate = "";
        this.inputData.AnnouncementAvailability = "";
      },
      async searchAddress() {
        this.YubinBangoLoading = true;
        await axios.get(
          'https://zipcloud.ibsnet.co.jp/api/search', 
          {
            params: { zipcode: this.inputData.ContractorPostalCode }
          }
        ).then(response => {
          if (response.data.status === 200) {
            this.inputData.ContractorPrefectures = response.data.results[0].address1;
            this.inputData.ContractorMunicipality = response.data.results[0].address2;
            this.inputData.ContractorTownLetter = response.data.results[0].address3;
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.YubinBangoLoading = false;
        });
      },
      async searchNextHomeAddress() {
        this.YubinBangoLoading = true;
        await axios.get(
          'https://zipcloud.ibsnet.co.jp/api/search', 
          {
            params: { zipcode: this.inputData.NextHomePostalCode }
          }
        ).then(response => {
          if (response.data.status === 200) {
            this.inputData.NextHomePrefectures = response.data.results[0].address1;
            this.inputData.NextHomeMunicipality = response.data.results[0].address2;
            this.inputData.NextHomeTownLetter = response.data.results[0].address3;
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.YubinBangoLoading = false;
        });
      },
      resetBankInfo(bankType) {
        if(bankType === 'ゆうちょ以外'){
          this.inputData.PostBankSymbol = "";
          this.inputData.PostBankNum = "";
        }else{
          this.inputData.BankName = "";
          this.inputData.AccountTypeRadio = "";
          this.inputData.BranchName = "";
          this.inputData.BranchOfficeNumber = "";
          this.inputData.BankAccountNumber = "";
        }
      },
      submit() {
        const form = this.$refs.validationCheck;
        const isValid = form.validate();
        if (isValid) {
          this.setInputData(this.inputData);
          this.$router.push('/SuspendAgreementMatter');
        } else {
          // バリデーションチェックに引っかかった箇所にスクロール
          const firstError = form.inputs.find((input) => input.hasError);
          this.$vuetify.goTo(firstError, { offset: 100 });
        }
      },
      backBtn() {
        return this.$router.push('/OkinawaGas')
      },
      //検針票のボタンのクリックイベント
      Meterbtnclick() {
        this.$refs.UploadMeterImage.click();
      },
      //保証金預かり証のボタンのクリックイベント証
      Depositbtnclick() {
        this.$refs.UploadDepositImage.click();
      },
      //キャンセルダイアログ
      cancel() {
        this.$dialog.show({
          title: "閉栓申し込みのキャンセルを行います。",
          text: "本当に申し込みのキャンセルを行いますか。",
          type: "error",
          onConfirm: async () => {
            this.loading = true
            let payload = {
              okigas_application:{} 
            }
            this.canselResponse = this.getAllResponseData
            payload.okigas_application.status = "cancel"
            payload.okigas_application.application_id = this.canselResponse.okigas_application.application_id
            payload.okigas_application.gas_last_use_date = this.canselResponse.okigas_application.gas_last_use_date
            await this.putCancelCloseApplication(payload)
            this.$router.push({ name : 'CancelComplete' })
            this.loading = false  
          },
          btnConfirmTitle: "はい",
          btnCancelTitle: "いいえ",
        });
      },
      isAllowedDates(val){
        return this.allowedDates.includes(val)
      },
      makeAllowedDates(){
        this.allowedDates = []
        for(const key in this.reservableDate){
          if (this.reservableDate[key] > 0){
            this.allowedDates.push(String(key))
          }
        }
      },
    },
    async created() {
      //新規、修正、更新の判定
      //画面遷移元の特定
      // 新規：responseDataにデータなし 編集：responseDataにデータあり
      let setCloseResponseAllData = this.getAllResponseData; //responseDataを格納
      console.log('閉栓レスポンス',this.setCloseResponseAllData)
      let transitionSourceScreen = this.$router.from //遷移元のデータを格納
      if ((setCloseResponseAllData === "") && (transitionSourceScreen.name === 'SuspendAgreementMatterPage')) {
        //新規かつ修正
        console.log('閉栓新規の修正画面')
        // 確認画面から戻って来て再度編集なので、stateの値をローカル変数にセットする
        console.log("setInputData",this.formData)
        this.inputData = this.formData;
        return;

      } else if (setCloseResponseAllData != "") {
        //更新：inputにresponseDataのデータをセットする
        console.log('閉栓更新画面')

        //キャンセルボタンの表示
        this.displayCancelBtn = true;

        // 「沖縄ガスのでんき」を申し込む かつ 受付可能範囲外なら「沖縄ガスのでんき」の入力項目をグレーアウト
        const application = setCloseResponseAllData.okigas_application;
        const isSuspendElectricity = ((application.is_used_okigas_electricity === 0) && (application.is_suspend_electricity === 1));
        const isStartElectricityContinue = ((application.is_used_okigas_electricity === 0) && (application.is_continuous_use_electricity === 1));
        const isStartElectricityNew = ((application.is_used_okigas_electricity === 1) && (application.okigas_electricity_new_use === 1));
        if (isSuspendElectricity || isStartElectricityContinue || isStartElectricityNew) {
          // 受付可能範囲設定の取得
          this.resGetAcceptableRange = await this.getAcceptableRange('close_faucet');
          const accRngSet = this.resGetAcceptableRange?.okigas_acceptable_range_setting;
          const dayCount = accRngSet?.acceptable_range_day_count;
          const monthCount = accRngSet?.acceptable_range_month_count;
          const partialSetting = accRngSet?.acceptable_range_partial_setting;

          // 受付可能範囲を計算
          const today = moment().format('YYYY-MM-DD');
          let accRngStartDate = moment();
          let accRngEndDate = moment();
          if (accRngSet) {
            const todayPartSet = (partialSetting) ? partialSetting.find((obj) => obj.date === today) : null;
            accRngStartDate = (todayPartSet) ? moment(today).add((todayPartSet.day_count + 1), 'd') : moment(today).add(dayCount, 'd');
            accRngEndDate = moment(accRngStartDate).add(monthCount, 'M');
          }
          console.log('accRng: ', accRngStartDate.format('YYYY-MM-DD'), '~', accRngEndDate.format('YYYY-MM-DD'))

          if (isSuspendElectricity) {
            // 「沖縄ガスのでんき」のご利用停止日をグレーアウト
            const suspendElectricityDate =  application.suspend_date
            console.log('suspendElectricityDate', suspendElectricityDate)
            if (!moment(suspendElectricityDate).isBetween(accRngStartDate, accRngEndDate, null, '[]')) {
              this.disabledSuspendElectricity = true;
            }
          }

          if (isStartElectricityContinue) {
            // 「沖縄ガスのでんき」継続利用開始日をグレーアウト
            const startElectricityContinueDate =  application.electricity_start_date
            console.log('startElectricityContinueDate', startElectricityContinueDate)
            if (!moment(startElectricityContinueDate).isBetween(accRngStartDate, accRngEndDate, null, '[]')) {
              this.disabledStartElectricityContinue = true;
            }
          }

          if (isStartElectricityNew) {
            // お引っ越し先で「沖縄ガスのでんき」を新規でご利用開始日をグレーアウト
            const startElectricityNewDate =  application.new_electricity_start_date
            console.log('startElectricityNewDate', startElectricityNewDate)
            if (!moment(startElectricityNewDate).isBetween(accRngStartDate, accRngEndDate, null, '[]')) {
              this.disabledStartElectricityNew = true;
            }
          }
        }

        let closeResponseReceptionData = setCloseResponseAllData.okigas_application
        console.log('閉栓responseData',closeResponseReceptionData)
        this.inputData.Status = closeResponseReceptionData.status
        this.inputData.ApplicantSurname = closeResponseReceptionData.applicant_last_name
        this.inputData.ApplicantName = closeResponseReceptionData.applicant_first_name
        this.inputData.ApplicantPhoneNumber = closeResponseReceptionData.applicant_tel_number
        //2. ご利用場所ご契約情報
        this.inputData.RadioGasType = closeResponseReceptionData.gas_kind
        this.inputData.ContractorSurname = closeResponseReceptionData.last_name
        this.inputData.ContractorName = closeResponseReceptionData.first_name
        this.inputData.ContractorSurnameFuri = closeResponseReceptionData.last_name_kana
        this.inputData.ContractorNameFuri = closeResponseReceptionData.first_name_kana
        this.inputData.ContractorPostalCode = closeResponseReceptionData.postal_code
        this.inputData.ContractorPrefectures = closeResponseReceptionData.pref
        this.inputData.ContractorMunicipality = closeResponseReceptionData.municipality
        this.inputData.ContractorTownLetter = closeResponseReceptionData.address1
        this.inputData.ContractorBlock = closeResponseReceptionData.address2
        this.inputData.ContractorBuildingName = closeResponseReceptionData.building
        this.inputData.ContractorRoomNumber = closeResponseReceptionData.room_number
        this.inputData.ContractorPhoneNumber = closeResponseReceptionData.tel_number
        this.inputData.ContractorEmail = closeResponseReceptionData.mail
        this.inputData.ContractorEmailConfirmation = closeResponseReceptionData.mail
        //3. ガスの最終ご利用日について
        this.inputData.GasLastUseDate = closeResponseReceptionData.gas_last_use_date
        //4. ご利用場所のガス契約情報
        this.inputData.GasChargeNumber = closeResponseReceptionData.charge_number
        this.inputData.MeterImageUrl = closeResponseReceptionData.meter_reading_slip_s3_url
        if (this.inputData.MeterImageUrl && this.inputData.MeterImageUrl !== "") {
          // URLを"/"で分割し、配列の最後の要素を取得
          const parts = this.inputData.MeterImageUrl.split("/");
          const fileName = parts[parts.length - 1];
          this.inputData.MeterImageName = fileName;

          // URLから画像データ取得、dataURLを取得
          fetch(this.inputData.MeterImageUrl)
            .then(response => response.blob()) // レスポンスをBlobに変換
            .then(blob => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob); // Blobをdata URI形式に変換
              });
            })
            .then(dataUri => {
              console.log('画像データをdata URI形式で取得:', dataUri);
              this.inputData.MeterImageBase64 = dataUri;
            })
            .catch(error => {
              console.error('画像データの取得に失敗しました:', error);
            });
        }
        //5.引っ越し先の情報
        this.inputData.NextHomePostalCode = closeResponseReceptionData.moving_postal_code
        this.inputData.NextHomePrefectures = closeResponseReceptionData.moving_pref
        this.inputData.NextHomeMunicipality = closeResponseReceptionData.moving_municipality
        this.inputData.NextHomeTownLetter = closeResponseReceptionData.moving_address1
        this.inputData.NextHomeBlock = closeResponseReceptionData.moving_address2
        this.inputData.NextHomeBuildingName = closeResponseReceptionData.moving_building
        this.inputData.NextHomeRoomNumber = closeResponseReceptionData.moving_room_number
        //6. 保証金預かりの返金について
        this.inputData.CustodyDepositNumber = closeResponseReceptionData.deposit_number
        this.inputData.DepositImageUrl = closeResponseReceptionData.deposit_s3_url
        if (this.inputData.DepositImageUrl && this.inputData.DepositImageUrl !== "") {
          // URLを"/"で分割し、配列の最後の要素を取得
          const parts = this.inputData.DepositImageUrl.split("/");
          const fileName = parts[parts.length - 1];
          this.inputData.DepositImageName = fileName;

          // URLから画像データ取得、dataURLを取得
          fetch(this.inputData.DepositImageUrl)
            .then(response => response.blob()) // レスポンスをBlobに変換
            .then(blob => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob); // Blobをdata URI形式に変換
              });
            })
            .then(dataUri => {
              console.log('画像データをdata URI形式で取得:', dataUri);
              this.inputData.DepositImageBase64 = dataUri;
            })
            .catch(error => {
              console.error('画像データの取得に失敗しました:', error);
            });
        }
        this.inputData.AccountHolder = closeResponseReceptionData.account_holder
        this.inputData.BankSelectionRadio = closeResponseReceptionData.bank_type
        this.inputData.BankName = closeResponseReceptionData.financial_name
        this.inputData.AccountTypeRadio = closeResponseReceptionData.account_type
        this.inputData.BranchName = closeResponseReceptionData.branch_name
        this.inputData.BranchOfficeNumber = closeResponseReceptionData.branch_office_number
        this.inputData.BankAccountNumber = closeResponseReceptionData.bank_account_number
        this.inputData.PostBankSymbol = closeResponseReceptionData.yucho_symbol
        this.inputData.PostBankNum = closeResponseReceptionData.yucho_number
        //7. でんきのご利用の停止およびお引越し先のご利用について
        this.inputData.UseOkinawaGasElectricQuestion = closeResponseReceptionData.is_used_okigas_electricity
        this.inputData.ElectricalSuspendQuestion = closeResponseReceptionData.is_suspend_electricity
        this.inputData.UsingElectricalSuspendDate = closeResponseReceptionData.suspend_date
        this.inputData.ContinuousUseQuestion = closeResponseReceptionData.is_continuous_use_electricity
        this.inputData.ContinuousUseStartDate = closeResponseReceptionData.electricity_start_date
        this.inputData.GasPaymentSelection = closeResponseReceptionData.last_gas_payment_type
        this.inputData.NewApplicationQuestion = closeResponseReceptionData.okigas_electricity_new_use
        this.inputData.NewUseStartDate = closeResponseReceptionData.new_electricity_start_date
        this.inputData.AnnouncementAvailability = closeResponseReceptionData.is_electricity_charge_notification
        //8.その他
        this.inputData.ProxyStoreNumber = closeResponseReceptionData.agency_number
        this.inputData.QRCodeParameter = closeResponseReceptionData.qr_parameter

        console.log('閉栓更新元データ',this.inputData)
        if(transitionSourceScreen.name === 'SuspendAgreementMatterPage'){
          //編集
          console.log('閉栓更新の編集画面')
          return this.inputData = this.formData;
        }
      }
    },
    async mounted() {
      // 受付可能範囲
      if (!this.resGetAcceptableRange) {
        this.resGetAcceptableRange = await this.getAcceptableRange('close_faucet');
      }
      const res = this.resGetAcceptableRange;

      let acceRangeDay = 1;
      let acceRangeMonth = 1;
      if (res && res.okigas_acceptable_range_setting) {
        acceRangeDay = res.okigas_acceptable_range_setting.acceptable_range_day_count;
        acceRangeMonth = res.okigas_acceptable_range_setting.acceptable_range_month_count;
      }

      this.dateMin = moment(this.today).add(acceRangeDay, 'd').format("YYYY-MM-DD");
      this.dateMax = moment(this.today).add(acceRangeMonth, 'M').format("YYYY-MM-DD");

      // 個別設定
      const foundObj = res.okigas_acceptable_range_setting.acceptable_range_partial_setting.find(obj => obj.date === this.today);
      if (foundObj) {
        this.dateMin = moment(foundObj.date).add(foundObj.day_count + 1, 'd').format("YYYY-MM-DD");
      }

      this.dispGasLastUseDate =  moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
      this.dispElSuspendDate =  moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
      this.dispContinuUseStartDate =  moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
      this.dispNewUseStartDate =  moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");

      // 時間枠情報と予約可能数の取得
      let req = {
        applicationType: 'close_faucet',
        start: this.dateMin,
        end: this.dateMax,      
      }
      let scheduleComaId
      const response = await this.getReserveCount(req)
      for( var dateKey in response.okigas_reception_setting.schedule) {
        let sumReservable = 0

        scheduleComaId = response.okigas_reception_setting.schedule[dateKey]

        for( var comaId in response.okigas_reception_setting.coma_list) { 
          
          if(scheduleComaId[comaId]){
            let reservableComa = parseInt(scheduleComaId[comaId][0], 10) - parseInt(scheduleComaId[comaId][1], 10)
            sumReservable += reservableComa
          }
        }
        this.reservableDate[dateKey] = sumReservable
        this.makeAllowedDates();
      }
      // QRコードを設定
      console.log('QRパラメータ', this.urlParams)
      if(this.urlParams['agency_number']){
        this.inputData.ProxyStoreNumber = this.urlParams['agency_number']
      }
      if(this.urlParams['qr_parameter']){
        this.inputData.QRCodeParameter = this.urlParams['qr_parameter']
      }
    },
  }
</script>

<style scoped>
@media screen and (max-width:600px) {
  .tytle{
    font-size: .7em;
  }
}
.postStyle1 {
  width:50px;
}
.postStyle2 {
  width:3px;
}
.postStyle3 {
  width:100px;
}
::v-deep .LastUseDate .v-date-picker-table--date .v-btn {
  width: 45px;
}
::v-deep .v-stepper__label {
  display: grid !important;
}
.disabledArea {
  background-color: rgba(200, 200, 200) !important;
}
</style>