import Vue from 'vue'
import VueRouter from 'vue-router'
import TopView from '../views/TopApplicationFormView.vue'
import SuspendApplication from '../views/SuspendApplicationView.vue'
import SuspendAgreementMatter from '../views/SuspendAgreementMatterView.vue'
import SuspendContentConfirmation from '../views/SuspendContentConfirmationView.vue'
import SuspendCompleteScreen from '../views/SuspendCompleteScreenView.vue'
import appstartview from  '../views/AppStartusingFormView.vue'
import Agreement from '../views/AppStartAgreementFormView.vue'
import ConfAppStart from '../views/ConfAppStartusingFormView.vue'
import Completed from '../views/AppStartCompletedFormView.vue'
import CancelComplete from '../views/CancelCompleteView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: TopView
  },
  {
    //「ご利用開始およびご利用停止」のお申し込みフォーム
    path: '/OkinawaGas',
    name: 'TopPage',
    component: TopView
  },
  {
    //ご利用開始の入力画面
    path: '/appstart',
    name: 'appstart',
    component: appstartview
  },
  {
    //ご利用停止の入力フォーム
    path: '/SuspendApplication',
    name: 'SuspendApplicationPage',
    component: SuspendApplication
  },
  {
    //ご利用停止の同意事項
    path: '/SuspendAgreementMatter',
    name: 'SuspendAgreementMatterPage',
    component: SuspendAgreementMatter
  },
  {
    //ご利用停止の申込内容確認
    path: '/SuspendContentConfirmation',
    name: 'SuspendContentConfirmationPage',
    component: SuspendContentConfirmation
  },
  {
    //「ご利用停止」のお申し込み完了画面
    path: '/SuspendCompleteScreen',
    name: 'SuspendCompleteScreenPage',
    component: SuspendCompleteScreen
  },
  {
    //ご利用開始の同意事項画面
    path: '/AppStartAgreement',
    name: 'AppStartAgreement',
    component: Agreement
  },
  {
    //ご利用開始の確認画面
    path: '/ConfAppStartUse',
    name: 'ConfAppStartUse',
    component: ConfAppStart
  },
  {
    //ご利用開始の完了画面
    path: '/AppStartCompleted',
    name: 'AppStartCompleted',
    component: Completed
  },
  {
    path: '/CancelComplete',
    name: 'CancelComplete',
    component: CancelComplete
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
  
})

router.beforeEach((to, from, next) => {
  router['to'] = to;
  router['from'] = from;
  // リロードされたか判定する
  if (from.name === null) {
    // ホーム画面以外でのリロードは、ホーム画面に戻す
    if (to.name != "home") {
      next('/');
    }
  }
  
  next();
})


export default router
