<template>
  <SuspendApplicationViewForm />
</template>

<script>
  import SuspendApplicationViewForm from '../components/Home/SuspendApplicationViewForm'
  export default {
    name: 'SuspendApplicationView',

    components: {
      SuspendApplicationViewForm,
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>