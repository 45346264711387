import {
  SET_INPUT_DATA,
  SET_LINK_CLOSE_DISPLAY,

} from "@/store/mutation-types";
  
  export default {
    [SET_INPUT_DATA]: (state, value) => {
      state.suspendAgreement = value
    },
    [SET_LINK_CLOSE_DISPLAY]:(state,value) =>{
      state.setResponseCloseLink = value
    }
  };