import Vue from 'vue'
import {
  GET_SEARCH_SCREEN,
  GET_LINK_AD_IMAGES,
  GET_TOP_OPEN_ACCEPTABLE_RANGE,
} from "@/store/action-types";
import {
  SET_RESPONSE_DATA
} from "@/store/mutation-types";

export default {
  [GET_SEARCH_SCREEN]: async ({ state, commit }) => {
    try {
      const response = await Vue.prototype.$okigasAxios  
        .get(
          "/api/okigas_application",{ 
            params:{ 
              //クエリパラメータ
              reception_no: state.topInput.ReceptionNumber,
              applicant_tel_number:state.topInput.PhoneNumber, 
            }
          }
        );
      
      // 取得した値をstateに保持する
      console.log("response.data", response.data)
      commit(SET_RESPONSE_DATA, response.data);
    } catch (error) {
      let title = "";
      let message = "";
      if (error.response.status === 500) {
        title = "システムエラーが発生しました。";
        message = "しばらく時間をおいてから再度お試しください。";
      } else {
        title = "リクエストが失敗しました。";
        message = "パラメーターが不正です。";
      }
      Vue.prototype.$dialog.show({
        title: title,
        text: message,
        type: "error",
        hideBtnCancel: true,
        btnConfirmTitle: "閉じる",
      });
    }
  },
  [GET_LINK_AD_IMAGES]: async ({ state }) => {
    console.log(state)
    try {
      const response = await Vue.prototype.$okigasAxios 
        .get(
          "/api/okigas_link_setting",
        );
        return response.data
      }catch(err){
        console.log(err);
        throw err;
      }
  },
  [GET_TOP_OPEN_ACCEPTABLE_RANGE]: async ({state},application_type) => {
    console.log(state)
    try{
        const response = Vue.prototype.$okigasAxios .get(
            "/api/okigas_acceptable_range_setting", 
            {
              params:{
                application_type: application_type
              }
            }
        );
          return response.data;
    }catch(err){
        console.log(err);
        throw err;
    }
}
};