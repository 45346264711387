<template>
  <!-- 画面Top -->
  <v-app>
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>

    <v-main style="background-color:#FAFBFC">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card class="mt-3 mb-3" max-width="1000px">
              <v-form ref="formvalidate" v-model="confValid" lazy-validation>
                <!-- ステップ表示 -->
                <div class="step">
                    <v-row class="mb-0">
                      <v-col cols="12">
                        <v-stepper alt-labels flat>
                          <v-stepper-header v-model="e1">
                            <v-col cols="2">
                              <v-stepper-step
                                class="pr-0 pl-0"
                                :complete="e1 > 1"
                                step="1"
                              >
                                <font size="4" color="#1478D2">STEP1</font>
                              </v-stepper-step>
                            </v-col>
                            <v-col cols="2">
                              <v-stepper-step
                                class="pr-0 pl-0"
                                :complete="e1 > 2"
                                step="2"
                              >
                                <font size="4" color="#1478D2">STEP2</font>
                              </v-stepper-step>
                            </v-col>
                            <v-col cols="2">
                              <v-stepper-step
                                class="pr-0 pl-0"
                                :complete="e1 > 3"
                                step="3"
                              >
                                <font size="4" color="#1478D2">STEP3</font>
                              </v-stepper-step>
                            </v-col>
                            <v-col cols="2">
                              <v-stepper-step
                                class="pr-0 pl-0"
                                :complete="e1 == 4"
                                step="4"
                              >
                                <font size="4" color="#1478D2">STEP4</font>
                              </v-stepper-step>
                            </v-col>
                            <v-col cols="2">
                              <v-stepper-step class="pr-0 pl-0" step="5">
                                <font size="4">STEP5</font>
                              </v-stepper-step>
                            </v-col>
                          </v-stepper-header>
                        </v-stepper>
                      </v-col>
                    </v-row>
                  </div>

                <!-- 画面Top -->
                <div class="Top">
                  <v-row justify="center" class="center ma-5">
                    <v-col cols="12" class="pt-0">
                      <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="60"
                      max-width="500"
                    >
                      <h2><font color="#FFFFFF">ご利用開始のお申込み内容の確認</font></h2>
                    </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row class="ms-3 me-1" v-if="isReceptionNo">
                    <v-col cols="12" md="2" align="left" class="pt-0 pb-0">
                      <p>受付番号</p>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <p>{{ isReceptionNo }}</p>
                    </v-col>
                  </v-row>
                  <v-row class="ms-1 me-1 my-1">
                    下記の項目を入力の上、送信してください。
                  </v-row>
                </div>

                <!-- 申込者情報 -->
                <div calss="appInfo">
                  <v-row class="ms-1 me-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➀&ensp;お申し込み者情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col align="left">
                      <v-row>
                        <v-col cols="12" md="5" class="pb-0">
                          お申し込み者
                        </v-col>
                        <v-col cols="12" md="4" class="ms-3">
                          <v-row class="mt-1">
                            <div class="blue--text">{{ confInput.firstname }}&emsp;{{ confInput.lastname }}</div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4">
                        <v-col cols="12" md="5" class="pb-0">
                          お申し込み者電話番号
                        </v-col>
                        <v-col cols="12" md="4" class="pb-0">
                          <div class="blue--text">{{ confInput.tel }}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <!-- 申し込み場所情報 -->
                <div class="appPlace">
                  <v-row class="ms-1 me-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➁&ensp;ご利用申し込み場所の情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      ご利用場所のガス種
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ conversionKindsradios }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      築3ヵ月未満の建物ですか?
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ conversionAgeradios }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      お名前
                    </v-col>
                    <v-col cols="12" md="4" align="left" class="ms-3">
                      <v-row class="mt-1">
                        <div class="blue--text">{{ confInput.apfirstname }}&emsp;{{ confInput.aplastname }}</div>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      お名前（フリガナ）
                    </v-col>
                    <v-col cols="12" md="4" align="left" class="ms-3">
                      <v-row class="mt-1">
                        <div class="blue--text">{{ confInput.readfirstname }}&emsp;{{ confInput.readlastname }}</div>
                      </v-row>
                    </v-col>

                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      郵便番号
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.postcode }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      都道府県
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.prefectures }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      市町村
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.Municipality }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      町字
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.town }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                    番地
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.straddress }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      建物名
                    </v-col>
                    <v-col cols="12" md="6" align="left">
                      <div class="blue--text">{{ confInput.building }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      部屋番号
                    </v-col>
                    <v-col cols="12" md="3" align="left">
                      <div class="blue--text">{{ confInput.roomnum }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      電話番号
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ confInput.aptel }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      メールアドレス
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ confInput.address }}</div>
                    </v-col>
                  </v-row>
                </div>

                <!-- 利用開始希望日時 -->
                <div class="desDateTime">
                  <v-row class="ms-1 me-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➂&ensp;ガスのご利用開始希望日について</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col align="left">
                      <v-row>
                        <v-col cols="12" md="5" class="pb-0">
                          希望⽇
                        </v-col>
                        <v-col cols="12" md="4" align="left">
                          <div class="blue--text">{{ gauStartDate }}</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="5" align="left" class="pb-0">
                          希望時間
                        </v-col>
                        <v-col cols="12" md="4" align="left">
                          <div class="blue--text">{{ desiredTime }}</div>
                        </v-col>
                      </v-row>
                      <v-row class="mb-5">
                        <v-col>
                          ガスをご利用開始の当日は必ずお立合いが必要です<br>作業時間は60分程度です。
                        </v-col>
                      </v-row>
                      <!-- PLのみ表示 -->
                      <div v-if="confInput.kindsradios!='city_gas'">
                        <v-row>
                          <v-col>
                            賃貸住宅の場合等、ご入居の際に保険金10,000円のご用意をお願いいたします。
                          </v-col>
                        </v-row>
                        <v-row class="ml-5">
                          <v-checkbox
                            v-model="confInput.checkbox"
                            :disabled="confInput.checkbox === true"
                            :rules="[rules.check]"
                            label="承諾して申し込みます"
                          ></v-checkbox>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <!-- セット割申し込み -->
                <div class="elecSetApp">
                  <v-row class="ms-1 me-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➃&ensp;でんき及びセット割のお申し込みについて</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" align="left">
                      <v-row>
                        <v-col cols="12" md="5">
                          （１）「沖縄ガスのでんき」のご利用：
                        </v-col>
                        <v-col cols="12" md="6">
                          <div class="blue--text">{{ conversionAppradios }}</div>
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col>
                          <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                          <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                          <p>・沖縄本島全域</p>
                          <p>・沖縄本島と電力系統が繋がっている離島</p>
                          <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col cols="12" md="5">
                          ➀「沖縄ガスのでんき」の利用開始日：
                        </v-col>
                        <v-col cols="12" md="4">
                          <div class="blue--text">{{ electricityStartDate }}</div>
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col cols="12" md="5">
                          ➁「でんき料金のお知らせ」の郵送の 要・不要：
                        </v-col>
                        <v-col cols="12" md="4">
                          <div class="blue--text">{{ confDetailradios }}</div>
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col>
                          （「要」の場合、でんき料金に１１０円が加算されます。）
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col cols="12" md="5">
                          （２）でんきとガスのセット割を申し込む：
                        </v-col>
                        <v-col cols="12" md="4">
                          <div class="blue--text">{{ confSetAppradios }}</div>
                        </v-col>
                      </v-row>
                      <v-row v-if="confInput.appradios">
                        <v-col cols="12">
                          セット割の支払い方法は、口座振替またはクレジットとなります。<br>
                          また、「ご利用のお知らせは（検針票）」は、スマートフォンやパソコンから確認できるWEB料金明細サービスのみとなります。<br>
                          （紙による検針票が発行されませんので、予めご了承下さい。）
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <!-- でんき料金とガス料金のお支払い方法 -->
                <div class="GasPayment">
                  <v-row class="ms-1 me-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➄&ensp;でんき料金とガス料金のお支払い方法について</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col align="left">
                      <v-row>
                        <v-col cols="12" md="5" class="pb-0">
                          ガス料金のWEB料金明細サービスの利用を申し込む
                        </v-col>
                        <v-col cols="12" md="4" align="left">
                          <div class="blue--text">{{ webItemizedCharges }}</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="5" align="left" class="pb-0">
                          ガス料金のお支払い方法
                        </v-col>
                        <v-col cols="12" md="4" align="left">
                          <div class="blue--text">
                            <div v-if="confInput.gasPayment === 1">
                              口座振替（沖縄銀行、琉球銀行、ゆうちょ銀行、沖縄県労金、コザ信金、JAおきなわ）
                            </div>
                            <div v-else-if="confInput.gasPayment === 2">
                              <v-row class="ma-0">
                                &nbsp;&ensp;クレジットカード （ご利用できるカード： 
                                <v-img
                                  class="shrink mr-2"
                                  :src="require('../../assets/card_5brand.png')"
                                  width="230"
                                  height="25"
                                  contain
                                />
                                ）
                              </v-row>
                            </div>
                            <div v-else-if="confInput.gasPayment === 3">
                              払込票（発行手数料としてガス料金に220円を加算して請求いたします。）
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <!-- その他 -->
                <div class="Other">
                  <v-row class="ms-1 me-1">
                    <v-col align="left">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                      <h3><font color="#009DE6">➅&ensp;その他（代理店記入）</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1"> 
                    <v-col class="pb-0" cols="12" md="4" align="left">  
                      代理店番号
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ confInput.agencynum }}</div>
                      <!--<v-text-field
                        v-model="confAgencynum"
                        clearable
                        outlined
                        dense
                      ></v-text-field>-->
                    </v-col>
                  </v-row>

                  <v-row v-show="confqrCodePara" class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left">
                      QRコードのパラメーター
                    </v-col>
                    <v-col cols="12" md="4" align="left">
                      <div class="blue--text">{{ confInput.qrpara }}</div>
                      <!--<v-text-field
                        v-model="confQrpara"
                        clearable
                        outlined
                        dense
                      ></v-text-field>-->
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1 pt-3 pb-3">
                    上記の内容を確認し、申込を確定します。
                  </v-row>
                  <div>
                    <v-overlay 
                      :value="loading"
                      class="centered"
                      color="rgba(204,204,204,0.6)"
                    >
                      <v-progress-circular
                        indeterminate
                        size="40"
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>

                  <v-row align="end" justify="center">
                    <v-col cols="4" md="2">
                      <v-btn 
                        color="gray"
                        width="100%"
                        @click="back"
                      >
                        戻る
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-btn
                        variant="outlined"
                        color="#009DE6"
                        width="100%"
                        class="white--text"
                        @click="submit"
                      >
                        登録
                      </v-btn>
                    </v-col> 
                  </v-row>
                </div>
              </v-form>  
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView/>
  </v-app>
</template>

<script>
import {
  mapState,
  mapActions,
} from "vuex";
import {
  POST_INPUT_APPDATA,
  PUT_OPEN_UPDATA
} from "@/store/action-types";
import FooterView from "@/components/common/GlobalFooter/index.vue";
import moment from "moment";
export default {
  nama:'ConfStartUse',
  components:{
    FooterView,
  },
  data: () => ({
    confValid:"",
    confReceiptnum:"",
    confAgencynum:"",
    confQrpara:"",
    elcdate:"",
    confqrCodePara:false,
    loading: false,
    rules:{
      check: checkvalue =>!!checkvalue,
      required: value => !!value || '必須項目です。',
      renum: value => value.length >= 5 || '5桁以上で入力',
      numcheck: value => /^[0-9]*$/.test(value) ||'数字で入力',
    },
    e1:4,
  }),
  computed:{
    ...mapState({
      //ここは参照したいステートを呼び出す
      confInput: state => state.ConfAppStartUseForm.confInput,
      getAllResponseData: state => state.topApplicationForm.getAllResponseData, 
    }),
    isReceptionNo(){
      return this.getAllResponseData?.okigas_application?.reception_no ? this.getAllResponseData?.okigas_application?.reception_no : false
    },
    gauStartDate(){
      return moment(this.confInput.startdate).locale('ja').format("YYYY-MM-DD(ddd)");
    },
    desiredTime(){
      if (this.confInput.time === "00:00～23:59") {
        return "時間指定なし";
      } else {
        return this.confInput.time;
      }
    },
    electricityStartDate(){
      return (
        this.confInput.appradios == 0 ? "" : this.confInput.startradios == 0 
        ? "でんきとガスご利用開始日は同じ" : moment(this.confInput.elstartdate).locale('ja').format("YYYY-MM-DD(ddd)")
        ) 
    },
    confDetailradios(){
      return (this.confInput.appradios == 0 ?"":this.confInput.detailradios == 0 ? "不要" :this.confInput.detailradios == 1 ? "要" : "")
    },
    confSetAppradios(){
      return (this.confInput.appradios== 0 ?"":this.confInput.setradios == 0 ? "申し込まない":this.confInput.setradios == 1 ? "申し込む" : "")
    },
    conversionKindsradios(){
      return (this.confInput.kindsradios == "city_gas" ? "都市ガス" : this.confInput.kindsradios == "propane_gas" ? "プロパンガス" : this.confInput.kindsradios == "unknown" ? "不明" : "")
    },
    conversionAgeradios(){
      return (this.confInput.ageradios == 0 ? "いいえ" : this.confInput.ageradios == 1 ? "はい" : "")
    },
    conversionAppradios(){
      return (this.confInput.appradios == 0 ? "申し込まない" : this.confInput.appradios == 1 ? "申し込む" : "")
    },
    webItemizedCharges(){
      return (
        (this.confInput.web_itemized_charges === 0) ? "申し込まない"
        : (this.confInput.web_itemized_charges === 1) ? "申し込む"
        : ""
      )
    },
  },
  methods:{
    ...mapActions({
      postInputAppData: POST_INPUT_APPDATA,
      putOpenUpdata: PUT_OPEN_UPDATA
    }),
    async submit() {
      this.loading = true;
      let payload = {
        okigas_application: {}
      };
      // 新規申し込みなのか、更新の申し込みなのかで、作成するデータの処理を分岐する
      payload.okigas_application.status = this.confInput.status
      payload.okigas_application.application_type = "open_faucet"
      payload.okigas_application.applicant_last_name = this.confInput.firstname
      payload.okigas_application.applicant_first_name = this.confInput.lastname
      payload.okigas_application.applicant_tel_number = this.confInput.tel
      payload.okigas_application.gas_kind = this.confInput.kindsradios
      payload.okigas_application.is_less_than_3months = this.confInput.ageradios
      payload.okigas_application.last_name = this.confInput.apfirstname
      payload.okigas_application.first_name = this.confInput.aplastname
      payload.okigas_application.last_name_kana = this.confInput.readfirstname
      payload.okigas_application.first_name_kana = this.confInput.readlastname
      payload.okigas_application.postal_code = this.confInput.postcode
      payload.okigas_application.pref = this.confInput.prefectures
      payload.okigas_application.municipality = this.confInput.Municipality
      payload.okigas_application.address1 = this.confInput.town
      payload.okigas_application.address2 = this.confInput.straddress
      payload.okigas_application.building = this.confInput.building
      payload.okigas_application.room_number = this.confInput.roomnum
      payload.okigas_application.tel_number = this.confInput.aptel
      payload.okigas_application.mail = this.confInput.address
      payload.okigas_application.preferred_date = this.confInput.startdate
      payload.okigas_application.preferred_time = this.confInput.time
      payload.okigas_application.deposit_agreement = this.confInput.checkbox
      payload.okigas_application.gas_plan = ""
      payload.okigas_application.is_use_electricity = this.confInput.appradios
      payload.okigas_application.electricity_start_select = this.confInput.startradios
      payload.okigas_application.electricity_start_date = this.confInput.elstartdate
      payload.okigas_application.is_electricity_news = this.confInput.detailradios
      payload.okigas_application.is_set_discount = this.confInput.setradios
      payload.okigas_application.web_itemized_charges = this.confInput.web_itemized_charges
      payload.okigas_application.gasPayment = this.confInput.gasPayment
      payload.okigas_application.agency_number = this.confInput.agencynum
      payload.okigas_application.qr_parameter = this.confInput.qrpara
      payload.okigas_application.updated_by = "application@user"

      // ユーザー名を設定
      payload.user_name = "application@user";

      // 新規申し込みなのか、更新の申し込みなのかで、叩くＡＰＩを変える
      let selectApi = this.getAllResponseData.okigas_application;
      console.log('開栓の全レスポンス',this.selectApi)

      if (selectApi != undefined) {
        //更新API呼び出し
        payload.okigas_application.application_id = selectApi.application_id;
        console.log('updataApplication_id',payload.okigas_application.application_id)
        await this.putOpenUpdata({
          new: payload,
          old: this.getAllResponseData
        });

        // ローカルストレージに保存しているパラメータを削除
        localStorage.removeItem('qr');

        // 完了画面に遷移
        this.loading = false;
        this.$router.push('/AppStartCompleted');
      } else { 
        //新規追加API呼び出し
        console.log('application_id',payload.okigas_application.application_id)
        payload.okigas_application.application_id = crypto.randomUUID();
        console.log('application_id',payload.okigas_application.application_id)
        const result = await this.postInputAppData(payload);
        if (result?.data?.status == 500) {
          this.loading = false;
          if (result?.data?.code === 'MaximumReservationError') {
            this.$dialog.show({
              title: "申込がいっぱいです。",
              text: "ご希望の時間帯は既に他の申し込みで埋まっているため申し込みいただけません。",
              type: "warning",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる",
            });
          } else {
            this.$dialog.show({
              title: "不明なエラーが発生しました。",
              text: "時間を置いても解決しない場合、お問い合わせください",
              type: "error",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる",
            });
          }
        } else {
          // ローカルストレージに保存しているパラメータを削除
          localStorage.removeItem('qr');

          // 完了画面に遷移
          this.loading = false;
          this.$router.push('/AppStartCompleted');
        }
      }
    },
    back() {
      return this.$router.push('/AppStartAgreement');
    },
  },
}
</script>

<style scoped>
@media screen and (max-width:600px) {
  .v-toolbar__title {
    font-size: .8em;
  }
  .Top h2 {
    font-size: 1em;
  }
  h3 {
    font-size: .9em;
  }
}
::v-deep .v-stepper__label {
  display: grid !important;
}
</style>