import{
  SET_TOP_INPUT,
  SET_RESPONSE_DATA,
  INITIALIZE_STATE,
  SET_URL_PARAMS,
} from "@/store/mutation-types";

export default {
[SET_TOP_INPUT]:  (state, value) => {
  state.topInput = value
},

//GETで取得したレスポンスを受け取る
[SET_RESPONSE_DATA]:(state, data) => {
  state.getAllResponseData = data;
},

//stateの初期化
[INITIALIZE_STATE]:(state) => {
  state.getAllResponseData = "";
},
[SET_URL_PARAMS]:  (state, value) => {
  state.urlParams = value
},
};
