//申し込み開始確認画面
export const SET_INPUT = "SET_INPUT";
// ご利用停止内容確認画面
export const SET_INPUT_DATA = "SET_INPUT_DATA";
//トップ画面インプット
export const SET_TOP_INPUT = "SET_TOP_INPUT";
//GETで取得したレスポンス
export const SET_RESPONSE_DATA = "SET_RESPONSE_DATA";
//stateを初期化する
export const INITIALIZE_STATE = "INITIALIZE_STATE";
//開栓同意画面GETで取得したリンク
export const SET_LINK_DISPLAY = "SET_LINK_DISPLAY";
//閉栓同意画面GETで取得したリンク
export const SET_LINK_CLOSE_DISPLAY = "SET_LINK_CLOSE_DISPLAY";
export const SET_URL_PARAMS = "SET_URL_PARAMS";
// 開栓の新規/更新した際の登録された申し込み情報
export const SET_RES_KAIHEI_DATA = "SET_RES_KAIHEI_DATA";
// 閉栓の新規/更新した際の登録された申し込み情報
export const SET_RES_HEISEN_DATA = "SET_RES_HEISEN_DATA";
