
// 画像圧縮処理
export const compressImage = async (file, maxSize) => {
  const quality = 1.0;
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = function () {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let width = image.width;
      let height = image.height;

      canvas.width = width;
      canvas.height = height;

      // 画像をcanvasに描画
      ctx.drawImage(image, 0, 0, width, height);

      // canvasからデータURLを取得し、JPEG形式で圧縮
      let dataURL = canvas.toDataURL('image/jpeg', quality);

      // 圧縮された画像のファイルサイズを取得
      let compressedFileSize = Math.round(dataURL.length * 0.75);
      console.log('初回の画像サイズ', compressedFileSize)
      
      // 200KBより大きかったらさらに縮小
      let i = 1
      if (compressedFileSize > maxSize) {      
        const n = maxSize / compressedFileSize
        dataURL = canvas.toDataURL('image/jpeg', n.toFixed(1));
        compressedFileSize = Math.round(dataURL.length * 0.75);
      }
      if (compressedFileSize > maxSize) {
        while (i > 0){
          width = 0.85 * width;
          height = 0.85 * height;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(image, 0, 0, width, height);
          dataURL = canvas.toDataURL('image/jpeg', 0.8);
          compressedFileSize = Math.round(dataURL.length * 0.75);
          console.log(i + '回目のサイズ : ', compressedFileSize)
          i++;
          if(compressedFileSize < maxSize) {
            break;
          }
        }
      }
      // Promiseを解決して、圧縮された画像データを返す
      resolve(dataURL);
    };

    image.onerror = function () {
      reject('画像の読み込みに失敗しました。');
    };
  });
}

export const Mixin = {
  methods: {
    compressImage,
  },
};