<template> 
  <SuspendCompleteScreen />
</template>
  
<script>
  import SuspendCompleteScreen from '../components/Home/SuspendCompleteScreen'
  export default {
    name: 'SuspendCompleteScreenView',

    components: {
      SuspendCompleteScreen,
    },
    beforeRouteLeave (to, from, next) {
      // ブラウザの戻るボタンが押されたときにTOPページにリダイレクト
      if (from.name === "SuspendCompleteScreenPage" && to.name === "SuspendContentConfirmationPage") {
        this.$router.go({path: '/', force: true})
      } else {
        next();
      }
    }
  }
</script>