<template>
  <AppStartUseCompleted />
</template>

<script>
  import AppStartUseCompleted from '../components/AplStart/AppStartCompleted.vue'
  export default {
    name: 'ConfAppStartUseFormView',
    components:{
      AppStartUseCompleted,
    },
    beforeRouteLeave (to, from, next) {
      // ブラウザの戻るボタンが押されたときにTOPページにリダイレクト
      if (from.name === "AppStartCompleted" && to.name === "ConfAppStartUse") {
        this.$router.go({path: '/', force: true})
      } else {
        next();
      }
    }
  }
</script>