//ご利用停止入力フォーム画面のvuexをインポート
import state from "./SuspendApplicationViewForm.state";
import mutations from "./SuspendApplicationViewForm.mutations";
import actions from "./SuspendApplicationViewForm.actions";

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};