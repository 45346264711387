<template>
     <StartUse />
</template>

<script>
  import StartUse from'../components/AplStart/StartUse.vue'

  export default{
    name: 'StartUseView',
    components:{
      StartUse
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>