<template> 
  <SuspendContentConfirmationForm />
</template>
  
<script>
  import SuspendContentConfirmationForm from '../components/Home/SuspendContentConfirmationForm'
  export default {
    name: 'SuspendContentConfirmationView',

    components: {
      SuspendContentConfirmationForm,
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>