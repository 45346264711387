<template> 
  <top-form />
</template>

<script>
  import TopForm from '../components/Home/TopApplicationForm/TopForm'

  export default {
    name: 'TopApplicationFormView',

    components: {
      TopForm,
    },
  }
</script>