export const POST_INPUT_DATA = "POST_INPUT_DATA";
export const POST_INPUT_APPDATA = "POST_INPUT_APPDATA";
export const GET_SEARCH_SCREEN = "GET_SEARCH_SCREEN";
export const PUT_OPEN_UPDATA = "PUT_OPEN_UPDATA";
export const PUT_CLOSE_UPDATA = "PUT_CLOSE_UPDATA";
export const PUT_CANCEL_OPENAPPLICATION = "PUT_CANCEL_OPENAPPLICATION";
export const PUT_CANCEL_CLOSEAPPLICATION = "PUT_CANCEL_CLOSEAPPLICATION";
export const GET_OPEN_RESERVATION_COUNT ="GET_OPEN_RESERVATION_COUNT";
export const GET_CLOSE_RESERVATION_COUNT = "GET_CLOSE_RESERVATION_COUNT";
export const GET_OPEN_ACCEPTABLE_RANGE = "GET_OPEN_ACCEPTABLE_RANGE";
export const GET_CLOSE_ACCEPTABLE_RANGE = "GET_CLOSE_ACCEPTABLE_RANGE";
export const GET_LINK_AD_IMAGES = "GET_LINK_AD_IMAGES";
export const GET_LINK_SETTING = "GET_LINK_SETTING";
export const GET_LINK_SETTING_CLOSE = "GET_LINK_SETTING_CLOSE";
export const GET_TOP_OPEN_ACCEPTABLE_RANGE = "GET_TOP_OPEN_ACCEPTABLE_RANGE";

