import Vue from 'vue'
import {
  PUT_CANCEL_OPENAPPLICATION,
  GET_OPEN_RESERVATION_COUNT,
  GET_OPEN_ACCEPTABLE_RANGE,
} from "@/store/action-types";

export default{
  //開栓申し込みキャンセル
  [PUT_CANCEL_OPENAPPLICATION]: async ({state}, req) => {
    console.log(state)
    
     return await Vue.prototype.$okigasAxios
      .put(
        "/api/okigas_application/open_faucet/cancel", 
        req
      )
      .then(() => {})
      .catch((err) => console.log(err));
    
  },
  [GET_OPEN_RESERVATION_COUNT]: async ({state},req) => {
    console.log(state)
    try {
      const response = await Vue.prototype.$okigasAxios.get(
        "/api/okigas_reception_setting",
        {
          params:{
            application_type: req. applicationType,
            start_date: req. start,
            end_date: req. end,
          },
        }
      );
        return response.data;
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  [GET_OPEN_ACCEPTABLE_RANGE]: async ({ state }, application_type) => {
    console.log(state)
    try{
      const response = await Vue.prototype.$okigasAxios.get(
        "/api/okigas_acceptable_range_setting",
        {
          params: {
            application_type: application_type
          }
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
} 
