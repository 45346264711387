export default {
  confInput:{},
  resKaiheiData: {},

  /*
  //申し込み者情報
  confFirstname: null,
  confLastname: null,
  confTel: null,
  //申し込み場所情報
  confGaskinds: null,
  confAge: null,
  confApfirstname: null,
  confAplastname: null,
  confReadfirstname: null,
  confReadlastname: null,
  confPostcode: null,
  confPrefectures: null,
  confMunicipality: null,
  conttown: null,
  confStraddress: null,
  confBuilding: null,
  confRoomnum: null,
  confAptel: null,
  confCalendarmenu: null,
  confAddress: null,
  conftime: null,
  //でんきのセット割申し込み
  confElecSet: null,
  confElecSetApp: null,
  confPriceInformation: null,
  confElecGasSetApp: null,
  */
  };