import {
  SET_INPUT_DATA,
  SET_RES_HEISEN_DATA,
  } from "@/store/mutation-types";
  
  export default {
    [SET_INPUT_DATA]: (state, value) => {
      state.FormData = value;
    },
    [SET_RES_HEISEN_DATA]: (state, resHeisenData) => {
      state.resHeisenData = resHeisenData;
    },
  };