<template>
  <v-app>
    <!-- ヘッダー -->
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>
    <v-main style="background-color:#FAFBFC">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card
              max-width="1000px"
              class="mt-3 mb-3"
            >
              <!--ステップ表示-->
              <div class="step">
                <v-row class="mb-10">
                  <v-col cols="12">
                    <v-stepper alt-labels flat>
                      <v-stepper-header v-model="e1">
                        <v-col cols="2">
                          <v-stepper-step
                            class="pr-0 pl-0"
                            :complete="e1 > 1"
                            step="1"
                          >
                            <font size="4" color="#1478D2">STEP1</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step
                            class="pr-0 pl-0"
                            :complete="e1 > 2"
                            step="2"
                          >
                            <font size="4" color="#1478D2">STEP2</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step
                            class="pr-0 pl-0"
                            :complete="e1 > 3"
                            step="3"
                          >
                            <font size="4" color="#1478D2">STEP3</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step
                            class="pr-0 pl-0"
                            :complete="e1 == 4"
                            step="4"
                          >
                            <font size="4" color="#1478D2">STEP4</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="5">
                            STEP5
                          </v-stepper-step>
                        </v-col>
                      </v-stepper-header>
                    </v-stepper>
                  </v-col>
                </v-row>
              </div>

              <!-- タイトル -->
              <div class="tytle">
                <v-row justify="center" class="center ma-5">
                  <v-col class="pt-0">
                    <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="88.5"
                      max-width="550"
                    >
                      <h2 class="ms-1 me-1"><font color="#FFFFFF">「お引越しによるご利用停止」の<br>お申し込み内容の確認</font></h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>

              <!-- 説明 -->
              <div class="Description">
                <v-row class="ms-3 me-1" v-if="isReceptionNo">
                  <v-col cols="4" md="2" align="left">
                    受付番号
                  </v-col>
                  <v-col cols="6" md="6" align="left" class="pb-0">
                    <p>{{ isReceptionNo }}</p>
                  </v-col>
                </v-row>
                <v-row class="ms-1">
                  <v-col align="left">
                    下記の項目を入力の上、送信してください。
                  </v-col>
                </v-row>
              </div>

              <!-- 1.お申し込み者情報 -->
              <div class="ApplicantInformation">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">⓵&ensp;お申し込み者情報</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    お申し込み者
                  </v-col>
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0 pe-0">
                    <font color="#2196F3">{{ OutputFormData.ApplicantSurname }}&emsp;{{ OutputFormData.ApplicantName }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    お申し込み者電話番号
                  </v-col>
                  <v-col cols="12" md="6" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ApplicantPhoneNumber }}</font>
                  </v-col>
                </v-row>
              </div>

              <!-- 2.ご利用場所ご契約情報 -->
              <div class="ContractInformation">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➁&ensp;ご利用場所ご契約情報</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    ご利用場所のガス種
                  </v-col>
                  <v-col cols="3" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ conversionRadioGasType }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    お名前
                  </v-col>
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorSurname }}&emsp;{{ OutputFormData.ContractorName }}</font>
                  </v-col>
                </v-row> 
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    お名前（フリガナ）
                  </v-col>
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorSurnameFuri }}&emsp;{{ OutputFormData.ContractorNameFuri }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    郵便番号
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorPostalCode }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    都道府県
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorPrefectures }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    市町村
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorMunicipality }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    町字
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorTownLetter }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    番地
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorBlock }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    建物名
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorBuildingName }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    部屋番号
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorRoomNumber }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    電話番号
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorPhoneNumber }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    メールアドレス
                  </v-col>
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ContractorEmail }}</font>
                  </v-col>
                </v-row>
              </div>

              <!-- 3.ガスの最終ご利用日について -->
              <div class="SuspendDesiredTime">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➂&ensp;ガスの最終ご利用日について</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    ガスの最終ご利用⽇
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ gasLastUseDate }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-1 me-1 mb-5">
                  <v-col cols="12" align="left" class="pt-0 pb-0">
                    <font color="#CD0001">ガスのご利用停止はオートロックなどの場合、立ち合いが必要です。</font>
                  </v-col>
                </v-row>
              </div>

              <!-- 4.ご利用場所のガス契約情報 -->
              <div class="GasContractInformation">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➃&ensp;ご利用場所のガス契約情報</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-1 me-1 mb-5">
                  <v-col cols="7" md="4" align="left" class="pt-0 pb-0">
                    （１）ガス料金番号
                  </v-col>
                  <v-col cols="5" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.GasChargeNumber }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-5 me-1 mb-5"> 
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    検針票の画像
                  </v-col>
                  <v-col cols="12" md="6" align="left">
                    <div v-if="OutputFormData.MeterImageUrl">
                      <v-img 
                        class="mt-5"
                        :src="OutputFormData.MeterImageUrl"
                        aria-label="検針票の画像"
                        height="auto"
                        width="auto"
                        style="border:3px #000000 solid;"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <!-- 5.引っ越し先の情報 -->
              <div class="InformationNextHome">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➄&ensp;引っ越し先の情報</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    郵便番号
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomePostalCode }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    都道府県
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomePrefectures }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    市町村
                  </v-col>
                  <v-col cols="8" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomeMunicipality }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    町字
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomeTownLetter }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    番地
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomeBlock }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    建物名
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomeBuildingName }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="4" md="4" align="left" class="pt-0 pb-0">
                    部屋番号
                  </v-col>
                  <v-col cols="8" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.NextHomeRoomNumber }}</font>
                  </v-col>
                </v-row>
              </div>

              <!-- 6.保証金預かりの返金について -->
              <div v-if="OutputFormData.RadioGasType !== 'city_gas'" class="DepositRefund">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➅&ensp;保証金預かりの返金について</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1">
                  <v-col align="left" class="pb-0">
                    保証金の返金方法
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-5">
                  <v-col align="left">
                    賃貸住宅の場合等、ご入居の際に保証金10,000円をお預かりしたお客さまは最終のガス料金のお支払いが確定後に保証金を返金させていただきます。
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-7">
                  <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                    保証金預かりNO
                  </v-col>
                  <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.CustodyDepositNumber }}</font>
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-7">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    保証金預かり証の画像
                  </v-col>
                  <v-col cols="12" md="6" align="left">
                    <div v-if="OutputFormData.DepositImageUrl">
                      <v-img
                        class="mt-5"
                        :src="OutputFormData.DepositImageUrl"
                        aria-label="保証金預かり証の画像"
                        height="auto"
                        width="auto"
                        style="border:3px #000000 solid;"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <div v-if="OutputFormData.RadioGasType === 'propane_gas' || OutputFormData.RadioGasType === 'unknown'">
                  <v-row class="ms-4 me-1 mb-7">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      保証金の受け取り口座指定について
                    </v-col>
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.isBankDesignation === 1 ? '保証金の受け取り口座を指定しない。' : OutputFormData.isBankDesignation === 2 ? '保証金の受け取り口座を指定する。' : '' }}</font>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="(OutputFormData.RadioGasType === 'propane_gas' || OutputFormData.RadioGasType === 'unknown') && OutputFormData.isBankDesignation === 2">
                  <v-row class="ms-4 me-1 mb-7">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      口座名義
                    </v-col>
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.AccountHolder }}</font>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="(OutputFormData.RadioGasType === 'propane_gas' || OutputFormData.RadioGasType === 'unknown') && OutputFormData.BankSelectionRadio === 0 && OutputFormData.isBankDesignation === 2">
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      銀行・金庫・農協の名称
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.BankName }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      口座の種類
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ conversionAccountType }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      支店名
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.BranchName }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      支店番号
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.BranchOfficeNumber }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      口座番号
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.BankAccountNumber }}</font>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="(OutputFormData.RadioGasType === 'propane_gas' || OutputFormData.RadioGasType === 'unknown') && OutputFormData.BankSelectionRadio === 1 && OutputFormData.isBankDesignation === 2">
                  <v-row class="ms-4 me-1 mb-5">
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      銀行名
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ conversionBankSelection }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-4 me-1 mb-5"> 
                    <v-col cols="6" md="4" align="left" class="pt-0 pb-0">
                      口座番号
                    </v-col>
                    <v-col cols="6" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ OutputFormData.PostBankSymbol }}</font>
                      <font color="#2196F3"><b>-</b>{{ OutputFormData.PostBankNum }}</font>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <!-- 7.でんきのご利用の停止およびお引越し先のご利用について -->
              <div class="AboutUse">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➆&ensp;でんきのご利用の停止およびお引越し先のご利用について</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col align="left" class="pb-0">
                    〇{{ conversionUseOkinawaGasElectric }}
                  </v-col>
                </v-row>
              </div>
              <!-- 現在のガスご利用場所で、「沖縄ガスのでんき」を利用している場合 -->
              <div v-if="OutputFormData.UseOkinawaGasElectricQuestion === 0 ">
                <v-row class="ms-2 me-1 mb-5">
                  <v-col align="left">
                    （１）現在のご利用場所で、「でんき」のご利用を停止しますか？
                  </v-col>
                </v-row>
                <div v-if="OutputFormData.ElectricalSuspendQuestion === 1 ">
                  <v-row class="ms-4 me-1 mb-5">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="2" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">〇{{ conversionElectricalSuspend }}</font>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      でんきの最終ご利用日
                    </v-col>
                    <v-col cols="12" md="6" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">{{ UsingElectricalSuspendDate }}</font>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <div v-if="OutputFormData.ElectricalSuspendQuestion === 0 ">
                  <v-row class="ms-4 me-1 mb-5">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="11" align="left" class="pt-0 pb-0">
                      <font color="#2196F3">〇{{ conversionElectricalSuspend }}</font>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <v-row class="ms-2 me-1 mb-5">
                  <v-col align="left" class="pb-0">
                    （２）引っ越し先で、「沖縄ガスのでんき」を継続利用を申し込みますか？
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-5">
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="2" align="left" class="pt-0 pb-0">
                    <font color="#2196F3"> 〇{{ conversionContinuousUse }}</font>
                  </v-col>
                  <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                    <div v-if="OutputFormData.ContinuousUseQuestion === 1">でんきのご利用開始⽇</div>
                  </v-col>
                  <v-col cols="12" md="6" align="left" class="pt-0 pb-0">
                    <font v-if="OutputFormData.ContinuousUseQuestion === 1" color="#2196F3">{{ ContinuousUseStartDate }}</font>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <div v-if="OutputFormData.ContinuousUseQuestion === 1">
                  <v-row class="ms-9 me-1 mb-5">
                    <v-col align="left">
                      <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                      <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                      <p>・沖縄本島全域</p>
                      <p>・沖縄本島と電力系統が繋がっている離島</p>
                      <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                    </v-col>
                  </v-row>
                </div>
                <!-- <v-row class="ms-2 me-1 mb-5">
                  <v-col align="left" class="pb-0">
                    （３）沖縄ガスのでんきの最終支払方法について
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-5">
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="11" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">〇{{ conversionGasPayment }}</font>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row> -->
              </div>
              <!-- 現在のガスご利用場所で、「沖縄ガスのでんき」を利用していない場合 -->
              <div v-if="OutputFormData.UseOkinawaGasElectricQuestion === 1">
                <v-row class="ms-4 me-1">
                  <v-col class="pb-0" cols="12" align="left">
                    お引越し先で、「沖縄ガスのでんき」を新規で申し込みますか
                  </v-col>
                </v-row>
                <v-row class="ms-4 me-1 mb-5">
                  <v-spacer></v-spacer>
                  <v-col cols="11" align="left">
                    <font color="#2196F3">〇{{ conversionNewApplication }}</font>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                </v-row>
                <div v-if="OutputFormData.NewApplicationQuestion === 1">
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col class="pb-0" cols="12" md="5" align="left">
                      （１）でんきのご利用開始⽇
                    </v-col>
                    <v-col cols="12" md="6" align="left">
                      <font color="#2196F3">{{ NewUseStartDate }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col class="pb-0" cols="12" md="5" align="left">
                      （２）「でんき料金のお知らせ」の郵送の要・不要<br>
                      &emsp; &emsp;（「要」の場合、でんき料金に１１０円が加算されます。）
                    </v-col>
                    <v-col cols="12" md="6" align="left">
                      <font color="#2196F3">{{ convertionAnnouncementAvailability }}</font>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" align="left">
                      （３）でんき料金のお支払い方法
                    </v-col>
                  </v-row>
                  <v-row class="ms-10 me-1">
                    <v-col class="pt-0" cols="12" md="11" align="left">
                      お支払いは、口座振替とクレジット支払のみとなります。<br>
                      お支払いに関する手続き用紙は、ご利用場所に別途郵送いたします。<br>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-5">
                    <v-col align="left">
                      <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                      <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                      <p>・沖縄本島全域</p>
                      <p>・沖縄本島と電力系統が繋がっている離島</p>
                      <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <!-- 8.その他（代理店記入） -->
              <div class="Other">
                <v-row class="ms-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➇&ensp;その他（代理店記入）</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row class="ms-2 me-1 mb-5">
                  <v-col cols="5" md="4" align="left" class="pt-0 pb-0">
                    代理店番号
                  </v-col>
                  <v-col cols="7" md="4" align="left" class="pt-0 pb-0">
                    <font color="#2196F3">{{ OutputFormData.ProxyStoreNumber }}</font>
                  </v-col>
                </v-row>
                <v-row v-show="confqrCodePara" class="ms-1 me-1 mb-5">
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    QRコードのパラメーター
                  </v-col>
                  <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                    {{ OutputFormData.QRCodeParameter }}
                  </v-col>
                </v-row>
              </div>
              <div>
                <v-overlay
                  :value="loading"
                  class="centered"
                  color="rgba(204,204,204,0.6)"
                >
                  <v-progress-circular
                    indeterminate
                    size="40"
                    color="primary"
                    ></v-progress-circular>
                </v-overlay>
              </div>
              <!-- 次へ/戻るボタン -->
              <div class="submit">
                <v-row class="mt-5" justify="center">
                  <v-col cols="4" md="5" align="right">
                    <v-btn
                      color="gray"
                      @click="backBtn"
                      width="40%"
                    >戻る
                    </v-btn>
                  </v-col>
                  <v-col cols="4" md="5" align="left">
                    <v-btn
                      color="#009DE6"
                      @click="submit"
                      width="40%"
                    >
                    <font color="white">登録</font>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView/>
  </v-app>
</template>

<script>
import FooterView from "@/components/common/GlobalFooter/index.vue";
import {
  mapState, 
  mapActions,
} from "vuex";
import {
  POST_INPUT_DATA,
  PUT_CLOSE_UPDATA,
} from "@/store/action-types";
import moment from "moment";
export default {
  components: {
    FooterView
  },
  data() {
    return {
      ReceptionNumber:"",
      e1:4,
      confqrCodePara:false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      OutputFormData: state => state.SuspendContentConfirmationForm.FormData,
      getAllResponseData: state => state.topApplicationForm.getAllResponseData,  
    }),
    isReceptionNo() {
      return this.getAllResponseData?.okigas_application?.reception_no ? this.getAllResponseData?.okigas_application?.reception_no : false
    },
    gasLastUseDate() {
      return moment(this.OutputFormData.GasLastUseDate).locale('ja').format("YYYY-MM-DD(ddd)");
    },
    UsingElectricalSuspendDate() {
      return moment(this.OutputFormData.UsingElectricalSuspendDate).locale('ja').format("YYYY-MM-DD(ddd)");
    },
    ContinuousUseStartDate() {
      return moment(this.OutputFormData.ContinuousUseStartDate).locale('ja').format("YYYY-MM-DD(ddd)");
    },
    NewUseStartDate() {
      return moment(this.OutputFormData.NewUseStartDate).locale('ja').format("YYYY-MM-DD(ddd)");
    },
    conversionRadioGasType() {
      return(
        this.OutputFormData.RadioGasType == "city_gas" ? "都市ガス" : this.OutputFormData.RadioGasType == "propane_gas" ? "プロパンガス" : this.OutputFormData.RadioGasType == "unknown" ? "不明" : ""
      )
    },
    conversionUseOkinawaGasElectric() {
      return(
        this.OutputFormData.UseOkinawaGasElectricQuestion == 0 ? "ガスをご利用している場所で、「沖縄ガスのでんき」を利用している場合" : this.OutputFormData.UseOkinawaGasElectricQuestion == 1 ? "ガスをご利用している場所で、「沖縄ガスのでんき」を利用していない場合" :""
      )
    },
    conversionElectricalSuspend() {
      return(
        this.OutputFormData.ElectricalSuspendQuestion == 1 ? "はい" : this.OutputFormData.ElectricalSuspendQuestion == 0 ? "いいえ（でんきは、継続して利用する。）": ""
      )
    },
    conversionContinuousUse() {
      return(
        this.OutputFormData.ContinuousUseQuestion == 1 ? "申し込む" : this.OutputFormData.ContinuousUseQuestion == 0 ? "申し込まない" : ""
      )
    },
    conversionGasPayment() {
      return(
        this.OutputFormData.GasPaymentSelection == 0 ? "現在と同じ支払方法で行う" : this.OutputFormData.GasPaymentSelection == 1 ? "お引越し先に払込票を郵送する" :""
      )
    },
    conversionNewApplication() {
      return(
        this.OutputFormData.NewApplicationQuestion == 1 ? "申し込む" : this.OutputFormData.NewApplicationQuestion == 0 ? "申し込まない" : ""
      )
    },
    convertionAnnouncementAvailability() {
      return(
        this.OutputFormData.AnnouncementAvailability ==  1 ? "要" : this.OutputFormData.AnnouncementAvailability ==  0 ? "不要" : ""
      )
    },
    conversionAccountType() {
      return(
        this.OutputFormData.AccountTypeRadio == 0 ? "普通" : this.OutputFormData.AccountTypeRadio == 1 ? "当座" : ""
      )
    },
    conversionBankSelection() {
      return(
        this.OutputFormData.BankSelectionRadio == 0 ? "銀行・金庫・農協の名称" : this.OutputFormData.BankSelectionRadio == 1 ? "ゆうちょ銀行" : ""
      )
    },
  },
  methods: { 
    ...mapActions({
      postInputData: POST_INPUT_DATA,
      putCloseUpdata: PUT_CLOSE_UPDATA
    }),
    async submit() {
      this.loading= true;
      let payload = {
        okigas_application:{}
      };
      // 新規申し込みなのか、更新の申し込みなのかで、作成するデータの処理を分岐する
      payload.okigas_application.status = this.OutputFormData.Status
      payload.okigas_application.application_id = crypto.randomUUID();
      payload.okigas_application.application_type = "close_faucet"
      //1. お申し込み者情報
      payload.okigas_application.applicant_last_name = this.OutputFormData.ApplicantSurname
      payload.okigas_application.applicant_first_name = this.OutputFormData.ApplicantName
      payload.okigas_application.applicant_tel_number = this.OutputFormData.ApplicantPhoneNumber
      //2. ご利用場所ご契約情報
      payload.okigas_application.gas_kind = this.OutputFormData.RadioGasType
      payload.okigas_application.last_name = this.OutputFormData.ContractorSurname
      payload.okigas_application.first_name = this.OutputFormData.ContractorName
      payload.okigas_application.last_name_kana = this.OutputFormData.ContractorSurnameFuri
      payload.okigas_application.first_name_kana = this.OutputFormData.ContractorNameFuri
      payload.okigas_application.postal_code = this.OutputFormData.ContractorPostalCode
      payload.okigas_application.pref = this.OutputFormData.ContractorPrefectures
      payload.okigas_application.municipality = this.OutputFormData.ContractorMunicipality
      payload.okigas_application.address1 = this.OutputFormData.ContractorTownLetter
      payload.okigas_application.address2 = this.OutputFormData.ContractorBlock
      payload.okigas_application.building = this.OutputFormData.ContractorBuildingName
      payload.okigas_application.room_number = this.OutputFormData.ContractorRoomNumber
      payload.okigas_application.tel_number = this.OutputFormData.ContractorPhoneNumber
      payload.okigas_application.mail = this.OutputFormData.ContractorEmail
      //3. ガスのご利用停止希望⽇時について
      payload.okigas_application.gas_last_use_date = this.OutputFormData.GasLastUseDate
      //4. ご利用場所のガス契約情報
      payload.okigas_application.charge_number = this.OutputFormData.GasChargeNumber
      payload.okigas_application.meter_reading_slip_image_info = {
        file_name : this.OutputFormData.MeterImageName ? this.OutputFormData.MeterImageName : '',
        data_uri : this.OutputFormData.MeterImageBase64 ? this.OutputFormData.MeterImageBase64 : ''
      }
      //5.引っ越し先の情報
      payload.okigas_application.moving_postal_code = this.OutputFormData.NextHomePostalCode
      payload.okigas_application.moving_pref = this.OutputFormData.NextHomePrefectures
      payload.okigas_application.moving_municipality = this.OutputFormData.NextHomeMunicipality
      payload.okigas_application.moving_address1 = this.OutputFormData.NextHomeTownLetter
      payload.okigas_application.moving_address2 = this.OutputFormData.NextHomeBlock
      payload.okigas_application.moving_building = this.OutputFormData.NextHomeBuildingName
      payload.okigas_application.moving_room_number = this.OutputFormData.NextHomeRoomNumber
      //6. 保証金預かりの返金について
      payload.okigas_application.deposit_number = this.OutputFormData.CustodyDepositNumber
      payload.okigas_application.deposit_image_info = {
        file_name : this.OutputFormData.DepositImageName ? this.OutputFormData.DepositImageName : '',
        data_uri : this.OutputFormData.DepositImageBase64 ? this.OutputFormData.DepositImageBase64 : ''
      }

      payload.okigas_application.account_holder = this.OutputFormData.AccountHolder
      payload.okigas_application.bank_type = this.OutputFormData.BankSelectionRadio
      payload.okigas_application.financial_name = this.OutputFormData.BankName
      payload.okigas_application.account_type = this.OutputFormData.AccountTypeRadio
      payload.okigas_application.branch_name = this.OutputFormData.BranchName
      payload.okigas_application.branch_office_number = this.OutputFormData.BranchOfficeNumber
      payload.okigas_application.bank_account_number = this.OutputFormData.BankAccountNumber
      payload.okigas_application.yucho_symbol = this.OutputFormData.PostBankSymbol
      payload.okigas_application.yucho_number = this.OutputFormData.PostBankNum
      //7. でんきのご利用の停止およびお引越し先のご利用について
      payload.okigas_application.is_used_okigas_electricity = this.OutputFormData.UseOkinawaGasElectricQuestion
      payload.okigas_application.is_suspend_electricity = this.OutputFormData.ElectricalSuspendQuestion
      payload.okigas_application.suspend_date = this.OutputFormData.UsingElectricalSuspendDate
      payload.okigas_application.is_continuous_use_electricity = this.OutputFormData.ContinuousUseQuestion
      payload.okigas_application.electricity_start_date = this.OutputFormData.ContinuousUseStartDate
      payload.okigas_application.last_gas_payment_type = this.OutputFormData.GasPaymentSelection
      payload.okigas_application.okigas_electricity_new_use = this.OutputFormData.NewApplicationQuestion
      payload.okigas_application.new_electricity_start_date = this.OutputFormData.NewUseStartDate
      payload.okigas_application.is_electricity_charge_notification = this.OutputFormData.AnnouncementAvailability
      //8.その他
      payload.okigas_application.agency_number = this.OutputFormData.ProxyStoreNumber
      payload.okigas_application.qr_parameter = this.OutputFormData.QRCodeParameter
      payload.okigas_application.updated_by = "application@user"

      payload.okigas_application.isBankDesignation = this.OutputFormData.isBankDesignation

      // ユーザー名を設定
      payload.user_name = "application@user";

      // 新規申し込みなのか、更新の申し込みなのかで、叩くＡＰＩを変える
      let closeSelectApi = this.getAllResponseData.okigas_application;
      console.log('閉栓の全レスポンス', this.closeSelectApi)

      if (closeSelectApi !== undefined) {
        //更新API呼び出し
        payload.okigas_application.application_id = closeSelectApi.application_id;
        console.log('closeUpdataApplication_id', payload.okigas_application.application_id)
        await this.putCloseUpdata({
          new: payload,
          old: this.getAllResponseData
        });

        // ローカルストレージに保存しているパラメータを削除
        localStorage.removeItem('qr');

        // 完了画面に遷移
        this.loading= false;
        this.$router.push('/SuspendCompleteScreen');
      } else {
        //新規追加API呼び出し
        payload.okigas_application.application_id = crypto.randomUUID();
        console.log('application_id',payload.okigas_application.application_id)
        const result = await this.postInputData(payload);
        if (result?.data?.status == 500) {
          this.loading = false;
          if (result?.data?.code === 'MaximumReservationError') {
            this.$dialog.show({
              title: "申込がいっぱいです。",
              text: "ご希望の時間帯は既に他の申し込みで埋まっているため申し込みいただけません。",
              type: "warning",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる",
            });
          } else {
            this.$dialog.show({
              title: "不明なエラーが発生しました。",
              text: "時間を置いても解決しない場合、お問い合わせください",
              type: "error",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる",
            });
          }
        } else {
          // ローカルストレージに保存しているパラメータを削除
          localStorage.removeItem('qr');

          // 完了画面に遷移
          this.loading = false;
          this.$router.push('/SuspendCompleteScreen');
        }
      }
    },
    backBtn() {
      this.$router.push('/SuspendAgreementMatter');
    },
  },
}
</script>

<style scoped>
@media screen and (max-width:600px) {
  .tytle{
    font-size: .7em;
  }
}
::v-deep .v-stepper__label {
  display: grid !important;
}
</style>