//ご利用停止内容確認画面のvuexをインポート
import state from "./SuspendContentConfirmationForm.state";
import mutations from "./SuspendContentConfirmationForm.mutations";
import actions from "./SuspendContentConfirmationForm.actions";

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};