<template>
  <SuspendAgreementMatterForm />
</template>
  
<script>
  import SuspendAgreementMatterForm from '../components/Home/SuspendAgreementMatterForm'
  export default {
    name: 'SuspendAgreementMatterView',

    components: {
      SuspendAgreementMatterForm,
    },
    created () {
      window.addEventListener("beforeunload", this.confirmReload);
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.confirmReload);
    },
    methods: {
      confirmReload (event) {
        event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
      },
    }
  }
</script>