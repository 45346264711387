<template>
  <!--画面Top-->
  <v-app>
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>

    <v-main  style="background-color:#FAFBFC">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card class="mt-3 mb-3" width="1000px">

              <!--ステップ表示-->
              <div class="step" >
                  <v-row class="mb-0">
                    <v-col cols="12">
                      <v-stepper alt-labels flat>
                        <v-stepper-header v-model="e1">
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0"
                              :complete="e1 > 1"
                              step="1">
                              <font size="4" color="#1478D2">STEP1</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" 
                              :complete="e1 > 2"
                              step="2"
                            >
                            <font size="4" color="#1478D2">STEP2</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" 
                              :complete="e1 == 3"
                              step="3">
                              <font size="4" color="#1478D2">STEP3</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" step="4">
                              <font size="4">STEP4</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" step="5">
                              <font size="4">STEP5</font>
                            </v-stepper-step>
                          </v-col>
                        </v-stepper-header>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </div>

                <!--申し込み-->
                <div class="AppText">
                  <v-row justify="center" class="center ma-5">
                    <v-col class="pt-0">
                      <v-sheet 
                        class="d-flex align-center justify-center"
                        color="#009DE6"
                        rounded="pill"
                        height="60"
                        max-width="500"
                      >
                      <h2><font color="#FFFFFF">ご利用開始のお申込み</font></h2>
                    </v-sheet>
                    </v-col>
                  </v-row>
                </div>


              <!--同意事項説明-->
              <div class="agreement">
                <v-row class="ms-1 me-1 my-1">
                  <v-col align="left">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                    <h3><font color="#009DE6">➅&ensp;同意事項</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row class="ms-2 me-1">
                  <v-col align="left">
                    <v-row>
                      （１）共通事項
                    </v-row>
                    <v-row>
                      <v-col>
                        <p>・現在、暴力団員、暴力団準構成員、総会屋等の反社会的勢力には該当せず、将来にわたっても該当しません。</p>
                        <p>・申し込に際し、次の「不利益説明事項」を読み、内容に同意したうえで申し込みをします。</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      〈不利益説明事項〉
                    </v-row>
                    <v-row>
                      <v-col>
                        <p>現在の小売契約解約時に下記に記載される事項が発生する可能性があります。</p>
                        <p>・契約期間中の解約に伴う違約金の発生（複数年契約などの場合）</p>
                        <p>・各社発行ポイントの失効</p>
                        <p>・継続利用割引に適用される継続利用期間の終了</p>
                        <p>・過去の電力使用量及びガス使用量に関する各小売事業者への照会不可</p>
                      </v-col>
                    </v-row>

                    <div v-if ="agreementInput.appradios == 1">
                      <v-row class="pb-0">
                        （２）「沖縄ガスのでんき」のお申し込みに関する同意事項
                      </v-row>
                      <v-row>
                        <v-col>
                          <p>・<a :href="this.indicate.serviceGuide" target="_blank" rel="noopener noreferrer">サービス内容説明書</a>に同意したうえで申し込みをします。</p>
                          <p>・<a :href="this.indicate.supplyClause" target="_blank" rel="noopener noreferrer">供給約款</a>に同意したうえで申し込みをします。</p>
                          <P>・申し込に際し、<a :href="this.indicate.personalInformationHandling" target="_blank" rel="noopener noreferrer">個人情報の取扱いについて</a>を読み、内容に同意したうえで申し込みをします。</P>
                          <p>・<a :href="this.indicate.consignmentClauseProvision" target="_blank" rel="noopener noreferrer">託送約款規定における需要者に関する事項</a>を遵守します。</p>
                          <p>・ 他社がガス供給している建物から「沖縄ガスのでんき」のご利用のお申し込みをされた場合、お申込みキャンセルのご意向を確認できない場合は、手続きを進めさせていただくことを予めご了承ください。</p>
                        </v-col>
                      </v-row>

                      <div v-if="agreementInput.setradios == 1">
                        <v-row>
                          （３）「でんきとガスのセット割」に関する同意事項
                        </v-row>
                        <v-row>
                          <v-col>
                            <p>・<a :href="this.indicate.setdiscount" target="_blank" rel="noopener noreferrer">セット割適用の重要説明事項</a>の内容を用意したうえで申し込みをします。</p>
                          </v-col>
                        </v-row>
                      </div>
                    </div>

                  </v-col>
                </v-row>
              </div>

              <!--同意確認-->
              <v-form 
                ref="form"
                v-model="valid"
                >
                <div class="agreementconf">
                  <v-row class="ms-2 me-2">
                    <v-col align="left">
                      <!-- <v-row class="ps-1 mt-10">
                        <p>上記の内容を確認し、同意する場合は「同意する」をチェックを入れてください。</p>
                      </v-row> -->
                      <v-radio-group
                        class="mt-5"
                        v-model="agreementcheck"
                        :rules="[rules.agree,rules.notagree]"
                        row
                        required
                        > 
                          <v-row class="ps-5 pb-5" justify="center">
                            <!-- <v-col cols="12" md="3" class="pb-0"> -->
                              <v-radio label="上記の内容を確認し、同意しました。" value="yes"></v-radio>
                            <!-- </v-col> -->
                            <!-- <v-col cols="12" md="3" class="pb-0">
                              <v-radio label="同意しない" value="no"></v-radio>
                            </v-col> -->
                          </v-row>
                      </v-radio-group> 
                      <!--<v-row class="mb-5" justify="center">
                        <v-col cols="12" md="6" class="ms-10 pt-0">
                          ※「同意する」を選択してください
                        </v-col>
                      </v-row>-->
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="4" md="5" class="mb-3" align="right">
                      <v-btn 
                        color="gray"
                        width="40%"
                        @click="back"
                      >
                        戻る
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="5" class="mb-3" align="left">
                      <v-btn
                        class="white--text"
                        :disabled="!valid ||agreementcheck == 'no'"
                        variant="outlined"
                        color="#009DE6"
                        width="40%"
                        @click="validate"
                      >
                        次へ
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    
    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>

<script>
import {
  mapState,
  mapActions,
} from "vuex";
import {
  GET_LINK_SETTING
} from "@/store/action-types";
import FooterView from "@/components/common/GlobalFooter/index.vue"
export default {
  name:'AppStartAgreement',
  data: () => ({
    agreementcheck:"",
    valid:true,
    rules:{
      agree: value => value == "yes" || value == "no" ||'「同意する」を選択してください',
      notagree: value =>  value == "yes" || '「同意する」を選択してください'
    },
    e1:3,
    indicate:{
      serviceGuide:"",
      supplyClause:"",
      personalInformationHandling:"",
      consignmentClauseProvision:"",
      setdiscount:"",
    }
    
}),
  components:{
    FooterView,
  },
methods: {
  ...mapActions({
    getLinkSetting: GET_LINK_SETTING,
  }),
  validate() {
        return this.$refs.form.validate() == false ? "":this.$router.push('/ConfAppStartUse')
      },

  back(){
  return this.$router.push('/appstart');
  },
  
  async displayLinkSetting(){
    await this.getLinkSetting()
    const linkSetting = this.setResponseLink.okigas_link_setting;
    this.indicate.serviceGuide = linkSetting.service_guide_url ? linkSetting.service_guide_url : undefined;
    this.indicate.supplyClause = linkSetting.supply_clause_url ? linkSetting.supply_clause_url : undefined;
    this.indicate.personalInformationHandling = linkSetting.personal_information_handling_url ? linkSetting.personal_information_handling_url : undefined;
    this.indicate.consignmentClauseProvision = linkSetting.consignment_clause_provision_url ? linkSetting.consignment_clause_provision_url : undefined;
    this.indicate.setdiscount = linkSetting.set_discount_important_matter_url ? linkSetting.set_discount_important_matter_url : undefined;
  }
},

computed:{
  ...mapState({
    agreementInput: state => state.AppStartAgreementForm.agreementInput,
    setResponseLink: state => state.AppStartAgreementForm.setResponseLink,
  })
},
mounted(){
  //リンク取得
  this.displayLinkSetting()
},
  
}
</script>
<style scoped>
@media screen and (max-width:600px){
    .AppText h2{
      font-size: 1em;
    }
    h2{
      font-size: .9em;
    }
}
</style>
<style scoped>
::v-deep .v-stepper__label{
  display: grid !important;
}

</style>