import Vue from 'vue'
import {
  GET_LINK_SETTING_CLOSE,
} from "@/store/action-types";
import {
SET_LINK_CLOSE_DISPLAY,
} from "@/store/mutation-types";

export default {
[GET_LINK_SETTING_CLOSE]: async ({commit}) =>{
  try{
    const response = await Vue.prototype.$okigasAxios
      .get(
        "/api/okigas_link_setting",{}
      );
    
    //取得した値をstateに保持する
    console.log("response.data", response.data)
    commit(SET_LINK_CLOSE_DISPLAY,response.data);
    
  }catch (err){return err}
}

}