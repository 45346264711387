<template>
  <v-app>
    <!-- ヘッダー -->
    <v-app-bar color="white" elevation="1">
      <v-img
        class="changeSize shrink mr-2"
        :src="require('../../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>
    <v-main style="background-color:#FAFBFC">
      <v-container>
        <v-row> 
          <v-col align="center"> 
            <v-card 
              max-width="1000px" 
              class="mt-3 mb-3"
            >
              <!--ステップ表示-->
              <div class="step">
                <v-row class="mb-10">
                  <v-col cols="12">
                    <v-stepper alt-labels flat>
                      <v-stepper-header v-model="e1">
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" 
                            :complete="e1 == 1"
                            step="1">
                            <font size="4" style="display: grid;" color="#1478D2">STEP1</font>
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="2">
                            STEP2
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="3">
                            STEP3
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="4">
                            STEP4
                          </v-stepper-step>
                        </v-col>
                        <v-col cols="2">
                          <v-stepper-step class="pr-0 pl-0" step="5">
                            STEP5
                          </v-stepper-step>
                        </v-col>
                      </v-stepper-header>
                    </v-stepper>
                  </v-col>
                </v-row>
              </div>
              <!-- タイトル -->
              <div class="tytle">
                <v-row justify="center" class="ma-5">
                  <v-col class="pt-0">
                    <v-sheet
                      class="d-flex align-center justify-center"
                      color="#009DE6"
                      rounded="pill"
                      height="60"
                      max-width="630"
                    >
                      <h2 class="ms-1 me-1"><font color="#FFFFFF">「ご利用開始およびご利用停止」のお申し込みフォーム</font></h2>
                    </v-sheet>
                  </v-col>
                </v-row>
              </div>
              <!-- スライド画像 -->
              <v-carousel
                class="carouselStyle"
                cycle
                interval="6000"
                show-arrows-on-hover
                hide-delimiters
                width="auto"
              >
                <v-carousel-item
                  v-for="(url, key) in this.slideImages"
                  :key="key"
                  :src="url"
                  contain
                ></v-carousel-item>
              </v-carousel>
              <!-- ⓵.お手続きの前にご確認ください。 -->
              <div class="ConfirmationItem">
                <v-row class="mt-5 ms-1">
                  <v-col align="left" class="pb-0">
                    <v-alert
                      class="d-flex align-center"
                      border="left"
                      colored-border
                      color="#009DE6"
                      height="20"
                      tile
                    >
                      <h3><font color="#009DE6">➀&ensp;お手続き前にご確認ください。</font></h3>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （１）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    ガスのご利用開始時に室内の設備点検を伴うため、お立会いが必要です。 
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （２）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    迷惑メール防止のため受信設定をされている方は、弊社ドメイン『@okinawagas.co.jp』のメールを受信できるようお願いいたします。  
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （３）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    予定日直前（５営業日以内）のキャンセルや変更は、下記のお電話にて承ります。 
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （４）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    ガス給湯器の動作確認のため、ガスのご利用開始前までに、電気と水道が使えるよう、ご準備願います。 動作確認ができない場合、ガスのご利用開始をお断りさせていただくことがあります。 
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （５）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    申込内容の確認や、訪問前の在宅確認のため、担当者より電話連絡をさせていただきます。 連絡が取れない場合、ご訪問を保留とさせていただくことがあります。 
                  </v-col>
                </v-row>
                <v-row class="me-2">
                  <v-col cols="2" md="1" align="right" class="ps-0 pe-0">
                    （６）
                  </v-col>
                  <v-col cols="10" md="11" align="left" class="ps-0">
                    ご入力いただくお名前、ご住所、お電話番号等の個人情報は、<a href="https://www.okinawagas.co.jp/privacypolicy.jsp " target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に則って利用させていただきます。※当社のお客さま情報の利用目的については、プライバシーポリシーをご覧ください。
                  </v-col>
                </v-row>
              </div>
              <v-form 
                ref="validationCheck"
              >
                <!-- ➁お申し込み手続き内容を選択してください。 -->
                <div class="AppSelect">
                  <v-row class="mt-5 ms-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➁&ensp;お申し込み手続き内容を選択してください。</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-radio-group 
                    v-model="radioSelection"
                    :rules="[rules.RadioSelectionCheck]"
                    class="pl-7"
                  >
                    <v-row class="">
                      <v-col class="pb-0" cols="12" align="left">
                        <v-radio 
                          value="ガスご利用を開始のお申し込み"
                        >
                          <template v-slot:label>
                            <span style="color:rgba(0,0,0,0.87)">ガスご利用を開始のお申し込み</span>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col cols="12" align="left" class="pt-0">
                        <font color="red">ガスのご利用開始には立ち会いが必要となります。</font>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col class="pb-0" cols="12" align="left">
                        <v-radio 
                          value="お引越しによるガスご利用を停止のお申し込み"
                        >
                          <template v-slot:label>
                            <span style="color:rgba(0,0,0,.87)">お引越しによるガスご利用を停止のお申し込み</span>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                    <v-row class="">
                      <v-col cols="12" align="left" class="pt-0">
                        <font color="red">ガスのご利用停止はオートロックなどの場合、立ち会いが必要です。</font>
                      </v-col>
                    </v-row>
                    <div v-if="radioSelection === 'お引越しによるガスご利用を停止のお申し込み'">
                      <v-row class="ms-8 me-2">
                        <v-col cols="12" align="left">
                          検針票および保証金預かり証の準備について
                        </v-col>
                      </v-row>
                      <v-row class="ms-10 me-2">
                        <v-col cols="12" align="left" class="pb-0">
                          ①ご利用場所のガス検針票&emsp;※必須ではありません。
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="6" align="center" class="mb-3">
                          <v-img
                            class="mt-5" 
                            src="../../../image/ガス検針票.png" 
                            height="auto"
                            width="auto"
                            style="border:3px #000000 solid;"
                          ></v-img>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                      </v-row>
                      <v-row class="ms-10 me-2">
                        <v-col cols="12" align="left" class="pb-0">
                          ②LPガス保証金預かり証&emsp;※必須ではありません。
                        </v-col>
                      </v-row>
                      <v-row class="ms-12 me-2">
                        <v-col cols="12" align="left">
                          LPガスで供給している賃貸住宅の場合等、ご入居の際に保証金として、10,000円を当社へお預けになった方のみ。
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="7" align="center" class="mb-3">
                          <v-img 
                            class="mt-5"
                            src="../../../image/保証金預かり証.jpg" 
                            aria-label="保証金預かり証"
                            height="auto"
                            width="auto"
                            style="border:3px #000000 solid;"
                          ></v-img>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                      </v-row>
                    </div>
                    <v-row class="">
                        <v-col cols="12" align="left">
                          <v-radio 
                            value="お申し込みいただいた内容を変更する場合"
                          >
                            <template v-slot:label>
                              <span style="color:rgba(0,0,0,0.87)">お申し込みいただいた内容を変更する場合</span>
                            </template>
                          </v-radio>
                        </v-col>
                      </v-row>
                  </v-radio-group>
                  <div v-if="radioSelection === 'お申し込みいただいた内容を変更する場合'">
                    <v-row class="ms-10 me-2">
                      <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                        受付番号  (ハイフンあり)&ensp;<font color="red">⁕</font>
                      </v-col>
                      <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                        <v-text-field
                          type="text"
                          v-model="topInput.ReceptionNumber"
                          :rules="[
                            rules.ReceptionRequiredCheck,
                            rules.ReceptionKeishikiCheck
                          ]"
                          placeholder="000000-00000"
                          outlined
                          clearable
                          dense    
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ms-10 me-2">
                      <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                        お申し込み時の電話番号  (ハイフン無し)&ensp;<font color="red">⁕</font>
                      </v-col>
                      <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                        <v-text-field 
                          type="text"
                          v-model="topInput.PhoneNumber"
                          placeholder="0988637730"
                          :rules="[rules.PhoneRequiredCheck, rules.NumLengthCheck]"
                          outlined
                          clearable
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ms-10 me-2 mb-5">
                      <v-col align="left">
                        <p>受付希望日時の変更ができない場合、大変恐れ入りますが、以下の問い合わせ窓口までご連絡ください。</p>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-row> 
                  <v-col align="center">
                    <v-btn
                      color="#009DE6" 
                      @click="submit"
                      width="20%"
                    >
                      <font color="white">次へ</font>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>	
      </v-container>
    </v-main>    
    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView />
  </v-app>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions
} from "vuex";
import {
  SET_TOP_INPUT, 
  INITIALIZE_STATE,
  SET_URL_PARAMS,
  SET_RESPONSE_DATA
} from "@/store/mutation-types";
import {
  GET_SEARCH_SCREEN,
  GET_LINK_AD_IMAGES,
  GET_OPEN_ACCEPTABLE_RANGE
} from "@/store/action-types";
import FooterView from "@/components/common/GlobalFooter/index.vue";
import moment from 'moment';
export default {
  data(){
    return {
      radioSelection: "",
      topInput:{
        ReceptionNumber: "",
        PhoneNumber: "",
        urlParams: {},
      },
      e1: 1,
      slideImages: {
        slide0: `${process.env.VUE_APP_LINK_IMAGE_BACKET_ENDPOINT}/advertisement-list/slide0.png`,
        slide1: `${process.env.VUE_APP_LINK_IMAGE_BACKET_ENDPOINT}/advertisement-list/slide1.png`,
        slide2: `${process.env.VUE_APP_LINK_IMAGE_BACKET_ENDPOINT}/advertisement-list/slide2.png`,
        slide3: `${process.env.VUE_APP_LINK_IMAGE_BACKET_ENDPOINT}/advertisement-list/slide3.png`,
        slide4: `${process.env.VUE_APP_LINK_IMAGE_BACKET_ENDPOINT}/advertisement-list/slide4.png`
      },

      //バリデーションチェック
      rules:{
        RadioSelectionCheck: value => (value === "ガスご利用を開始のお申し込み" || value === "お引越しによるガスご利用を停止のお申し込み" || value === 'お申し込みいただいた内容を変更する場合') || '',
        ReceptionRequiredCheck: value => !!value || "受付番号を入力してください",
        ReceptionKeishikiCheck : value => ((/^\d{6}-\d{5}$/.test(value)) && (/^(10|20)/.test(value))) || '受付番号が正しくありません',
        //ReceptionNumCheck : value => (/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/.test(value)) || '',
        PhoneRequiredCheck: value => !!value || "電話番号を入力してください",
        NumLengthCheck: value => value == null ||  10 <= value.length && value.length <= 11 || '(市外局番込み)10桁以上11桁以下で入力してください。',
      }
    }
  },
  components:{
    FooterView,
  },
  computed: {
    ...mapState({  
      getAllResponseData: state => state.topApplicationForm.getAllResponseData
    }),
  },
  methods: { 
    ...mapMutations({
      setTopInput: SET_TOP_INPUT,
      initializeState: INITIALIZE_STATE,
      setUrlParams: SET_URL_PARAMS,
      setResponseData: SET_RESPONSE_DATA
    }),
    ...mapActions({
      getSearchScreen: GET_SEARCH_SCREEN,
      getLinkAdImages: GET_LINK_AD_IMAGES,
      getAcceptableRange: GET_OPEN_ACCEPTABLE_RANGE
    }),
    checkImageURL(url, callback) {
      const img = new Image();
      img.onload = function() {
        callback(true); // 画像が正常に読み込まれた場合
      };
      img.onerror = function() {
        callback(false); // 画像が読み込めなかった場合
      };
      img.src = url;
    },
    async submit() {
      this.$refs.validationCheck.validate(false);
      if (this.radioSelection === "ガスご利用を開始のお申し込み") {
        // 開栓申込
        if (this.$refs.validationCheck.validate() === false) {
          return;
        } else {
          // パラメータセット
          this.setUrlParams(this.topInput.urlParams);

          // 開栓申し込み画面に進む
          this.$router.push('/appstart');
        }
      } else if (this.radioSelection === "お引越しによるガスご利用を停止のお申し込み") {
        // 閉栓申込
        if (this.$refs.validationCheck.validate() === false) {
          return;
        } else {
          // パラメータセット
          this.setUrlParams(this.topInput.urlParams);

          // 閉栓申し込み画面に進む
          this.$router.push('/SuspendApplication');
        }
      } else {
        // 申込変更
        if (!this.topInput.ReceptionNumber || !this.topInput.PhoneNumber) {
          // 受付番号、電話番号のいずれかが入力されていない
          return;

        } else {
          // パラメータセット
          this.setUrlParams(this.topInput.urlParams);

          // 受付番号、電話番号によって、申込情報を取得
          this.setTopInput(this.topInput);
          await this.getSearchScreen();
          const response = this.getAllResponseData;
          console.log('TopForm', response)
          if (!response || !response.okigas_application) {
            // 受付番号と電話番号が一致するお申し込みがない場合
            this.setResponseData(""); // 取得したレスポンスデータをリセットする
            this.$dialog.show({
              title: "申込情報が見つかりませんでした。",
              text: "受付番号と電話番号をお確かめ下さい。",
              type: "warning",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる"
            });
          } else if (response.okigas_application.deleted_at !== "") {
            // お申し込みが削除済みの場合
            this.setResponseData(""); // 取得したレスポンスデータをリセットする
            this.$dialog.show({
              title: "申込情報が見つかりませんでした。",
              text: "受付番号と電話番号をお確かめ下さい。",
              type: "warning",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる"
            });
          } else if (response.okigas_application.status === 'cancel' || response.okigas_application.status === 'cancel_downloaded') {
            // お申し込みのステータスがキャンセルの場合
            this.setResponseData(""); // 取得したレスポンスデータをリセットする
            this.$dialog.show({
              title: "",
              text: "WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。お手数ですが次のお問い合わせ窓口までご連絡をください。\n【お問い合わせ窓口】\n沖縄ガス株式会社\n電話番号：平日　098-863-7438　\n土曜・日曜・祝祭日　098-863-7730\n受付時間：9:00～17:00",
              type: "warning",
              hideBtnConfirm: true,
              btnCancelTitle: "閉じる"
            });
          } else {
            // 受付可能範囲を取得
            const applicationType = response.okigas_application.application_type;
            const resGetAcceptableRange = await this.getAcceptableRange(applicationType);
            const accRngSet = resGetAcceptableRange?.okigas_acceptable_range_setting;
            const dayCount = accRngSet?.acceptable_range_day_count;
            const monthCount = accRngSet?.acceptable_range_month_count;
            const partialSetting = accRngSet?.acceptable_range_partial_setting;

            // 受付可能範囲を計算
            const today = moment().format('YYYY-MM-DD');
            let accRngStartDate = moment();
            let accRngEndDate = moment();
            if (accRngSet) {
              const todayPartSet = (partialSetting) ? partialSetting.find((obj) => obj.date === today) : null;
              accRngStartDate = (todayPartSet) ? moment(today).add((todayPartSet.day_count + 1), 'd') : moment(today).add(dayCount, 'd');
              accRngEndDate = moment(accRngStartDate).add(monthCount, 'M');
            }
            console.log('accRng: ', accRngStartDate.format('YYYY-MM-DD'), '~', accRngEndDate.format('YYYY-MM-DD'))

            // ガスのご利用希望日を取得(開栓ならガスのご利用希望日、閉栓ならガスの最終利用日)
            const preferredDate = (applicationType === 'open_faucet')
              ? response.okigas_application.preferred_date
              : (applicationType === 'close_faucet')
                ? response.okigas_application.gas_last_use_date
                : null;
            console.log('preferredDate: ', preferredDate)

            if (preferredDate && !moment(preferredDate).isBetween(accRngStartDate, accRngEndDate, null, '[]')) {
              // お申し込み受付範囲外の場合
              this.setResponseData(""); // 取得したレスポンスデータをリセットする
              this.$dialog.show({
                title: "",
                text: "WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。お手数ですが次のお問い合わせ窓口までご連絡をください。\n【お問い合わせ窓口】\n沖縄ガス株式会社\n電話番号：平日　098-863-7438　\n土曜・日曜・祝祭日　098-863-7730\n受付時間：9:00～17:00",
                type: "warning",
                hideBtnConfirm: true,
                btnCancelTitle: "閉じる"
              });
            } else {
              // 検索成功の場合
              // 開栓 or 閉栓を判定し画面遷移させる
              const receptionNo = response.okigas_application.reception_no;
              const isOpenFaucet = (receptionNo.substring(0,2) === '10');
              const isCloseFaucet = (receptionNo.substring(0,2) === '20');
              if (isOpenFaucet) {
                return this.$router.push('/appstart');
              } else if (isCloseFaucet) {
                return this.$router.push('/SuspendApplication');
              }
            }
          }
        }
      }
    }
  },
  async created() {
    //stateの値を初期化する
    this.initializeState();

    // 広告画像から未設定の要素を削除
    for (const key in this.slideImages) {
      const val = this.slideImages[key];
      await this.checkImageURL(val, (check) => {
        if (!check) {
          delete this.slideImages[key];
          this.$forceUpdate();
        }
      });
    }

    // 代理店番号とQRパラメータ取得
    const urlsearchParams = new URLSearchParams(window.location.search);
    console.log('urlsearchParams', urlsearchParams)
    let agency_number = urlsearchParams.get('agency_number');
    let qr_parameter = urlsearchParams.get('qr');
    if (qr_parameter !== null) {
      // パラメータが取得できた場合、ローカルストレージに保存
      localStorage.setItem('qr', qr_parameter);
    } else {
      // パラメータが取得できなかった場合、ローカルストレージを参照
      qr_parameter = localStorage.getItem('qr');
    }
    this.topInput.urlParams.agency_number = agency_number;
    this.topInput.urlParams.qr_parameter = qr_parameter;
    console.log('urlParams', this.topInput.urlParams)
  },
  mounted() {
  }
}
</script>

<style scoped>
@media screen and (max-width:600px) {
  .tytle{
    font-size: .7em;
  }
  .footer{
    font-size: .8em;
  }
}
@media screen and (max-width:600px) {  
  ::v-deep .v-window.carouselStyle.v-item-group.theme--dark.v-window--show-arrows-on-hover.v-carousel {
    height: 250px !important;
  }
  ::v-deep .v-image__image.v-image__image--contain {
    height: 50% !important;
  }
}
::v-deep .v-stepper__label {
  display: grid !important;
}
</style>
