<template>
  <!-- 画面Top -->
  <v-app>
    <v-app-bar color="white" elevation="1">
      <v-img
        class="shrink mr-2"
        :src="require('../../assets/okinawagasulogo-image.png')"
        width="190"
      />
    </v-app-bar>

    <v-main style="background-color: #FAFBFC;">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-card class="mt-3 mb-3" max-width="1000px">
              <v-form
                ref="formvalidate"
                v-model="input.valid"
                lazy-validation
              >

                <!-- ステップ表示 -->
                <div class="step">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-stepper alt-labels flat>
                        <v-stepper-header v-model="e1">
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" :complete="e1 > 1" step="1">
                              <font size="4" color="#1478D2">STEP1</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" :complete="e1 == 2" step="2">
                              <font size="4" color="#1478D2">STEP2</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" step="3">
                              <font size="4">STEP3</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" step="4">
                              <font size="4">STEP4</font>
                            </v-stepper-step>
                          </v-col>
                          <v-col cols="2">
                            <v-stepper-step class="pr-0 pl-0" step="5">
                              <font size="4">STEP5</font>
                            </v-stepper-step>
                          </v-col>
                        </v-stepper-header>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </div>

                <!-- 画面Top -->
                <div class="TopText">
                  <v-row justify="center" class="center ma-5">
                    <v-col cols="12" class="pt-0">
                      <v-sheet 
                        class="d-flex align-center justify-center"
                        color="#009DE6"
                        rounded="pill"
                        height="60"
                        max-width="500"
                      >
                        <h2><font color="#FFFFFF">ご利用開始のお申込み</font></h2>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row v-if="isReceptionNo" class="ms-3 me-1">
                    <v-col cols="12" md="2" align="left" class="pt-0 pb-0">
                      <p>受付番号</p>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <p>{{ isReceptionNo }}</p>
                    </v-col>
                  </v-row>
                  <div v-if ="displayCancelBtn">
                    <v-row class="ms-1 me-1 mb-3">
                      <v-col cols="12" md="5" align="left">
                        <v-btn color="error" :disabled="disabledElectricity" @click="cancel">
                          <font color="white">お申し込みをキャンセルする</font>
                        </v-btn>
                      </v-col>
                      <v-col v-if="!disabledElectricity" class="pt-0" cols="12" align="left">
                        <font color="red">
                          キャンセルのお申し込みを行いますと、「沖縄ガスのでんき」に関するお申し込みも<br>
                          キャンセルとなりますので、予めご了承ください。
                        </font>
                      </v-col>
                      <v-col v-else class="pt-0" cols="12" align="left">
                        <font color="#CD0001">
                          WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。<br>
                          お問い合わせ窓口までご連絡をください。
                        </font>
                      </v-col>
                    </v-row>
                    <v-overlay 
                      :value="loading"
                      class="centered"
                      color="rgba(204,204,204,0.6)"
                    >
                      <v-progress-circular
                        indeterminate
                        size="40"
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>
                  <v-row class="ms-1 me-1 my-1">
                    下記の項目を入力の上、送信してください。
                  </v-row>
                </div>

                <!-- 申込者情報 -->
                <div calss="appInfo">
                  <v-row class="ms-1 me-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➀&ensp;お申し込み者情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pt-0 pb-0 mt-2">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;お申し込み者
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" align="left" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.firstname"
                        :rules="[rules.lastName,rules.lastNamedigitLimit,rules.lastNamecheck]"
                        clearable
                        outlined
                        dense
                        placeholder="沖縄"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.lastname"
                        :rules="[rules.firstName,rules.firstdigitLimit,rules.firstNamecheck]"
                        clearable
                        outlined
                        dense
                        placeholder="次郎"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" align="left" class="pt-0 pb-0 mt-2">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;お申し込み者電話番号<br>
                        <!-- 余白用 --><span style="min-width:40px; color:transparent; user-select: none;">あああ</span>
                        (ハイフン無し)
                      </p>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.tel"
                        value="tnum"
                        :rules="[rules.tel,rules.teldigitLimit,rules.telnumcheck]"
                        clearable
                        outlined
                        dense
                        placeholder="0988637730"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1">
                    <v-col cols="12" align="left" class="pt-0">
                      ※&emsp;内容の確認や訪問前の在宅確認のため、担当者より電話連絡をさせていただきます。
                    </v-col>
                  </v-row>
                </div>

                <!-- 申し込み場所情報 -->
                <div class="appPlace">
                  <v-row class="ms-1">
                    <v-col align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➁&ensp;ご利用申し込み場所の情報</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-radio-group
                    v-model="input.kindsradios"
                    :rules="[rules.kinds]"
                    row
                  >
                    <v-row class="ms-2 me-1">
                      <v-col cols="12" md="4" align="left" class="pt-0" >
                        <p style="color:black">
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px;"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          &ensp;ご利用場所のガス種
                        </p>
                      </v-col>
                      <v-col cols="10" md="2" class="pt-0">
                        <v-radio label="都市ガス" :value="'city_gas'"></v-radio>
                      </v-col>
                      <v-col cols="10" md="3" class="pt-0 pe-0">
                        <v-radio label="プロパンガス" :value="'propane_gas'"></v-radio>
                      </v-col>
                      <v-col cols="10" md="2" class="pt-0">
                        <v-radio label="不明" :value="'unknown'"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <v-radio-group
                    v-model="input.ageradios"
                    :rules="[rules.radios]"
                    row
                  >
                    <v-row class="ms-2 me-1 mb-1">
                      <v-col cols="12" md="4" align="left" class="pt-0 pb-0">
                        <p style="color:black">
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          &ensp;築3ヵ月未満の建物ですか？
                        </p>
                      </v-col>
                      <v-col cols="12" md="2" class="pt-0">
                        <v-radio label="はい" :value="1"></v-radio>
                      </v-col>
                      <v-col cols="12" md="3" class="pt-0">
                        <v-radio label="いいえ" :value="0"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <v-row class="ms-2 me-1">
                    <v-col cols="6" md="2" class="pb-0 mt-1" align="left">
                      <P>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;お名前
                      </p>
                    </v-col>
                    <v-col class="px-0 pb-0" cols="4" md="2" align="left">
                      <v-dialog
                        v-model="dialog"
                        width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            text
                            class="px-0 text-decoration-underline"
                          >
                            入力に困ったら
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-row class="mt-1">
                              <v-col cols="12">
                                例）姓:9文字、名:10文字以内で入力してください。
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="4" align="left" class="pb-0">
                                <v-row class="ms-1 me-1">
                                  <p>
                                    <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                                    &nbsp;お名前
                                  </p>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;沖縄ガス</font>
                                </v-sheet>
                              </v-col>
                              <v-col cols="8" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;株式会社</font>
                                </v-sheet>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="4" align="left" class="pb-0">
                                <v-row class="ms-1 me-1">
                                  <p>
                                    <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                                    &nbsp;フリガナ
                                  </p>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row class="mb-1">
                              <v-col cols="12" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;オキナワガス</font>
                                </v-sheet>
                              </v-col>
                              <v-col cols="8" md="3">
                                <v-sheet color="d-flex align-center " rounded="rounded" outlined height="40" width="190">
                                  <font>&emsp;カブシキガイシャ</font>
                                </v-sheet>
                              </v-col>
                            </v-row>
                            <font color="red">文字数が制限を超える場合は、先頭より入力できる範囲でご入力ください。</font><br>
                            <font color="red">※法人の種類（株式会社、有限会社など）は、文字数制限を超える場合、省いてください。</font>
                          </v-card-text>
                          <v-card-actions>
                            <v-row justify="center" class="mb-3">
                              <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                              >
                                閉じる
                              </v-btn>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="input.apfirstname"
                        :rules="[rules.appPlaceLastName,rules.appLastNamedigitLimit]"
                        clearable
                        outlined
                        dense
                        placeholder="沖縄"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="input.aplastname"
                        :rules="[rules.appPlaceFirstName,rules.appFirstdigitLimit]"
                        clearable
                        outlined
                        dense
                        placeholder="太郎"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;お名前（フリガナ）
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="input.readfirstname"
                        :rules="[rules.kanaAppPlaceLastName,rules.appKanalastNamedigitLimit,rules.appkanaLastName]"
                        clearable
                        outlined
                        dense
                        placeholder="オキナワ"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                      <v-text-field
                        v-model="input.readlastname"
                        :rules="[rules.kanaAppPlaceFirstName,rules.appKnafirstdigitLimit,rules.appkanaFirstName]"
                        clearable
                        outlined
                        dense
                        placeholder="タロウ"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;郵便番号<br>
                        <!-- 余白用 --><span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                        (ハイフン無し)
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.postcode"
                        :rules="[rules.postnum,rules.postNumcheck]"
                        @change="autofilladdress"
                        clearable
                        outlined
                        dense
                        placeholder="9008605"
                        :loading="YubinBangoLoading"
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;都道府県
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        type="text"
                        v-model="input.prefectures"
                        placeholder="沖縄県"
                        :rules="[rules.prefectures,rules.appPrefecturesdigitLimit,rules.prefecturescheck]"
                        outlined
                        clearable
                        dense
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;市町村
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.Municipality"
                        :rules="[rules.MunicipalityRules,rules.appMunicipalitydigitLimit,rules.Municipalitycheck]"
                        clearable
                        outlined
                        dense
                        placeholder="那覇市"
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;町字
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.town"
                        :rules="[rules.towns,rules.apptownsdigitLimit,rules.townscheck]"
                        clearable
                        outlined
                        dense
                        placeholder="⻄"
                        :disabled="YubinBangoLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;番地
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.straddress"
                        :rules="[rules.straddressRules,rules.appstraddressdigitLimit]"
                        clearable
                        outlined
                        dense
                        placeholder="3-13-2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>
                        &ensp;建物名
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.building"
                        :rules="[rules.building_length]"
                        clearable
                        outlined
                        dense
                        placeholder=""
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #BDBDBD; width:40px"><font style="color:white">&thinsp;任意&thinsp;</font></span>
                        &ensp;部屋番号
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.roomnum"
                        :rules="[rules.appRoomnumdigitLimit]"
                        clearable
                        outlined
                        dense
                        placeholder=""
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;電話番号<br>
                        <!-- 余白用 --><span style="min-width:40px; color:transparent;user-select: none;">あああ</span>
                        (ハイフン無し)
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.aptel"
                        :rules="[rules.appTel,rules.appTeldigitLimit,rules.apptelnumcheck]"
                        clearable
                        outlined
                        dense
                        placeholder="0988637730"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;メールアドレス
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.address"
                        :rules="[rules.addressRules,rules.addresscheck, rules.EmailLengthCheck]"
                        clearable
                        outlined
                        dense
                        placeholder="okinawatarou@okinawagas.co.jp"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col cols="12" md="4" class="pt-0 pb-0" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;メールアドレス（確認用）
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-text-field
                        v-model="input.confaddress"
                        :rules="[rules.confaddressRules,addressHogeCheck, rules.EmailLengthCheck]"
                        clearable
                        outlined
                        dense
                        placeholder="okinawatarou@okinawagas.co.jp"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <!-- 利用開始希望日時 -->
                <div class="desDateTime">
                  <v-row class="ms-1">
                    <v-col cols="12" align="left" class="pb-0">
                      <v-alert
                        class="d-flex align-center"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➂&ensp;ガスのご利用開始希望日時について</font></h3>
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1 pb-0">
                    <v-col cols="12" md="4" align="left" class="pt-0 pb-0"> 
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;希望⽇<br>
                        <span style="font-size: 11px;">※日付横の()内の数字はお申し込み可能な件数です。</span>
                      </p>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" md="4">
                      <v-menu
                        v-model="input.menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="350"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="input.startdate"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.startdateRules]"
                            outlined
                            clearable
                            dense
                            required
                            placeholder="ここをクリック"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="startDateOfUse"
                          v-model="dispStartDate"
                          @input="dispStartDateMethod"
                          no-title
                          locale="jp-ja"
                          :day-format="dayFormat"
                          :allowed-dates="isAllowedDates"
                          :min="this.dateMin"
                          :max="this.dateMax"
                          scrollable
                          required
                          width="350"
                          @change="resetTime"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mb-1 mt-0">
                    <v-col class="pt-0" align="left">
                      希望日が選択できない場合、下記のお問合わせ窓口まで、ご電話ください。
                    </v-col>
                  </v-row>

                  <v-row class="ms-2 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left">
                      <p>
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                        &ensp;希望時間
                      </p>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="4">
                      <v-select
                        v-model="input.time"
                        :rules="input.startdate === '' ? [] : [rules.timeRules]"
                        :items = "this.reservableTimes[input.startdate]"
                        outlined
                        clearable
                        dense
                        required
                        :disabled="input.startdate === ''"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="ms-2 me-1 mt-0">
                    <v-col class="pt-0" align="left">
                      ガスをご利用開始の当日は必ずお立合いが必要です<br>
                      作業時間は60分程度です。<br>
                    </v-col>
                  </v-row>

                  <div v-if="input.kindsradios != 'city_gas'">
                    <v-row  class="ms-2 me-1">
                      <v-col class="pb-0" align="left">
                        【注意】LPガス賃貸契約の場合、ご入居の際に保証金10,000円を現金にてご用意お願いいたします。 
                      </v-col>
                    </v-row>
                    <v-row class="ps-2 me-1">
                      <v-col cols="3" md="1" class="mt-2">
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                      </v-col>
                      <v-col class="ps-0 pt-0 pb-0 mb-3" cols="8"> 
                        <v-checkbox
                          v-model="input.checkbox"
                          :rules="[rules.check]"
                        >
                          <template v-slot:label>
                            承諾して申し込みます
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- 初回リリースは非表示 -->
                  <div v-if="ratePlanHidden">
                    <v-row class="ms-2 me-1">
                      <v-col class="pb-0" align="left">
                        ガス料金プランについて
                      </v-col>
                    </v-row>
                    <v-row class="ms-3 me-1">
                      <v-col align="left">
                        ご希望するガス料金プランを選択してください。&emsp;※詳しい料金プランは<a href="https://www.okinawagas.co.jp/" target="_blank" rel="noopener noreferrer">こちらから</a>
                      </v-col>
                    </v-row>
                    <v-row class="ms-3 me-1">
                      <v-col cols="12" md="4" align="left">
                        <v-select
                          v-model="input.ratePlanMenu"
                          :rules="[rules.ratePlanRules]"
                          :items="input.ratePlanitem"
                          outlined
                          clearable
                          dense
                          required
                          placeholder="ここをクリック"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <!-- でんき及びセット割の申し込み -->
                <div class="elecSetApp" :class="(disabledElectricity) ? 'disabledArea' : ''">
                  <v-row class="ms-1">
                    <v-col class="pb-0" align="left">
                      <v-alert
                        class="d-flex align-center"
                        :class="(disabledElectricity) ? 'disabledArea' : ''"
                        border="left"
                        colored-border
                        color="#009DE6"
                        height="20"
                        tile
                      >
                        <h3><font color="#009DE6">➃&ensp;でんき及びセット割のお申し込みについて</font></h3>
                      </v-alert>
                    </v-col>
                    <v-col v-if="disabledElectricity" cols="12" align="left" class="pt-0">
                      <font color="#CD0001">
                        WEBからのお申し込み内容の変更・キャンセルができる期間を過ぎました。<br>
                        お問い合わせ窓口までご連絡をください。
                      </font>
                    </v-col>
                  </v-row>

                  <v-radio-group
                    v-model="input.appradios"
                    :rules="[rules.appradiosrule]"
                    :disabled="disabledElectricity"
                    row
                  >
                    <v-row class="ms-2 me-1">
                      <v-col cols="12" md="5" align="left" class="pb-0">
                        <p>
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          （１）「沖縄ガスのでんき」のご利用について&ensp;
                        </p>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-radio label="申し込む" :value="1"></v-radio>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-radio label="申し込まない" :value="0"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>

                  <div v-if="input.appradios == 1">
                    <v-row class="ms-2 mt-1">
                      <!-- 余白用 --><v-col cols="0" sm="1" class="pa-0 ma-0"></v-col>
                      <v-col cols="12" sm="11" align="left" class="pb-0">
                        <p>
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          &ensp;<font size="4">➀</font>「沖縄ガスのでんき」の利用開始日
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <!-- 余白用 --><v-col class="pa-0 ma-0"  cols="0" sm="1"></v-col>
                      <v-col class="pt-0 pb-0" cols="12" sm="11">
                        <v-radio-group 
                          class="ms-2 me-2 mt-0 mb-0"
                          v-model="input.startradios"
                          :disabled="(input.appradios == 0) || disabledElectricity"
                          :rules="[setDiscountStart]"
                          row
                        >
                          <v-col cols="12" md="4">
                            <v-radio label="でんきとガスご利用開始⽇は同じ" :value="0" :change="electricityStratDate"></v-radio>
                          </v-col>
                          <v-col cols="12" md="4" class="radiosAppdate">
                            <v-radio class="mr-0" ref="electricityDate" label="でんきの利用開始⽇を指定する" :value="1"></v-radio>
                          </v-col>
                          <v-col cols="12" md="4" align="left" class="ml-0 pt-1">
                            <v-menu
                              v-model="input.menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="input.elstartdate"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="(input.appradios == 0 || input.startradios == '' || input.startradios == 0) ? [] : [setDiscountDate]"
                                  :disabled="(input.appradios == 0 || input.startradios == '' || input.startradios == 0) || disabledElectricity"
                                  clearable
                                  outlined
                                  dense 
                                  required
                                  hid-details="auto"
                                  placeholder="ここをクリック"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                class="electricSetDiscount"
                                v-model="dispElStartDate"
                                @input="dispElStartDateMethod"
                                no-title
                                locale="jp-ja"
                                :day-format="electricSetdayFormat"
                                :min="this.dateMin"
                                :max="this.dateMax"
                                scrollable
                                required
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <!-- 余白用 --><v-col cols="0" sm="1" class="pa-0 ma-0"></v-col>
                      <v-col cols="12" sm="11" align="left" class="ms-2 me-2 mt-0 mb-0">
                        <p><font color="#CD0001">新たに電気を使用する先が「オール電化住宅」の場合、料金が割高になる可能性がありますのでご注意ください。</font></p>
                        <p>なお、「沖縄ガスのでんき」が契約できる地域は、以下のとおりとなります。</p>
                        <p>・沖縄本島全域</p>
                        <p>・沖縄本島と電力系統が繋がっている離島</p>
                        <p>伊平屋島、野甫島、伊是名島、伊江島、水納島、津堅島、久高島、座間味島、渡嘉敷島</p>
                      </v-col>
                    </v-row>

                    <v-row class="ms-2 mt-1">
                      <!-- 余白用 --><v-col class="pa-0 ma-0" cols="0" sm="1"></v-col>
                      <v-col cols="12" sm="11" align="left" class="pb-0">
                        <p>
                          <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                          &ensp;<font size="4">➁</font>「でんき料金のお知らせ」の郵送の要・不要
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="ms-6 pt-0">
                      <!-- 余白用 --><v-col class="pa-0 ma-0" xs="0" cols="1"></v-col>
                      <v-col xs="12" cols="11" align="left" class="pa-0 ma-0">
                        <p>（「要」の場合、でんき料金に１１０円が加算されます。）</p>
                      </v-col>
                    </v-row>
                    <v-row class="ms-2 me-1 mt-0">
                      <!-- 余白用 --><v-col class="pa-0 ma-0" cols="0" sm="1"></v-col>
                      <v-col class="pt-0 pb-0" cols="12" sm="11">
                        <v-radio-group 
                          v-model="input.detailradios"
                          :disabled="(input.appradios == 0) || disabledElectricity"
                          :rules="[setDiscountdetail]"
                        >
                          <v-row>
                            <v-col cols="12" md="2" class="pt-0">
                              <v-radio label="要" :value="1"></v-radio>
                            </v-col>
                            <v-col cols="12" md="2" class="pt-0">
                              <v-radio label="不要" :value="0"></v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row class="ms-2 me-1">
                      <v-col class="pt-0 pb-0" cols="12">
                        <v-radio-group
                          v-model="input.setradios"
                          :disabled="(input.appradios == 0) || disabledElectricity"
                          :rules="[setDiscountApp]"
                        >
                          <v-row>
                            <v-col cols="12" md="5" align="left" class="pb-0">
                              <p>
                                <span style="border-radius: 5px; background-color: #CD0001; width:40px"><font style="color:white">&thinsp;必須&thinsp;</font></span>
                                &ensp;（２）でんきとガスのセット割を申し込む
                              </p>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-radio label="申し込む" :value="1"></v-radio>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-radio label="申し込まない" :value="0"></v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="ms-3 me-1 mb-2">
                      <v-col cols="12" align="left" class="pt-0">
                        「でんきとガスのセット割（でガス割）」を契約すると毎月のガス料金が50円(税込)割引となります。<br>
                        ※お客さまの契約料金内容によっては割引対象外とさせていただく場合がございます。<br>
                        ※セット割をお申し込みの場合、ガス料金のWEB料金明細サービスのご利用が必須となります。<br>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <!-- でんき料金とガス料金のお支払い方法 -->
                <div class="GasPayment">
                  <v-row class="ms-1">
                    <v-col class="pb-0" align="left">
                      <div>
                        <v-alert
                          class="d-flex align-center"
                          border="left"
                          colored-border
                          color="#009DE6"
                          height="20"
                          tile
                        >
                          <h3><font color="#009DE6">➄&ensp;でんき料金とガス料金のお支払い方法について</font></h3>
                        </v-alert>
                      </div>
                      <div class="ml-2">
                        電気料金とガス料金は別々での請求となります。<br>
                        なお、お支払いに関する手続き用紙は、ご利用場所に別途郵送いたします。
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="ms-3">
                    <v-col cols="12" align="left" class="pb-0">
                      <v-radio-group
                        v-model="input.web_itemized_charges"
                        :disabled="input.appradios === 1 && input.setradios === 1"
                        :rules="[rules.webItemizedChargesCheck(input.appradios, input.setradios,input.web_itemized_charges)]"
                      >
                        <v-row>
                          <v-col cols="12" md="5" align="left" class="pb-0">
                            <p style="color:black">
                              <span style="border-radius: 5px; background-color: #CD0001; width:40px" class="mr-1">
                                <font style="color:white">
                                  &thinsp;必須&thinsp;
                                </font>
                              </span>
                              ガス料金のWEB料金明細サービスの利用を申し込む&ensp;
                            </p>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-radio label="申し込む" :value="1"></v-radio>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-radio label="申し込まない" :value="0"></v-radio>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="ms-3 me-1">
                    <v-col cols="12" align="left" class="pb-0">
                      ※WEB料金明細サービスは、紙の「ご利用のお知らせ（検針票）」の代わりに、WEB上でガスのご利用状況を確認できるサービスを提供いたします。<br>
                      ※お支払い方法は、口座振替またはクレジットとなります。<br>
                    </v-col>
                  </v-row>

                  <v-row class="ms-3">
                    <v-col cols="12" md="5" align="left" class="pb-0">
                      <p style="color:black">
                        <span style="border-radius: 5px; background-color: #CD0001; width:40px" class="mr-1">
                          <font style="color:white">
                            &thinsp;必須&thinsp;
                          </font>
                        </span>
                        ガス料金のお支払い方法&ensp;
                      </p>
                    </v-col>
                    <v-radio-group
                      v-model="input.gasPayment"
                      :rules="[rules.gasPaymentCheck(input.appradios, input.setradios, input.web_itemized_charges, input.gasPayment)]"
                      row
                    >
                      <v-row class="ms-2 me-1">
                        <v-radio label="口座振替（沖縄銀行、琉球銀行、ゆうちょ銀行、沖縄県労金、コザ信金、JAおきなわ）" :value="1" class="mt-5"></v-radio>
                        <v-radio :value="2" class="mt-5">
                          <template slot="label">
                            <v-row>
                              &nbsp;&ensp;クレジットカード （ご利用できるカード： 
                              <v-img
                                class="shrink mr-2"
                                :src="require('../../assets/card_5brand.png')"
                                width="230"
                                height="25"
                                contain
                              />
                              ）
                            </v-row>
                          </template>
                        </v-radio>
                        <v-radio
                          v-if="((input.appradios !== 1 || input.setradios !== 1) && input.web_itemized_charges !== 1)"
                          label="払込票（発行手数料としてガス料金に220円を加算して請求いたします。）"
                          :value="3"
                          class="mt-5"
                        ></v-radio>
                      </v-row>
                    </v-radio-group>
                  </v-row>

                  <v-row class="ms-1" v-if="input.appradios === 1">
                    <v-col class="pb-0" align="left">
                      <div class="ml-2">
                        <v-row>
                          <v-col class="py-0">
                            <div>
                              ・でんき料金のお支払い方法
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="ml-2">
                          <v-col class="py-0">
                            <div>
                              ※でんき料金のお支払い方法は、口座振替またはクレジットカードとなります。
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="ml-2">
                          <v-col class="pt-0">
                            <v-row class="ma-0">
                              ※ご利用できるクレジットカード （ご利用できるカード：
                              <v-img
                                class="shrink mr-2"
                                :src="require('../../assets/card_5brand.png')"
                                width="230"
                                height="25"
                                contain
                              />
                              ）
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <!-- その他 -->
                <div class="Other">
                  <v-row class="ms-1">
                    <v-col class="pb-0" align="left">
                      <div>
                        <v-alert
                          class="d-flex align-center"
                          border="left"
                          colored-border
                          color="#009DE6"
                          height="20"
                          tile
                        >
                          <h3><font color="#009DE6">➅&ensp;その他（代理店記入）</font></h3>
                        </v-alert>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="ms-3 me-1">
                    <v-col class="pb-0" cols="12" md="4" align="left">
                      <p>
                        代理店番号
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                      <v-text-field
                        v-model="input.agencynum"
                        clearable
                        outlined
                        dense
                        :rules="[rules.dairitenBangoLength]"
                        placeholder=""
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-row v-show="qrCodePara" class="ms-3 me-1">
                    <v-col class="pt-0 pb-0" cols="12" md="4" align="left">
                      <p>
                        QRコードのパラメーター
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="input.qrpara"
                        :rules="[qrcodeValidate]"
                        clearable
                        outlined
                        dense
                        placeholder=""
                      ></v-text-field>
                    </v-col>
                  </v-row> -->

                  <v-row class="mt-5" justify="center">
                    <v-col cols="4" md="2" >
                      <v-btn 
                        color="gray"
                        width="100%"
                        @click="back"
                      >
                        戻る
                      </v-btn>
                    </v-col> 
                    <v-col cols="4" md="2">
                      <v-btn
                        variant="outlined"
                        class="white--text"
                        color="#009DE6"
                        textcolor="wthit"
                        width="100%"
                        @click="validate"
                      >
                        次へ
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- フッター -->
    <v-divider color="#009DE6"></v-divider>
    <FooterView/>
  </v-app>
</template>

<script>
  import FooterView from "@/components/common/GlobalFooter/index.vue";
  import { mapState, mapMutations, mapActions } from "vuex";
  import {
    SET_INPUT,
  } from "@/store/mutation-types";
  import {
    PUT_CANCEL_OPENAPPLICATION,
    GET_OPEN_RESERVATION_COUNT,
    GET_OPEN_ACCEPTABLE_RANGE,
  } from "@/store/action-types";
  import moment from "moment";
  import axios from "axios";
  export default {
    nama:'StartUse',
    components:{
      FooterView,
    },
    data: () => ({
      YubinBangoLoading: false,
      input:{
        menu: false,
        menu2: false,
        firstname: "",
        lastname: "",
        tel: "",
        apfirstname: "",
        aplastname: "",
        readfirstname: "",
        readlastname: "",
        prefectures: '',
        prefecturesname: ['福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県'],
        Municipality: "",
        town: "",
        straddress: "",
        building: "",
        roomnum: "",
        aptel: "",
        address: "",
        confaddress: "",
        web_itemized_charges:"",
        gasPayment:"",
        startdate: "",
        elstartdate: "",
        time: null,
        checkbox: false,
        postcode: "",
        agencynum:"",
        qrpara:"",
        appradios:"",
        kindsradios:"",
        ageradios:"",
        startradios:"",
        detailradios:"",
        setradios:"",
        valid: true,
        isPush: false,
        today: moment().format('YYYY-MM-DD'),
        ratePlanMenu:"",
        ratePlanitem:['一般料金','ゆーふるプラン','エコジョーズ料金'],
      },
      qrCodePara:false,
      ratePlanHidden: false,
      e1: 2,
      dialog:false,
      reservableDate: {},
      reservableTimes: {},
      allowedDates: [],
      dateMin: "",
      dateMax: "",
      dispStartDate: "",
      dispElStartDate: "",
      displayCancelBtn: false,
      loading: false,
      rules:{
        lastName: value => !!value || 'お申し込み者（姓）を入力してください。',
        lastNamedigitLimit: value => value == null || 1 <= value.length && value.length <= 9 || '9文字以下で入力してください。',
        lastNamecheck: value => /^[^0-9０-９]*$/.test(value) ||'お申し込み者（姓）は文字のみ有効です。',
        firstName: value => !!value || 'お申し込み者（名）を入力してください。',
        firstdigitLimit: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        firstNamecheck: value => /^[^0-9０-９]*$/.test(value) ||'お申し込み者（名）は文字のみ有効です。',
        tel: value => !!value || 'お申し込み者電話番号を入力してください。', 
        teldigitLimit: value => value == null ||  10 <= value.length && value.length <= 11 || '(市外局番込み)10桁以上11桁以下で入力してください。',
        telnumcheck: value => /^[0-9]*$/.test(value) ||'お申し込み電話番号は数字のみ有効です。',
        kinds: value => value == "city_gas" || value == "propane_gas" || value == "unknown" ,
        radios: value => (value === 1 || value === 0) ? true : false ,
        appPlaceLastName: value => !!value || 'お名前（姓）を入力してください。',
        appLastNamedigitLimit: value => value == null || 1 <= value.length && value.length <= 9 || '9文字以下で入力してください。',
        appPlaceFirstName: value => !!value || 'お名前（名）を入力してください。',
        appFirstdigitLimit: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        kanaAppPlaceLastName: value => !!value || 'フリガナ（姓）を入力してください。',
        appKanalastNamedigitLimit: value => value == null || 1 <= value.length && value.length <= 9 || '9文字以下で入力してください。',
        appkanaLastName: value => /^[ァ-ヶー0-9０-９]+$/.test(value) || "フリガナ（姓）はカタカナもしくは数字のみ有効です。", 
        kanaAppPlaceFirstName: value => !!value || 'フリガナ（名）を入力してください。',
        appKnafirstdigitLimit: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        appkanaFirstName: value => /^[ァ-ヶー0-9０-９]+$/.test(value) || "フリガナ（名）はカタカナもしくは数字のみ有効です。", 
        postnum: value => !!value || '郵便番号を入力してください。',
        postNumcheck: value => /^\d{7}$/.test(value) ||'ハイフン無し7桁で入力してください。',
        prefectures: value => !!value || '都道府県を入力してください。',
        prefecturescheck: value => /^[^0-9０-９]*$/.test(value) ||'都道府県は文字のみ有効です。',
        appPrefecturesdigitLimit: value => value == null || 1 <= value.length && value.length <= 4 || '4文字以下で入力してください。',
        MunicipalityRules: value => !!value || '市町村を入力してください。',
        Municipalitycheck: value => /^[^0-9０-９]*$/.test(value) ||'市町村は文字のみ有効です。',
        appMunicipalitydigitLimit: value => value == null || 1 <= value.length && value.length <= 10 || '10文字以下で入力してください。',
        towns: value => !!value || '町字を入力してください。',
        townscheck: value => /^[^0-9０-９]*$/.test(value) ||'町字は文字のみ有効です。',
        apptownsdigitLimit: value => value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        straddressRules: value => !!value || '番地を入力してください。',
        appstraddressdigitLimit: value => value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        building_length: value => value == "" || value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        appRoomnumdigitLimit: value => value == "" || value == null || 1 <= value.length && value.length <= 32 || '32文字以下で入力してください。',
        appTel: value => !!value || '電話番号を入力してください。',
        appTeldigitLimit: value => value == null ||  10 <= value.length && value.length <= 11 || '(市外局番込み)10桁以上11桁以下で入力してください。',
        apptelnumcheck: value => /^[0-9０-９]*$/.test(value) ||'電話番号は数字のみ有効です。',
        EmailLengthCheck: value => value.length <= 254 || '254文字以下で入力してください。',
        addressRules: value => !!value || 'メールアドレスを入力してください。',
        addresscheck:value => /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(value) ||'メールアドレスが正しくありません。',
        confaddressRules: value => !!value || 'メールアドレス(確認用)を入力してください。',
        startdateRules: value => !!value || '希望日を選択してください。',
        timeRules: value => !!value || '希望時間を選択してください。',
        check: checkvalue =>!!checkvalue,
        ratePlanRules: value => !!value || '料金プランを選択してください。', 
        appradiosrule: value => (value === 1 || value === 0) ? true : false,
        setdisdate: value => !value ? '利用開始日を入力してください。' : true ,
        nosetdisdate: value => value ? true : true,
        start: value => (value === 1 || value === 0) ? true : false,
        nostart: value => value == "",
        detail: value => (value === 1 || value === 0) ? true : false,
        nodetail: value => value == "",
        set: value => (value === 1 || value === 0) ? true : false,
        noset: value => value == "",
        dairitenBangoLength: value => (value === "" || value === null || value === undefined) ? true : value.length <= 20 ? true : '',
        agencyNumber: value => !!value || "代理店番号を入力してください。",
        webItemizedChargesCheck(appradios, setradios, value) {
          return (
            ((appradios === 1 && setradios === 1) && value === 2) ? false
            : (value === "" || value === null || value === undefined) ? false
            : (value !== 1 && value !== 0) ? false
            : true
          );
        },
        gasPaymentCheck(appradios, setradios, web_itemized_charges, value) {
          return (!value || (((appradios === 1 && setradios === 1) || web_itemized_charges === 1) && value === 3)) ? false : true
        },
      },
      resGetAcceptableRange: null,
      disabledElectricity: false,
    }),
    watch: {
      // ご利用場所のガス種別が変更された場合
      "input.kindsradios": {
        handler(value) {
          if (value === "city_gas") {
            // 都市ガスが選択された場合、「承諾して申し込みます」の値は初期値に戻す
            this.input.checkbox = false;
          }
        },
        deep: true
      },
      // 「沖縄ガスのでんき」のご利用についてが変更された場合
      "input.appradios": {
        handler(value) {
          if (value === 0) {
            // 「沖縄ガスのでんき」のご利用についてが「申し込まない」に変更された場合、
            // 「沖縄ガスのでんき」の利用開始日と利用開始日は、初期値に戻す
            this.input.startradios = "";
            this.input.elstartdate = "";
            // でんき料金のお知らせ」の郵送の要・不要は、初期値に戻す
            this.input.detailradios = "";
            // でんきとガスのセット割を申し込むは、初期値に戻す
            this.input.setradios = "";
          }
        },
        deep: true
      },
      // 「沖縄ガスのでんき」の利用開始日が変更された場合
      "input.startradios": {
        handler(value) {
          if (value === 0) {
            // 「沖縄ガスのでんき」の利用開始日が、「でんきとガスご利用開始日は同じ」に変更された場合、
            // 利用開始日は、初期値に戻す
            this.input.elstartdate = "";
          }
        },
        deep: true
      },
      // でんきとガスのセット割を申し込むが変更された場合
      "input.setradios": {
        handler(value) {
          if (value === 1) {
            // でんきとガスのセット割を申し込むが、「申し込む」に変更された場合、
            // ガス料金のWEB料金明細サービスの利用を申し込むは、「申し込む」に変更
            this.input.web_itemized_charges = 1;
          }
        },
        deep: true
      },
    },
    computed: {
      ...mapState({
        dataBeforeCorrection: state => state.StartUseForm.dataBeforeCorrection, 
        getAllResponseData: state => state.topApplicationForm.getAllResponseData, 
        urlParams: state => state.topApplicationForm.urlParams,
      }),
      isReceptionNo(){
        return this.getAllResponseData?.okigas_application?.reception_no ? this.getAllResponseData?.okigas_application?.reception_no : false
      },
      addressHogeCheck() {
        return this.input.address == this.input.confaddress || 'メールアドレスが一致しません。';
      },
      setDiscountDate() {
        return (this.input.appradios === 1 && this.input.startradios === 0) ? this.rules.nosetdisdate : this.rules.setdisdate; 
      },
      setDiscountStart() {
        return this.input.appradios == 0 ? this.rules.nostart : this.rules.start;
      },
      setDiscountdetail() {
        return this.input.appradios == 0 ? this.rules.nodetail :this.rules.detail; 
      },
      setDiscountApp() {
        return this.input.appradios == 0 ? this.rules.noset : this.rules.set; 
      },
      qrcodeValidate() {
        return this.qrCodePara == false ? true : !!this.input.qrpara || "QRコードパラメーターを入力してください。";
      },
      electricityStratDate() {
        return this.input.startdate;
      },
      handleClearEvent() {
        if (this.input.startdate) {
          console.log('希望日あり');
        } else {
          console.log('希望日なし');
        }
        return "aaa";
      },
    },
    methods: {
      ...mapMutations({
        setInput: SET_INPUT, 
      }),
      ...mapActions({
        putCancelOpenApplication: PUT_CANCEL_OPENAPPLICATION,
        getReserveCount: GET_OPEN_RESERVATION_COUNT,
        getAcceptableRange: GET_OPEN_ACCEPTABLE_RANGE
      }),
      resetTime() {
        // 希望日に変更があった場合は希望時間をリセットする
        this.input.time = null;
      },
      validate () {
        const form = this.$refs.formvalidate;
        const isValid = form.validate();
        if (isValid) {
          this.setInput(this.input);
          this.$router.push('/AppStartAgreement');
        } else {
          // バリデーションチェックに引っかかった箇所にスクロール
          const firstError = form.inputs.find((input) => input.hasError);
          this.$vuetify.goTo(firstError, { offset: 100 });
        }
      },
      //でんきセット割カレンダー
      electricSetdayFormat: function(date) {
        return new Date(date).getDate();
      },
      async autofilladdress() {
        this.YubinBangoLoading = true;
        await axios.get(
          'https://zipcloud.ibsnet.co.jp/api/search', 
          {
            params: { zipcode: this.input.postcode }
          }
        ).then(response => {
          if (response.data.status === 200) {
            this.input.prefectures = response.data.results[0].address1;
            this.input.Municipality = response.data.results[0].address2;
            this.input.town = response.data.results[0].address3;
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.YubinBangoLoading = false;
        });
      },
      back() {
        return this.$router.push('/');
      },
      //ガス利用開始日カレンダー
      dayFormat: function(date) {
        const key = moment(date).format('YYYY-MM-DD')
        if(this.reservableDate[key] !== undefined){
          const retValue = this.reservableDate[key]
          return new Date(date).getDate() +"("+ retValue +")"
        }else{
          return new Date(date).getDate() +"("+ '0' +")"
        }
      },
      //キャンセルダイアログ
      cancel() {
        this.$dialog.show({
          title: "開栓申し込みのキャンセルを行います。",
          text: "本当に申し込みのキャンセルを行いますか。",
          type: "error",
          onConfirm: async () => {
            this.loading = true
            let payload = {
              okigas_application: {}
            }
            this.canselResponse = this.getAllResponseData
            payload.okigas_application.status = "cancel"
            payload.okigas_application.application_id = this.canselResponse.okigas_application.application_id
            payload.okigas_application.preferred_date = this.canselResponse.okigas_application.preferred_date
            payload.okigas_application.preferred_time = this.canselResponse.okigas_application.preferred_time
            await this.putCancelOpenApplication(payload)
            this.$router.push({ name: 'CancelComplete' })
            this.loading = false
          },
          btnConfirmTitle: "はい",
          btnCancelTitle: "いいえ",
        });
      },
      async cancelOpenApp() {
        console.log("キャンセル")
        let payload = {
          okigas_application: {}
        }
        this.canselResponse = this.getAllResponseData
        console.log('開栓キャンセルレスポンス',this.canselResponse)
        payload.okigas_application.status = "cancel"
        payload.okigas_application.application_id = this.canselResponse.okigas_application.application_id
        payload.okigas_application.preferred_date = this.canselResponse.okigas_application.preferred_date
        payload.okigas_application.preferred_time = this.canselResponse.okigas_application.preferred_time
        console.log('ステータス',payload.okigas_application.status)
        console.log('ID',payload.okigas_application.application_id)
        console.log('日付',payload.okigas_application.preferred_date)
        console.log('時間',payload.okigas_application.preferred_time)
        await this.putCancelOpenApplication(payload)
      },
      dispStartDateMethod() {
        this.input.startdate = this.dispStartDate
        this.input.menu = false
      },
      dispElStartDateMethod() {
        this.input.elstartdate = this.dispElStartDate
        this.input.menu2 = false
      },
      isAllowedDates(val) {
        return this.allowedDates.includes(val)
      },
      makeAllowedDates() {
        this.allowedDates = []
        for(const key in this.reservableDate){
          if (this.reservableDate[key] > 0){
            this.allowedDates.push(String(key))
          }
        }
      },
    },
    //新規、修正、更新の判定
    async created() {
      //画面遷移元の特定
      // 新規：responseDataにデータなし 編集：responseDataにデータあり
      let setOpenResponseAllData = this.getAllResponseData; //responseDataを格納
      let transitionSourceScreen = this.$router.from; //遷移元のデータを格納
      if ((setOpenResponseAllData === "") && (transitionSourceScreen.name === 'AppStartAgreement')) {
        //新規かつ修正
        //確認画面から戻って来て再度修正なので、stateの値をローカル変数にセットする
        console.log('新規の編集画面')
        return this.input = this.dataBeforeCorrection;

      } else if (setOpenResponseAllData !== "") {
        //更新：inputにresponseDataのデータをセットする
        console.log('更新画面')
        //キャンセルボタンの表示
        this.displayCancelBtn = true;

        // 「沖縄ガスのでんき」を申し込む かつ 受付可能範囲外なら「沖縄ガスのでんき」の入力項目をグレーアウト
        const application = setOpenResponseAllData.okigas_application;
        const isUseElectricity = (application.is_use_electricity === 1);
        if (isUseElectricity) {
          // 受付可能範囲設定の取得
          this.resGetAcceptableRange = await this.getAcceptableRange('open_faucet');
          const accRngSet = this.resGetAcceptableRange?.okigas_acceptable_range_setting;
          const dayCount = accRngSet?.acceptable_range_day_count;
          const monthCount = accRngSet?.acceptable_range_month_count;
          const partialSetting = accRngSet?.acceptable_range_partial_setting;

          // 受付可能範囲を計算
          const today = moment().format('YYYY-MM-DD');
          let accRngStartDate = moment();
          let accRngEndDate = moment();
          if (accRngSet) {
            const todayPartSet = (partialSetting) ? partialSetting.find((obj) => obj.date === today) : null;
            accRngStartDate = (todayPartSet) ? moment(today).add((todayPartSet.day_count + 1), 'd') : moment(today).add(dayCount, 'd');
            accRngEndDate = moment(accRngStartDate).add(monthCount, 'M');
          }
          console.log('accRng: ', accRngStartDate.format('YYYY-MM-DD'), '~', accRngEndDate.format('YYYY-MM-DD'))

          // 「沖縄ガスのでんき」ご利用開始日を取得(ガスのご利用開始日と同じならガスのご利用開始日を参照)
          const electricityStartDate = (application.electricity_start_select === 0)
            ? application.preferred_date
            : application.electricity_start_date;
          console.log('electricityStartDate', electricityStartDate)

          // 「沖縄ガスのでんき」入力項目をグレーアウト
          if (!moment(electricityStartDate).isBetween(accRngStartDate, accRngEndDate, null, '[]')) {
            this.disabledElectricity = true;
          }
        }

        let responseReceptionData = setOpenResponseAllData.okigas_application
        console.log('開栓responseData',responseReceptionData)
        console.log('更新元データ',this.input)
        this.input.status = responseReceptionData.status
        this.input.firstname = responseReceptionData.applicant_last_name
        this.input.firstname = responseReceptionData.applicant_last_name
        this.input.lastname = responseReceptionData.applicant_first_name
        this.input.tel = responseReceptionData.applicant_tel_number
        this.input.kindsradios = responseReceptionData.gas_kind
        this.input.ageradios = responseReceptionData.is_less_than_3months
        this.input.apfirstname = responseReceptionData.last_name
        this.input.aplastname = responseReceptionData.first_name
        this.input.readfirstname = responseReceptionData.last_name_kana
        this.input.readlastname = responseReceptionData.first_name_kana
        this.input.postcode = responseReceptionData.postal_code
        this.input.prefectures = responseReceptionData.pref
        this.input.Municipality = responseReceptionData.municipality
        this.input.town = responseReceptionData.address1
        this.input.straddress = responseReceptionData.address2
        this.input.building = responseReceptionData.building
        this.input.roomnum = responseReceptionData.room_number
        this.input.aptel = responseReceptionData.tel_number
        this.input.address = responseReceptionData.mail
        this.input.confaddress = responseReceptionData.mail
        this.input.startdate = responseReceptionData.preferred_date
        this.input.time = responseReceptionData.preferred_time
        this.input.checkbox = responseReceptionData.deposit_agreement
        this.input.ratePlanMenu = responseReceptionData.gas_plan
        this.input.appradios = responseReceptionData.is_use_electricity
        this.input.startradios = responseReceptionData.electricity_start_select
        this.input.elstartdate = responseReceptionData.electricity_start_date
        this.input.detailradios = responseReceptionData.is_electricity_news
        this.input.setradios = responseReceptionData.is_set_discount
        this.input.web_itemized_charges = responseReceptionData.web_itemized_charges
        this.input.gasPayment = responseReceptionData.gasPayment
        this.input.agencynum = responseReceptionData.agency_number
        this.input.qrpara = responseReceptionData.qr_parameter

        if (transitionSourceScreen.name === 'AppStartAgreement') {
          //更新の編集
          console.log('更新の編集画面')
          return this.input = this.dataBeforeCorrection;
        }
      }
    },
    async mounted() {
      this.loading = true;

      // 受付可能範囲
      if (!this.resGetAcceptableRange) {
        this.resGetAcceptableRange = await this.getAcceptableRange('open_faucet');
      }

      const res = this.resGetAcceptableRange;
      let acceRangeDay = 1;
      let acceRangeMonth = 1;
      if (res && res.okigas_acceptable_range_setting) {
        acceRangeDay = res.okigas_acceptable_range_setting.acceptable_range_day_count;
        acceRangeMonth = res.okigas_acceptable_range_setting.acceptable_range_month_count;
      }

      this.dateMin = moment(this.input.today).add(acceRangeDay, 'd').format("YYYY-MM-DD");
      this.dateMax = moment(this.input.today).add(acceRangeMonth, 'M').format("YYYY-MM-DD");

      // 個別設定
      const foundObj = res.okigas_acceptable_range_setting.acceptable_range_partial_setting.find(obj => obj.date === this.input.today);
      if (foundObj) {
        this.dateMin = moment(foundObj.date).add(foundObj.day_count + 1, 'd').format("YYYY-MM-DD");
      }

      this.dispStartDate = moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
      this.dispElStartDate = moment(this.dateMin, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");

      // 時間枠情報と予約可能数の取得
      let req = {
        applicationType: 'open_faucet',
        start: this.dateMin,
        end: this.dateMax,      
      }
      let scheduleComaId
      let reservationTime
      const response = await this.getReserveCount(req)
      
      const comaListObj = response.okigas_reception_setting.coma_list;
      let comaList = []
      // comaListを配列に変換
      const comaListKeys = Object.keys(comaListObj);
      comaListKeys.forEach((key) => {
        comaList.push({
          id: key,
          start: comaListObj[key].start,
          end: comaListObj[key].end
        });
      });
      // 時間枠の開始時間の若い順に並び替え
      comaList.sort((a, b) => {
        const aTime = a.start;
        const bTime = b.start;

        // 時間と分に分割して数値として比較
        const [aHour, aMinute] = aTime.split(":").map(Number);
        const [bHour, bMinute] = bTime.split(":").map(Number);

        // 時間の比較
        if (aHour !== bHour) {
          return aHour - bHour; // 時間が異なる場合は時間で比較
        } else {
          return aMinute - bMinute; // 同じ時間なら分で比較
        }
      });
      
      for( var dateKey in response.okigas_reception_setting.schedule) {
        let sumReservable = 0
        this.reservableTimes[dateKey] = []
        scheduleComaId = response.okigas_reception_setting.schedule[dateKey]

        comaList.forEach((comaInfo) => {
          const comaId = comaInfo.id;
          if (scheduleComaId[comaId]) {
            // 時間指定なし表示フラグがfalseなら、時間指定なしコマはカウントしない、受付可能時間枠も追加しない
            if (response.okigas_reception_setting.is_display_unspecified || comaId != 1) {
              // 予約可能数をカウント
              let reservableComa = parseInt(scheduleComaId[comaId][0], 10) - parseInt(scheduleComaId[comaId][1], 10);
              sumReservable += reservableComa;

              // 受付可能時間枠を追加
              reservationTime = comaInfo;
              const text = (comaId == 1) ? "時間指定なし 09:00〜17:00" : reservationTime['start'] + '～' + reservationTime['end'];
              this.reservableTimes[dateKey].push({
                text: text, 
                value:reservationTime['start'] + '～' + reservationTime['end'],
                disabled: reservableComa === 0
              });
            }
          }
        });
        this.reservableDate[dateKey] = sumReservable
        this.makeAllowedDates();
      }
      // QRコードを設定
      console.log('QRパラメータ', this.urlParams)
      if(this.urlParams['agency_number']){
        this.input.agencynum = this.urlParams['agency_number']
      }
      if(this.urlParams['qr_parameter']){
        this.input.qrpara = this.urlParams['qr_parameter']
      }
      this.$forceUpdate()
      this.loading=false;
    },
  }
</script>

<style scoped>
@media screen and (max-width:600px) {
  .v-toolbar__title {
    font-size: .8em;
  }
  .TopText h2 {
    font-size: 1em;
  }
  h3 {
    font-size: .9em;
  }
}
.radiosAppdate.col-md-4.col-12 {
  max-width: 280px;
}
::v-deep .startDateOfUse .v-date-picker-table--date .v-btn {
  width: 45px; 
}
::v-deep .v-stepper__label {
  display: grid !important;
}
.disabledArea {
  background-color: rgba(200, 200, 200) !important;
}
</style>